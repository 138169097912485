import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import "./StudySurvey.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import smalllogo from "../../Assets/Images/Logo1.svg";
import emaillogo from "../../Assets/Images/email.svg";
import signinpassword from "../../Assets/Images/password.svg";
import SublyApi from "../../helpers/Api";
import { SocialLogin } from "../../actions/currentUser";
import { checkSocialLogin } from "../../actions/currentUser";
import { getTokens } from "../../actions/currentUser";
import { Toast } from "../../commonFile";
import { SmallLoader } from "../../commonFile";
import { Getlanguage } from "../../commonfunction";
import { getTokenArr } from "../../firebase";
import { getUser } from "../../actions/currentUser";
import { getinternet } from "../../commonfunction";

const PreStudySurvey = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const completed = queryParams.get("completed") || false;
  const email = queryParams.get("email") || "";

  const [challengeComplete, setChallengeComplete] = useState(false);

  const { t, i18n } = useTranslation();
  useEffect(() => {
    async function setlanguage() {
      const geti18value = await Getlanguage(
        parseInt(
          currentUser.default_language
            ? currentUser.default_language
            : changelanguage
        )
      );
      i18n.changeLanguage(geti18value);
    }
    setlanguage();
  }, []);

  useEffect(() => {
    const onloadTurnstileCallback = () => {
      /* global turnstile */
      turnstile.render("#content", {
        sitekey: process.env.REACT_APP_CF_TURNSTILE_SITEKEY,
        callback: function (token) {
          setTimeout(() => {
            setChallengeComplete(true);
          }, 1000);
        },
      });
    };

    if (!challengeComplete) {
      const script = document.createElement("script");
      script.src = "https://challenges.cloudflare.com/turnstile/v0/api.js";
      script.defer = true;
      const captchaMessage = document.createElement("h2");
      captchaMessage.textContent =
        "Checking if the site connection is secure and reviewing the security of your connection before proceeding...";
      captchaMessage.style.position = "absolute";
      captchaMessage.style.top = "calc(50% - 200px)";
      captchaMessage.style.left = "50%";
      captchaMessage.style.transform = "translate(-50%, -50%)";
      captchaMessage.style.textAlign = "center";
      captchaMessage.style.color = "white";
      captchaMessage.style.fontSize =
        window.innerWidth <= 768 ? "20px" : "35px"; // Adjust the breakpoint and font size values as needed
      const captcha = document.createElement("div");
      captcha.appendChild(script);
      captcha.appendChild(captchaMessage);
      script.onload = onloadTurnstileCallback;
      document.body.appendChild(captcha);

      return () => {
        document.body.removeChild(captcha);
      };
    }
  }, [challengeComplete]);

  function removeCaptcha() {
    var iframes = document.querySelectorAll(
      'iframe[title="Widget containing a Cloudflare security challenge"]'
    );
    iframes.forEach(function (iframe) {
      iframe.parentNode.removeChild(iframe);
    });
  }

  useEffect(() => {
    if (challengeComplete) {
      const captcha = document.querySelector("div[data-turnstile-container]");
      if (captcha) {
        captcha.style.opacity = 0;
        var scriptContent = document.querySelectorAll(
          'iframe[title="Widget containing a Cloudflare security challenge"]'
        );
        scriptContent.forEach(function (iframe) {
          iframe.parentNode.removeChild(iframe);
        });
        document.body.removeChild(captcha);
      }
      removeCaptcha();

      if (completed) {
        const updateUser = async () => {
          const timezone = await Intl.DateTimeFormat().resolvedOptions()
            .timeZone;

          let requestData = new FormData();
          requestData.append("email", email);
          await SublyApi.surveyCompleted(
            requestData,
            isTokenFound,
            timezone,
            changelanguage ? changelanguage : 1
          );
          setSurveyCompleted(true);
          setLoading(false);
        };

        updateUser();
      }
    }
  }, [challengeComplete]);

  const [isTokenFound, setTokenFound] = useState();
  getTokenArr(setTokenFound);

  const dispatch = useDispatch();
  // medtitaion value get by redux
  const {
    currentUser,
    getexperiense: experience,
    getlanguage: language,
    getgender: gender,
    getsurvey: age,
  } = useSelector((st) => st);
  // get survey convert to number because api get value in number
  const chanexperience = Number(experience.defaultData);
  const changeage = Number(age.defaultData);
  const changegender = Number(gender.defaultData);
  const changelanguage = Number(language.defaultData);
  const history = useHistory();
  const [loading, setLoading] = useState(completed);
  const [surveyCompleted, setSurveyCompleted] = useState(false);
  const [imageShow, setImageshow] = useState(false);

  const [passwordShown, setPasswordShown] = useState(false); // passwork show state
  // it's use for class add for error show end outline show in input field
  const [errorshow, seterrorshow] = useState(false);

  const changeState = () => {
    errorshow == false ? seterrorshow(true) : seterrorshow(false);
  };
  // it's use for input data validation & get data & setdata.
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // it's work get data from form & api sent data & get respone.

  const onSubmit = async (formData) => {
    const devicetype = navigator.userAgent;
    const online = await getinternet();

    if (online === false) {
      Toast.fire({
        icon: "error",
        title: t("noiternet"),
      });
    } else {
      const timezone = await Intl.DateTimeFormat().resolvedOptions().timeZone;
      let requestData = new FormData();
      requestData.append("email", formData.email);
      requestData.append("password", formData.password);
      requestData.append("firebase_token", isTokenFound ? isTokenFound : "");
      setLoading(true);
      await SublyApi.userLogin(
        requestData,
        isTokenFound,
        timezone,
        changelanguage ? changelanguage : 1
      )
        .then((responsejson) => {
          if (responsejson.data.status_code === 400) {
            setLoading(false);
            if (responsejson.data.error_type === "INVALID_PRESURVEY_PENDING") {
              Toast.fire({
                icon: "success",
                title: t("loginsuccess"),
              });
              // Redirect the User to the Survey
              window.location.href = `https://survey.alchemer.com/s3/7782648/MECI-Questionnaire?email=${formData.email}`;
            } else {
              Toast.fire({
                icon: "error",
                title: `${responsejson.data.message}`,
              });
            }
          }
        })
        .catch((error) => {});
    }
  };

  // -- Social Login work for signin with social side.
  async function SocialSignUp(email, userID, social_type) {
    const timezone = await Intl.DateTimeFormat().resolvedOptions().timeZone;
    var formdata = new FormData();
    formdata.append("email", email);
    formdata.append("social_id", userID);
    formdata.append("social_type", social_type);
    formdata.append("meditation", `${chanexperience ? chanexperience : ""}`);
    formdata.append("age_group", `${changeage ? changeage : ""}`);
    formdata.append("gender_id", `${changegender ? changegender : ""}`);
    formdata.append(
      "default_language",
      `${changelanguage ? changelanguage : ""}`
    );
    formdata.append("firebase_token", isTokenFound ? isTokenFound : "");
    await SublyApi.checkSocialLogin(
      formdata,
      isTokenFound,
      timezone,
      changelanguage ? changelanguage : 1
    )
      .then(async (responsejson) => {
        let socialStatus = await responsejson.data.social_status;
        if (socialStatus == 1) {
          dispatch(getTokens(responsejson.data.user_details.auth_token));
          dispatch(checkSocialLogin(responsejson));
          dispatch(getUser({ isTokenFound: isTokenFound }));
          history.push("/algorithm");
        } else {
          await SublyApi.SocialLogin(
            formdata,
            isTokenFound,
            timezone,
            changelanguage ? changelanguage : 1
          ).then((responsejsons) => {
            if (responsejsons.data.status_code === 400) {
              Toast.fire({
                icon: "error",
                title: `${responsejson.data.message}`,
              });
            } else {
              Toast.fire({
                icon: "success",
                title: t("socialsuccess"),
              });
              dispatch(getTokens(responsejsons.data.user_details.auth_token));
              dispatch(SocialLogin(responsejsons));
              dispatch(getUser({ isTokenFound: isTokenFound }));
              history.push("/algorithm");
            }
          });
        }
      })
      .catch((error) => {});
  }


  // handles the change in the form. react sets form data in state every time there is a change.

  //popup show or not show by usestate hook.
  const [modalOpen, setModalOpen] = useState(false);

  const eye = <FontAwesomeIcon icon={faEye} />; //image
  const eye2 = <FontAwesomeIcon icon={faEyeSlash} />; // image
  const imagevisiblity = () => {
    setImageshow(imageShow ? false : true);
  };
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  return (
    <div
      id="content"
      style={{
        display: challengeComplete ? "initial" : "flex",
        justifyContent: challengeComplete ? "initial" : "center",
        alignItems: challengeComplete ? "initial" : "center",
        height: challengeComplete ? "initial" : "100vh",
      }}
    >
      {challengeComplete && (
        <>
          <div className="container">
            <div className="signinlogofirst">
              <button>
                <img
                  style={{ width: "294px" }}
                  src={smalllogo}
                  alt="samlllogo"
                  onClick={() => history.push("/")}
                />
              </button>
            </div>
            <div className="containermain">
              <div className="loginpagediv">
                <div className="formcontainer">
                  <div className="formbody">
                    <div className="forminnerbody">
                      <div className="logintitle">
                        <h3>{t("preStudySurvey")}</h3>
                        <p>{t("ENTERYOURDET")}</p>
                      </div>
                      {!completed && (
                        <>
                          <form
                            method="post"
                            id="8"
                            onSubmit={handleSubmit(onSubmit)}
                            autoComplete={false}
                          >
                            <div
                              className={`emaillogobody ${
                                errors.email ? "outline" : ""
                              }`}
                            >
                              <div className="emaillogoform">
                                <img
                                  src={emaillogo}
                                  alt="emaillogo"
                                  style={{
                                    verticalAlign: "-webkit-baseline-middle",
                                  }}
                                  width="23px"
                                  height="23px"
                                />
                              </div>
                              <input
                                autoFocus="autofocus"
                                autoComplete={false}
                                id="email"
                                name="email"
                                placeholder={t("enteremail")}
                                className="inputfieldauto"
                                {...register("email", {
                                  required: t("emailreq"),
                                  pattern: {
                                    value:
                                      /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/,
                                    message: t("pentvalemail"),
                                  },
                                })}
                              />
                            </div>
                            {errors.email && (
                              <span className="errorMessageColor">
                                {errors.email?.message}
                              </span>
                            )}
                            <div>
                              <div
                                className={`signinpasswordbody ${
                                  errors.password ? "outline" : ""
                                }`}
                              >
                                <div className="signinpasswordform">
                                  <img
                                    src={signinpassword}
                                    alt="signinpasswordlogo"
                                    width="23px"
                                    height="23px"
                                  />
                                </div>
                                <input
                                  className="inputfieldauto"
                                  type={passwordShown ? "text" : "password"}
                                  autoComplete={false}
                                  id="password"
                                  name="password"
                                  placeholder={t("enterpasswork")}
                                  {...register("password", {
                                    required: t("passwordrew"),
                                    pattern: {
                                      value:
                                        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])/,
                                      message: t("signinpwderror"),
                                    },
                                  })}
                                />
                                <i
                                  style={{ color: "white" }}
                                  onClick={() => {
                                    togglePasswordVisiblity();
                                    imagevisiblity();
                                  }}
                                >
                                  {imageShow ? eye2 : eye}
                                </i>
                              </div>
                              {errors.password && (
                                <span className="errorMessageColor">
                                  {errors.password.message}{" "}
                                </span>
                              )}
                            </div>
                            <p className="forgotpassword">
                              <NavLink exact to="/Forgot">
                                {t("FORGOTPASS")}
                              </NavLink>
                            </p>
                            <div>{loading ? <SmallLoader /> : null}</div>
                            <button
                              className="btn"
                              id="8"
                              type={`${loading ? "button" : "submit"}`}
                              onClick={changeState}
                            >
                              {t("SIGNIN")}
                            </button>
                          </form>
                          <div className="divider_Box">
                            <hr className="divide"></hr>
                          </div>
                          <div className="signupArea">
                            <div>
                              <p>{t("DONTACCOUNT")}</p>
                            </div>
                            <div>
                              <NavLink to="/signup">{t("SIGNUP")}</NavLink>
                            </div>
                          </div>
                        </>
                      )}
                      {completed && (
                        <>
                          {!surveyCompleted && <SmallLoader />}
                          {surveyCompleted && (
                            <div className="p-5">
                              <p className="text-white">
                                {t("preStudySurveyCompleted")}
                              </p>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PreStudySurvey;
