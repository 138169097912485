import React, { useEffect } from "react";

import { useHistory } from "react-router-dom";
import { useState } from "react";
import SublyApi from "../../helpers/Api";
import { Loader } from "../../commonFile";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const VerifyEmail = () => {
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const { t } = useTranslation();
  const [serverErrorMessage, setServerErrorMessage] = useState("");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const email = queryParams.get("email");
  const token = queryParams.get("token");

  useEffect(() => {
    if (email && token) {
      const verifyEmail = async () => {
        try {
          const timezone = await Intl.DateTimeFormat().resolvedOptions()
            .timeZone;
          const response = await SublyApi.verifyEmail(email, token, timezone);
          setLoading(false);
          if (response.data.is_verified === 1) {
            setInterval(() => {
                history.push("/login");
            }, 5000);
          } else {
            setServerErrorMessage(response.data.message);
          }
        } catch (error) {
          // setServerErrorMessage(error.response.data.message);
          setLoading(false);
        }
      };
      verifyEmail();
    }
  }, [email, token, history]);

  return (
    <section className="emailVerficationbody">
      <div className="container">
        <div className="emailVerificationConatin">
          <div className="emailConatin">
            <div className="emailVerificationbody">
              <h2 className="title">{t("EMAILVER_TITLE")}</h2>
              {!loading && serverErrorMessage.length === 0 && (
                <>
                  <p className="text-center text-gray-primary mt-5">
                    You have sccessfully verified your email.
                  </p>
                  <p className="text-center text-gray-primary mt-3">
                    You will now redirected to login screen .
                  </p>
                  <Loader />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VerifyEmail;
