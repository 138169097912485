import { combineReducers } from 'redux';
import currentUser from './currentUser';
import homeSession from './homeSession';
import defaultList from './defaultList';
import newDefaultList from './newDefaultList';
import goalTimeList from './goalTimeList';
import timeNarrationList from './timeNarrationList';
import narrationThemeList from './narrationThemeList';
import getDefault from './getDefault';
import getExcercise from './getExcercise';
import errors from './errors';
import token from './token';
import getsurvey from "./getsurvey";
import getgender from "./getgender";
import getlanguage from "./getlanguage";
import getexperiense from "./getexperiense";
import tempData from "./tempData";
// import products from './products';
/** combines the reducers into one root reducer */
export default combineReducers({ currentUser, newDefaultList, tempData, getexperiense, getlanguage, getgender, getExcercise, getsurvey, homeSession, defaultList, goalTimeList, timeNarrationList, narrationThemeList, getDefault, token, errors });
