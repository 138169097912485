import { responsivePropType } from "react-bootstrap/esm/createUtilityClasses";
import SublyApi from "../helpers/Api";
import { showErr, clearErr } from "./errors";
import {TIME_NARRATION_LIST,REMOVE_TIME_NARRATION_LIST
} from "./types";

export function searchTimeNarration(timeID,goalid,token) {
  return async function (dispatch) {
      try {
        dispatch(clearErr());
        const defaultData = await SublyApi.searchTimeNarration(timeID,goalid,token);
         dispatch(getTimetNarrationList(defaultData.data));
         
       } catch (err) {
        dispatch(showErr(err));
      }
    };
  }


function getTimetNarrationList(defaultData) {
  return {
    type: TIME_NARRATION_LIST,
    defaultData,
  };
}
 
 
export function deletTimeNarration(defaultData) {
  return {
    type: REMOVE_TIME_NARRATION_LIST,
    defaultData,
  };
}

 