import { responsivePropType } from "react-bootstrap/esm/createUtilityClasses";
import SublyApi from "../helpers/Api";
import { showErr, clearErr } from "./errors";
import { FETCH_DEFAULT_LIST, REMOVE_DEFAULT_LIST, FETCH_NEW_DEFAULT_LIST, REMOVE_NEW_DEFAULT_LIST } from "./types";

export function getDefaultData(data) {
  return async function (dispatch) {
    try {
      dispatch(clearErr());
      // const defaultData = await SublyApi.setExcersiseDefault(token);
      // if (defaultData && defaultData.data) {
      //   defaultData.data.default.durationMinuteID = defaultData.data.default.duration_minute_id;
      // }

      await dispatch(getDefaultList(data));
    } catch (err) {
      dispatch(showErr(err));
    }
  };
}

export function setDefaultData(defaultData) {
  return async function (dispatch) {
    try {
      dispatch(clearErr());

      await dispatch(getDefaultList(defaultData));
    } catch (err) {
      dispatch(showErr(err));
    }
  };
}

export function getDefaultList(defaultData) {
  return {
    type: FETCH_DEFAULT_LIST,
    defaultData,
  };
}


export function deleteDefaultList(defaultData) {
  return {
    type: REMOVE_DEFAULT_LIST,
    defaultData,
  };
}

export function setNewDefaultData(newDefaultData) {
  return async function (dispatch) {
    try {
      dispatch(clearErr());

      dispatch(getNewDefaultList(newDefaultData));
      dispatch((newDefaultData));
    } catch (err) {
      dispatch(showErr(err));
    }
  };
}

function getNewDefaultList(newDefaultData) {
  return {
    type: FETCH_NEW_DEFAULT_LIST,
    newDefaultData,
  };
}


export function deleteNewDefaultList(newDefaultData) {
  return {
    type: REMOVE_NEW_DEFAULT_LIST,
    newDefaultData,
  };
}