import React from "react";
import './PolicyHeader.css';
import PolicyLogo from '../../../../Assets/Images/Logo1.svg';

function PolicyHeader() {
    return (
        <section className="HeaderContainer">
            <div className="HeadContain">
                <div className="logoArea">
                    <img style={{width: "294px"}} src={PolicyLogo} alt="policylogo" />
                </div>
                <div className="HeadingArea">
                    <h4>Privacy Policy</h4>
                </div>
            </div>
        </section>
    )
};

export default PolicyHeader;