import { AGE_LIST, REMOVE_AGE_LIST } from '../actions/types';
export default function rootReducer(state = {}, action) {
    switch (action.type) {

        case AGE_LIST:
            return { ...state, ...action };
        case REMOVE_AGE_LIST:
            return {};

        default:
            return state;
    }
}
