
// import { createStore } from 'redux';
// import rootReducer from "./reducers/root";
//  import storage from 'redux-persist/lib/storage';

// import { persistStore, persistReducer } from 'redux-persist'; // allows your app to persist by saving to local storage
// const persistConfig = {
// 	key             : 'root',
// 	storage
//  };
// const persistedReducer = persistReducer(persistConfig, rootReducer);

// const store = createStore(persistedReducer,window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
// export const persistor =persistStore(store);
// export default store;


import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist'; // allows your app to persist by saving to local storage
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import thunk from 'redux-thunk';
import root from './reducers/root';
import { createStore, applyMiddleware } from 'redux';

const persistConfig = {
	key: 'root',
	storage,
	stateReconciler: autoMergeLevel2
};

const persistedReducer = persistReducer(persistConfig, root);

export const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)));

export const persistedStore = persistStore(store);
