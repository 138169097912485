import { responsivePropType } from "react-bootstrap/esm/createUtilityClasses";
import SublyApi from "../helpers/Api";
import { showErr, clearErr } from "./errors";
import {
  EXCERCISE_LIST, REMOVE_EXCERCISE_LIST
} from "./types";

export function getExcercise(responsejson) {

  return async function (dispatch) {
    try {

      dispatch(clearErr());
      dispatch(getExcerciseList(responsejson));
    } catch (err) {
      dispatch(showErr(err));
    }
  };
}


function getExcerciseList(defaultData) {
  return {
    type: EXCERCISE_LIST,
    defaultData,
  };
}


export function deleteExcerciseList(defaultData) {
  return {
    type: REMOVE_EXCERCISE_LIST,
    defaultData,
  };
}

