import React, { useState, useEffect } from "react";
import "./InviteFriend.css";
import Nav from "../../Header/header";
import { Icon } from "@iconify/react";
import { useHistory } from "react-router-dom";
import SublyApi from "../../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { Toast } from "../../../commonFile";
import { SmallLoader } from "../../../commonFile";
import { Getlanguage } from "../../../commonfunction";
import { useTranslation } from "react-i18next";
import { getinternet } from "../../../commonfunction";
import { deleteToken } from "../../../actions/currentUser";
import { logout } from "../../../actions/currentUser";
import { deleteDefaultList } from "../../../actions/defaultList";
import { clearErr } from "../../../actions/errors";
import { deleteExcerciseList } from "../../../actions/getExcercise";
import { deleteExperience } from "../../../actions/getexperiense";
import { deleteGender } from "../../../actions/getgender";
import { deleteLanguage } from "../../../actions/getlanguage";
import { deleteAge } from "../../../actions/getsurvey";
import { deletTimeNarration } from "../../../actions/timeNarrationList";
import { removeTempData } from "../../../actions/tempData";
import { deleteNarrationTheme } from "../../../actions/narrationTheme";
import { deleteDefault } from "../../../actions/homeSession";
import { deleteGoalTimeList } from "../../../actions/goalTimeList";
import { deleteNewDefaultList } from "../../../actions/defaultList";


function InviteFriend() {
  const [values, setValues] = useState("");
  const [emails, setEmails] = useState("");
  const [load, setLoad] = useState(false);
  const [errorEmailText, setErrorEmailText] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const history = useHistory();
  const token = useSelector((st) => st.token);
  const dispatch = useDispatch();
  const currentUser = useSelector((st) => st.currentUser);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    async function setlanguage() {
      const geti18value = await Getlanguage(parseInt(currentUser.default_language ? currentUser.default_language : 1));
      i18n.changeLanguage(geti18value);
    }
    setlanguage();
  }, []);


  // -----here i am validate the email and pushing in the array-----

  function getEmails() {
    let email = [...emails];
    const regEx = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/;

    if (regEx.test(values) && values !== "") {
      email.push(values);
      setErrorEmailText("");
      setValues("");
    } else {
      if (values == "") {
        setErrorEmailText(t("emailreq"));
      }
      else {
        setErrorEmailText(t("pentvalemail"));
      }
    }
    setEmails(email);
  }

  // ----removing the email from array----

  function removeEmain(i) {
    let updatedemail = [...emails];
    updatedemail.splice(i, 1);
    setEmails(updatedemail);
  }

  // ----logOut while token expired----

  const signOut = () => {
    dispatch(logout());
    dispatch(deleteToken());
    dispatch(deleteDefaultList());
    dispatch(clearErr());
    dispatch(deleteExcerciseList());
    dispatch(deleteExperience());
    dispatch(deleteGender());
    dispatch(deleteLanguage());
    dispatch(deleteAge());
    dispatch(deletTimeNarration());
    dispatch(removeTempData());
    dispatch(deleteNarrationTheme());
    dispatch(deleteDefault());
    dispatch(deleteGoalTimeList());
    dispatch(deleteNewDefaultList());
    dispatch(deleteDefaultList());
    history.push("/login");
  };

  // -----sharing the emails to API-----

  async function handleSubmit() {
    const online = await getinternet();

    if (online === false) {
      Toast.fire({
        icon: "error",
        title: t("noiternet"),
      });
    }
    else {
      setLoad(true);
      let requestData = new FormData();
      for (let i = 0; i < emails.length; i++) {
        requestData.append(`email[${i}]`, emails[i]);
      }
      await SublyApi.sendInvitation(token, requestData, currentUser.device_id, currentUser.device_timezone)
        .then((responsejson) => {
          if (responsejson &&
            responsejson.data.error_type == "USER_NOT_FOUND" &&
            responsejson.data.status_code === 400) {
            // Toast.fire({
            //   icon: "error",
            //   title: responsejson.data.message,
            // });
            signOut();
          }
          else {
            if (responsejson.data.status_code === 400) {
              setErrorMessage(responsejson.data.message);
              Toast.fire({
                icon: "error",
                title: responsejson.data.message,
              });
              setLoad(false);
            } else {
              setSuccessMessage(responsejson.message);
              Toast.fire({
                icon: "success",
                title: responsejson.message
                  ? responsejson.message
                  : t("invitaionsent"),
              });
              setLoad(false);
              history.push("/Setting");
            }
          }
        })
        .catch(() => { });
    }
  }
  return (
    <>
      <section>
        <Nav></Nav>
        <div className="inviteContainer">
          <div className="inviteFriendBody">
            <div className="inviteFriend_Header">
              <div className="inviteBack_Icon">
                <Icon
                  icon="eva:arrow-ios-back-fill"
                  color="white"
                  width="24"
                  cursor="pointer"
                  height="24"
                  onClick={() => {
                    history.push("/setting");
                  }}
                />
              </div>
              <div className="invite_Title">
                <h5>{t("INVITEFRIEND")}</h5>
              </div>
            </div>
            <div className="friendDetail_Area">
              <label>{t("EMAILADDRESS")}</label>
              <div className="email_inviteInput">
                <input
                  autoFocus="autofocus"
                  type="email"
                  name="email"
                  autoComplete="off"
                  value={values}
                  onChange={(event) => setValues(event.target.value)}
                />
                <p onClick={load == false && getEmails}>{t("ADDANOTHER")}</p>
              </div>
              <p className="forError">{errorEmailText}</p>
              <p className="spacing"></p>
              <span>
                {emails &&
                  emails.map((item, index) => (
                    <div className="emailAddress" key={index}>
                      <p key={index}>{item}</p>
                      <Icon
                        icon="carbon:close-outline"
                        color="#a8a4a4"
                        width="25"
                        height="25"
                        cursor="pointer"
                        onClick={() => {
                          removeEmain(index);
                        }}
                      />
                    </div>
                  ))}
              </span>
            </div>
            <div className="for_Error_Msg">
              <span>{load ? <SmallLoader /> : null}</span>
              <div className="invite_btn">
                <button onClick={load == false && handleSubmit}>{t("INVITE")}</button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default InviteFriend;
