import React from "react";

import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className="fixed-bottom w-100 d-flex justify-content-center bg-black">
      <div className="newsetsuppli">
        <ul className="new_Support_Links">
          <li className=" newAboutus">
            <NavLink exact to="/Setting/AboutUs">
              <p className="new_Support_Links_Title">{t("ABOUTUS")}</p>
            </NavLink>
          </li>
          <li className="new_Support_Links_Title responsivenonshow">|</li>
          <li className="newTermofuse">
            <a
              href="https://api.precisionpain.management/home/terms"
              target="blank"
            >
              <p className="new_Support_Links_Title">{t("TERMSOFUSE")}</p>
            </a>
          </li>
          <li className="new_Support_Links_Title responsivenonshow">|</li>
          <li className="newSupport">
            <NavLink exact to="/Setting/Support">
              <p className="new_Support_Links_Title">{t("SUPPORT")}</p>
            </NavLink>
          </li>
          <li className="new_Support_Links_Title responsivenonshow">|</li>
          <li className="newprivacy">
            <a
              href="https://api.precisionpain.management/home/privacy"
              target="blank"
            >
              <p className="new_Support_Links_Title ">{t("PRIVACY")}</p>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
