import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';


import SessionSection from "../Components/SessionSection/SessionSection";
import MyFavorites from "../Components/MyFavorites/MyFavorites";
import MyHistory from "../Components/History/History.js";
import HomeVideo from "../Components/Home/VideoContainer/HomeVideo/HomeVideo";
import Signinpage from "../Components/Auth/Signin/Signin";
import Forgot from "../Components/Auth/Forgot/Forgot";
import Signup from "../Components/Auth/Signup/signup";
import Terms from "../Components/SupportLinks/TermsConditions/TermsCondition";
import Support from "../Components/SupportLinks/Support/Support";
import NotFound from "../Components/NotFound/NotFound";
import HomePolicy from "../Components/HomeFooter/PolicyCondition/HomePolicy/HomePolicy";
import EmailVerifcation from "../Components/Auth/emailVerification/emailVerification";
import Reminder from "../Components/Auth/Reminder/Reminder";
import Alogoritm from "../Components/Alogorithm/Algorithm";
import AgeQuestion from "../Components/Auth/question/AgeQuestion";
import MyAccountSetting from "../Components/Setting/MyAccountSetting";
import ExperienceQuestion from "../Components/Auth/question/experiense";
import GenderQuestion from "../Components/Auth/question/GenderQuestion";
import LanguageQuestion from "../Components/Auth/question/LanuguageQues";
import NewHistItemDetail from "../Components/HistoryItemDetail/NewHistoryDeatil";
import NewRatingSection from "../Components/RatingSection/NewRatingSection";
import NewSetting from "../Components/Setting/NewSetting";
import EditAccountSetting from "../Components/Setting/EditAccounSetting";
import NewTutorial from "../Components/Tutorial/NewTutorial";
import IntroVideo from "../Components/IntroVideo/IntroVideo";
import BasicCalibration from "../Components/BasicCalibration/BasicCalibration";
import LanguageSetting from "../Components/Setting/LanguageSettting/LanguageSetting.js";
import ChangePassword from "../Components/Setting/ChangePassword/ChangePassword";
import ManageSubscription from "../Components/Setting/ManageSubscription/ManageSubscription";
import SetReminder from "../Components/Setting/SetReminder/SetReminder";
import InviteFriend from "../Components/Setting/InviteFriend/InviteFriend";
import MyData from "../Components/Setting/MyData/MyData";
import BeforeRatingSection from "../Components/RatingSection/BeforeRatingSection";
import NewFavouriteDetail from "../Components/FavoriteDetail/NewFavouriteDetail";
import AboutUs from "../Components/Setting/AboutUs/AboutUs";
import FeedBackRatting from "../Components/IntroVideo/Feedback/newFeedBack";
import Privacy from "../Components/SupportLinks/privacy/privacy";
import VerifyEmail from "../Components/VerifyEmail/VerifyEmail";
import PreStudySurvey from "../Components/StudySurvey/PreStudySurvey.js";
import MidStudySurvey from "../Components/StudySurvey/MidStudySurvey.js";
import PostStudySurvey from "../Components/StudySurvey/PostStudySurvey.js";

function Routes(props) {
  const currentUser = useSelector((st) => st.currentUser);

  const location = useLocation();

  if (location.pathname === "/" && !currentUser.userID) {
    window.location.href = "https://muviklabs.com/breathconductor"
    return;
  }
 
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Redirect to="/login" />
        </Route>
        <Route exact path="/pre-study-survey">
          <PreStudySurvey />
        </Route>

        <Route exact path="/mid-study-survey">
          <MidStudySurvey />
        </Route>

        <Route exact path="/post-study-survey">
          <PostStudySurvey />
        </Route>

        <Route exact path="/verify-email">
          <VerifyEmail />
        </Route>

        <Route exact path="/Video">
          {currentUser.userID ? <Redirect to="/login" /> : <HomeVideo />}
        </Route>
        <Route exact path="/Home-Policy">
          {currentUser.userID ? <Redirect to="/login" /> : <HomePolicy />}
        </Route>
        <Route exact path="/login">
          {currentUser.userID ? <Redirect to="/check" /> : <Signinpage />}
        </Route>
        <Route exact path="/SignUp">
          {currentUser.userID ? <Redirect to="/check" /> : <Signup />}
        </Route>
        <Route exact path="/Forgot">
          {!currentUser.userID && currentUser.is_verified !== 0 ? (
            <Forgot />
          ) : (
            <Redirect to="/check" />
          )}
        </Route>
        <Route exact path="/check">
          {currentUser.userID && currentUser.is_verified == 0 ? (
            <EmailVerifcation />
          ) : (
            <Redirect to="algorithm" />
          )}
        </Route>
        <Route exact path="/algorithm">
          {currentUser.is_verified == 1 && currentUser.is_subscribe == 0 ? (
            <Alogoritm />
          ) : (
            <Redirect to="/age-question" />
          )}
        </Route>
        <Route exact path="/age-question">
          {!currentUser.is_survey_complete ||
          (currentUser.is_survey_complete == 0 &&
            currentUser.is_subscribe == 1 &&
            currentUser.is_verified == 1 &&
            !currentUser.is_home) ? (
            <AgeQuestion />
          ) : (
            <Redirect to="/new-tutorial" />
          )}
        </Route>
        <Route exact path="/experience-question">
          {!currentUser.is_survey_complete ||
          (currentUser.is_survey_complete == 0 &&
            currentUser.is_subscribe == 1 &&
            currentUser.is_verified == 1 &&
            !currentUser.is_home) ? (
            <ExperienceQuestion />
          ) : (
            <Redirect to="/new-tutorial" />
          )}
        </Route>
        <Route exact path="/gender-question">
          {!currentUser.is_survey_complete ||
          (currentUser.is_survey_complete == 0 &&
            currentUser.is_subscribe == 1 &&
            currentUser.is_verified == 1 &&
            !currentUser.is_home) ? (
            <GenderQuestion />
          ) : (
            <Redirect to="/new-tutorial" />
          )}
        </Route>
        <Route exact path="/language-question">
          {!currentUser.is_survey_complete ||
          (currentUser.is_survey_complete == 0 &&
            currentUser.is_subscribe == 1 &&
            currentUser.is_verified == 1 &&
            !currentUser.is_home) ? (
            <LanguageQuestion />
          ) : (
            <Redirect to="/new-tutorial" />
          )}
        </Route>
        <Route exact path="/new-tutorial">
          {currentUser.is_tutorial === "1" ? (
            <NewTutorial />
          ) : (
            <Redirect to="/home" />
          )}
        </Route>
        <Route exact path="/home">
          {currentUser.userID &&
          currentUser.is_subscribe == 1 &&
          currentUser.is_verified == 1 ? (
            <SessionSection />
          ) : (
            <Redirect to="/login" />
          )}
        </Route>
        <Route exact path="/Home/before-rating-section">
          {currentUser.userID &&
          currentUser.is_subscribe == 1 &&
          currentUser.is_verified == 1 ? (
            <BeforeRatingSection />
          ) : (
            <Redirect to="/login" />
          )}
        </Route>
        <Route exact path="/Home/new-intro">
          {currentUser.userID &&
          currentUser.is_subscribe == 1 &&
          currentUser.is_verified == 1 ? (
            <IntroVideo />
          ) : (
            <Redirect to="/login" />
          )}
        </Route>
        <Route exact path="/calibration">
          {currentUser.userID &&
          currentUser.is_subscribe == 1 &&
          currentUser.is_verified == 1 ? (
            currentUser.is_survey_complete == 0 ? (
              <Redirect to="/age-question" />
            ) : (
              <BasicCalibration />
            )
          ) : (
            <Redirect to="/login" />
          )}
        </Route>
        <Route exact path="/Home/new-after-rating">
          {currentUser.userID &&
          currentUser.is_subscribe == 1 &&
          currentUser.is_verified == 1 ? (
            <NewRatingSection />
          ) : (
            <Redirect to="/login" />
          )}
        </Route>
        <Route exact path="/Home/new-feed-back">
          {currentUser.userID &&
          currentUser.is_subscribe == 1 &&
          currentUser.is_verified == 1 ? (
            <FeedBackRatting />
          ) : (
            <Redirect to="/login" />
          )}
        </Route>
        <Route exact path="/MyHistory">
          {currentUser.userID &&
          currentUser.is_subscribe == 1 &&
          currentUser.is_verified == 1 ? (
            <MyHistory />
          ) : (
            <Redirect to="/login" />
          )}
        </Route>
        <Route exact path="/MyHistory/HistItemDetail/:id">
          {currentUser.userID &&
          currentUser.is_subscribe == 1 &&
          currentUser.is_verified == 1 ? (
            <NewHistItemDetail />
          ) : (
            <Redirect to="/login" />
          )}
        </Route>
        <Route exact path="/MyFavorites">
          {currentUser.userID &&
          currentUser.is_subscribe == 1 &&
          currentUser.is_verified == 1 ? (
            <MyFavorites />
          ) : (
            <Redirect to="/login" />
          )}
        </Route>
        <Route exact path="/MyFavorites/FavoriteDetail/:Id">
          {currentUser.userID &&
          currentUser.is_subscribe == 1 &&
          currentUser.is_verified == 1 ? (
            <NewFavouriteDetail />
          ) : (
            <Redirect to="/login" />
          )}
        </Route>
        <Route exact path="/Setting">
          {currentUser.userID &&
          currentUser.is_subscribe == 1 &&
          currentUser.is_verified == 1 ? (
            <NewSetting />
          ) : (
            <Redirect to="/login" />
          )}
        </Route>
        <Route exact path="/Setting/AboutUs">
          {currentUser.userID &&
          currentUser.is_subscribe == 1 &&
          currentUser.is_verified == 1 ? (
            <AboutUs />
          ) : (
            <Redirect to="/login" />
          )}
        </Route>
        <Route exact path="/Setting/Support">
          {currentUser.userID &&
          currentUser.is_subscribe == 1 &&
          currentUser.is_verified == 1 ? (
            <Support />
          ) : (
            <Redirect to="/login" />
          )}
        </Route>

        <Route exact path="/Setting/Language-setting/:route">
          {currentUser.userID &&
          currentUser.is_subscribe == 1 &&
          currentUser.is_verified == 1 ? (
            <LanguageSetting />
          ) : (
            <Redirect to="/login" />
          )}
        </Route>
        <Route exact path="/Setting/account-setting">
          {currentUser.userID &&
          currentUser.is_subscribe == 1 &&
          currentUser.is_verified == 1 ? (
            <MyAccountSetting />
          ) : (
            <Redirect to="/login" />
          )}
        </Route>
        <Route exact path="/Setting/edit-account-setting">
          {currentUser.userID &&
          currentUser.is_subscribe == 1 &&
          currentUser.is_verified == 1 ? (
            <EditAccountSetting />
          ) : (
            <Redirect to="/login" />
          )}
        </Route>
        <Route exact path="/Setting/Password-change">
          {currentUser.userID &&
          currentUser.is_subscribe == 1 &&
          currentUser.is_verified == 1 ? (
            <ChangePassword />
          ) : (
            <Redirect to="/login" />
          )}
        </Route>
        <Route exact path="/Setting/Invite-friend">
          {currentUser.userID &&
          currentUser.is_subscribe == 1 &&
          currentUser.is_verified == 1 ? (
            <InviteFriend />
          ) : (
            <Redirect to="/login" />
          )}
        </Route>
        <Route exact path="/Setting/Manage-subscriptions">
          {currentUser.userID &&
          currentUser.is_subscribe == 1 &&
          currentUser.is_verified == 1 ? (
            <ManageSubscription />
          ) : (
            <Redirect to="/login" />
          )}
        </Route>
        <Route exact path="/Setting/SetReminder">
          {currentUser.userID &&
          currentUser.is_subscribe == 1 &&
          currentUser.is_verified == 1 ? (
            <SetReminder />
          ) : (
            <Redirect to="/login" />
          )}
        </Route>
        <Route exact path="/Setting/MyData">
          {currentUser.userID &&
          currentUser.is_subscribe == 1 &&
          currentUser.is_verified == 1 ? (
            <MyData />
          ) : (
            <Redirect to="/login" />
          )}
        </Route>
        <Route exact path="/Setting/TermCondition">
          {currentUser.userID &&
          currentUser.is_subscribe == 1 &&
          currentUser.is_verified == 1 ? (
            <Terms />
          ) : (
            <Redirect to="/login" />
          )}
        </Route>
        <Route exact path="/Setting/Privacy">
          {currentUser.userID &&
          currentUser.is_subscribe == 1 &&
          currentUser.is_verified == 1 ? (
            <Privacy />
          ) : (
            <Redirect to="/login" />
          )}
        </Route>
        <Route exact path="/Home/:expId">
          {currentUser.userID &&
          currentUser.is_subscribe == 1 &&
          currentUser.is_verified == 1 ? (
            <SessionSection />
          ) : (
            <Redirect to="/login" />
          )}
        </Route>
        <Route exact path="/Reminder">
          {currentUser.userID &&
          currentUser.is_subscribe == 1 &&
          currentUser.is_verified == 1 ? (
            <Reminder />
          ) : (
            <Redirect to="/login" />
          )}
        </Route>
        <Route exact path="" component={NotFound} />
      </Switch>
    </Router>
  );
}
export default Routes;
