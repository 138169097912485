import React, { useState } from "react";
import "./SetReminder.css";
import { logout, deleteToken, getUser } from "../.././../actions/currentUser";
import { Icon } from "@iconify/react";
import Nav from "../../Header/header.js";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SublyApi from "../../../helpers/Api";
import { useEffect } from "react";
import DatePicker from "react-datepicker";
import { deleteExcerciseList } from "../.././../actions/getExcercise";
import "react-datepicker/dist/react-datepicker.css";
import { Toast } from "../../../commonFile";
import { SmallLoader } from "../../../commonFile";
import { t } from "i18next";
import { getinternet } from "../../../commonfunction";
import moment from "moment";
import { deleteDefaultList } from "../.././../actions/defaultList";
import { clearErr } from "../.././../actions/errors";
import { deleteExperience } from "../.././../actions/getexperiense";
import { deleteGender } from "../.././../actions/getgender";
import { deleteLanguage } from "../.././../actions/getlanguage";
import { deleteAge } from "../.././../actions/getsurvey";
import { deletTimeNarration } from "../.././../actions/timeNarrationList";
import { removeTempData } from "../.././../actions/tempData";
import { deleteNarrationTheme } from "../.././../actions/narrationTheme";
import { deleteDefault } from "../.././../actions/homeSession";
import { deleteGoalTimeList } from "../.././../actions/goalTimeList";
import { deleteNewDefaultList } from "../.././../actions/defaultList";


function SetReminder() {
  const [load, setLoad] = useState();
  const [value, onChange] = useState("");
  const [reminderValue, setReminderValue] = useState();
  const [timeeValue, setTimeValue] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [remind, setRemind] = useState();
  const [time, setTime] = useState("");
  const [timezones, settimezones] = useState()
  const currentUser = useSelector((st) => st.currentUser);
  const history = useHistory();
  const token = useSelector((st) => st.token);
  const dispatch = useDispatch();

  // ----logOut while Tken expired----

  const signOut = () => {
    dispatch(logout());
    dispatch(deleteToken());
    dispatch(deleteDefaultList());
    dispatch(clearErr());
    dispatch(deleteExcerciseList());
    dispatch(deleteExperience());
    dispatch(deleteGender());
    dispatch(deleteLanguage());
    dispatch(deleteAge());
    dispatch(deletTimeNarration());
    dispatch(removeTempData());
    dispatch(deleteNarrationTheme());
    dispatch(deleteDefault());
    dispatch(deleteGoalTimeList());
    dispatch(deleteNewDefaultList());
    dispatch(deleteDefaultList());
    history.push("/login");
  };

  // -----API for default set-----

  useEffect(() => {
    async function UpdateReminder() {
      setTimeValue("");
      const setReminder = await SublyApi.accountDetails(token, currentUser.device_id, currentUser.device_timezone);
      if (
        setReminder &&
        setReminder.data.error_type === "USER_NOT_FOUND" &&
        setReminder.data.status_code === 400
      ) {
        // Toast.fire({
        //   icon: "error",
        //   title: setReminder.data.message,
        // });
        signOut();
      }
      else {
        dispatch(getUser({ remindvalue: setReminder && setReminder.data && setReminder.data.reminder_data ? setReminder.data.reminder_data.is_remind : 0 }));
        const convertTime = setReminder && setReminder.data && setReminder.data.reminder_data && setReminder.data.reminder_data
          ? setReminder.data.reminder_data.reminder_time.toString()
          : "";
        const convertTimeNew = moment.utc(convertTime).local();
        const finalDate = setReminder && setReminder.data && setReminder.data.reminder_data
          ? new Date(convertTimeNew)
          : "";
        const sendingDate = () => setTimeValue(finalDate);
        sendingDate();

        // let toggleValue = setReminder && setReminder.data && setReminder.data.reminder_data
        //   ? Number(setReminder.data.reminder_data.is_remind)
        //   : 0;


        setReminder && setReminder.data && setReminder.data.reminder_data && setReminderValue(setReminder.data.reminder_data);
      }
    }
    UpdateReminder();
    convertFormat();

  }, []);

  // ---------------changing format of date and time and sending to API----------------

  const convertFormat = () => {
    const date = new Date();
    // const offset = date.getTimezoneOffset();
    // settimezones(offset);
    const singleDate = date.getDate();
    const year = date.getFullYear();
    const singleMonth = date.getMonth();
    const newDate =
      year.toString() +
      "-" +
      singleMonth.toString() +
      "-" +
      singleDate.toString();
    onChange(newDate);
  };

  // -----Here i am sending the data to API-----

  const handleSubmit = async () => {
    const online = await getinternet();
    if (online === false) {
      Toast.fire({
        icon: "error",
        title: t("noiternet"),
      });
    }
    else {
      setLoad(true);
      let requestData = new FormData();
      requestData.append("is_reminder", `${currentUser.remindvalue}`);
      requestData.append(
        "remind_time",
        `${time
          ? value.toString() + " " + time.toString()
          : reminderValue ? reminderValue.reminder_time : moment().format("YYYY-MM-DD HH:mm:ss")
        }`
      );
      requestData.append("time_zone", `${currentUser.device_timezone}`);
      await SublyApi.setReminder(token, requestData, currentUser.device_id, currentUser.device_timezone)
        .then((responsejson) => {
          if (responsejson &&
            responsejson.data.error_type == "USER_NOT_FOUND" &&
            responsejson.data.status_code === 400) {
            // Toast.fire({
            //   icon: "error",
            //   title: responsejson.data.message,
            // });
            signOut();
          }
          else {
            if (responsejson &&
              responsejson.data.error_type == "USER_NOT_FOUND" &&
              responsejson.data.status_code === 400) {
              Toast.fire({
                icon: "error",
                title: responsejson.data.message,
              });
              setLoad(false);
            } else {
              dispatch(getUser({ remindvalue: responsejson.data.updated_data.is_remind == false ? 0 : 1 }));
              Toast.fire({
                icon: "success",
                title: responsejson.message
                  ? responsejson.message
                  : t("remindupdatemsg"),
              });
              // setRemind(0);
              setLoad(false);
              history.push("/Setting");
            }
          }
        })
        .catch(() => { });
    }
  };
  async function checktooglesta(checkedvalue) {
    // if (currentUser.remindvalue == 1) {
    //   dispatch(getUser({ remindvalue: 0 }));
    // }
    // else {
    //   dispatch(getUser({ remindvalue: 1 }));
    // }
    let requestData = new FormData();
    requestData.append("is_reminder", `${checkedvalue == 1 ? 1 : 0}`);
    requestData.append(
      "remind_time",
      `${time
        ? value.toString() + " " + time.toString()
        : reminderValue && reminderValue.reminder_time ? reminderValue.reminder_time : moment().format("YYYY-MM-DD HH:mm:ss")
      }`
    );
    requestData.append("time_zone", `${currentUser.device_timezone}`);
    await SublyApi.setReminder(token, requestData, currentUser.device_id, currentUser.device_timezone).then((responsejson) => {
      if (
        responsejson &&
        responsejson.data.error_type == "USER_NOT_FOUND" &&
        responsejson.data.status_code === 400
      ) {
        // Toast.fire({
        //   icon: "error",
        //   title: responsejson.data.message,
        // });
        signOut();
      }
      else {
        if (responsejson.data.status_code == 400) {
          Toast.fire({
            icon: "error",
            title: `${responsejson.data.message}`,
          })
        }
        else {
          dispatch(getUser({ remindvalue: responsejson.data.updated_data.is_remind ? 1 : 0 }));
          Toast.fire({
            icon: "success",
            title: `${responsejson.message}`,
          })
        }
      }
    })
  }
  function PreventKeyHandler(e) {
        if (e.keyCode !== 48 &&
      e.keyCode !== 49 &&
      e.keyCode !== 50 &&
      e.keyCode !== 51 &&
      e.keyCode !== 52 &&
      e.keyCode !== 53 &&
      e.keyCode !== 54 &&
      e.keyCode !== 55 &&
      e.keyCode !== 56 &&
      e.keyCode !== 57 &&
      e.keyCode !== 65 &&
      e.keyCode !== 77 &&
      e.keyCode !== 80 &&
      e.keyCode !== 186 &&
      e.keyCode !== 32 &&
      e.keyCode !== 8) {
      e.preventDefault();
      return false;
    }
  }

  return (
    <>
      <section>
        <Nav></Nav>
        <div className="setReminder_Container">
          <div className="setReminder_Body">
            <div className="setReminder_Header">
              <div className="reminderBack_Icon">
                <Icon
                  icon="eva:arrow-ios-back-fill"
                  color="white"
                  width="24"
                  cursor="pointer"
                  height="24"
                  onClick={() => {
                    history.push("/setting");
                  }}
                />
              </div>
              <div className="reminder_Title">
                <h5>{t("SETREMINDER")}</h5>
              </div>
            </div>
            <div className="setReminder_daily">
              <p>{t("DAILYREMINDER")}</p>
              <Toggle
                aria-label="No label tag"
                checked={currentUser.remindvalue == 1 ? 1 : 0}
                onChange={(e) => checktooglesta(e.target.checked)}
              />
            </div>
            <div className="reminder_Time">
              <div className="time_Slot">
                <span>{t("REMINDERME")}</span>
              </div>
              <div style={{ marginLeft: "5px" }}>
                <DatePicker
                  id="my_date_picker"
                  name="my_date_picker"
                  type="date"
                  selected={timeeValue ? timeeValue : startDate}
                  onChange={(date) => {
                    let time = date && date.toLocaleTimeString();
                    setTime(time);
                    setStartDate(date);
                    setTimeValue(date);
                  }}
                  placeholderText="hh:mm am/pm"
                  onKeyDown={(e) => PreventKeyHandler(e)}
                  readOnly={currentUser.remindvalue == 1 ? false : true}
                  disabled={currentUser.remindvalue == 1 ? false : true}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                />
              </div>
            </div>
            <div className="remindLoader">{load ? <SmallLoader /> : null}</div>
            {/* <p className="forError">{errorMessage}</p> */}
            <div className="reminder_Save_btn">
              <button type="button" onClick={() => currentUser && currentUser.remindvalue == 1 ? handleSubmit() : ""}>
                {t("SAVE")}
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SetReminder;
