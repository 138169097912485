import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { confirm } from "react-confirm-box";
import "./History.css";
import clearBtn from "../../Assets/Images/closeIcon.svg";
import { useHistory } from "react-router-dom";
import { NavLink } from "react-router-dom";
import NextArrow from "../../Assets/Images/next.svg";
import Nav from "../../Components/Header/header";
import Footer from "../Footer/Footer";
import SublyApi from "../../.../../helpers/Api";
import { Toast } from "../../commonFile";
import { logout, deleteToken } from "../../actions/currentUser";
import { Loader } from "../../commonFile";
import {
  deleteDefaultList,
  deleteNewDefaultList,
} from "../../actions/defaultList";
import { getinternet } from "../../commonfunction";
import { Getlanguage } from "../../commonfunction";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { clearErr } from "../../actions/errors";
import { deleteExcerciseList } from "../../actions/getExcercise";
import { deleteExperience } from "../../actions/getexperiense";
import { deleteGender } from "../../actions/getgender";
import { deleteLanguage } from "../../actions/getlanguage";
import { deleteAge } from "../../actions/getsurvey";
import { deletTimeNarration } from "../../actions/timeNarrationList";
import { removeTempData } from "../../actions/tempData";
import { deleteNarrationTheme } from "../../actions/narrationTheme";
import { deleteDefault } from "../../actions/homeSession";
import { deleteGoalTimeList } from "../../actions/goalTimeList";



function MyHistory() {
  const [historyDataList, setFormData] = useState(null);
  const [resultfail, setresultfail] = useState()
  const [loading, setLoading] = useState(false);
  const [notfound, setNotFound] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const token = useSelector((st) => st.token);
  const currentUser = useSelector((st) => st.currentUser);
  // -----icons for rating-----
  const { t, i18n } = useTranslation();
  useEffect(() => {
    async function setlanguage() {
      const geti18value = await Getlanguage(parseInt(currentUser.default_language ? currentUser.default_language : 1));
      i18n.changeLanguage(geti18value);
    }
    setlanguage();
  }, []);


  const signOut = () => {
    dispatch(logout());
    dispatch(deleteToken());
    dispatch(deleteDefaultList());
    dispatch(clearErr());
    dispatch(deleteExcerciseList());
    dispatch(deleteExperience());
    dispatch(deleteGender());
    dispatch(deleteLanguage());
    dispatch(deleteAge());
    dispatch(deletTimeNarration());
    dispatch(removeTempData());
    dispatch(deleteNarrationTheme());
    dispatch(deleteDefault());
    dispatch(deleteGoalTimeList());
    dispatch(deleteNewDefaultList());
    dispatch(deleteDefaultList());
    history.push("/login");
  };

  // it's api work take history data by useEffect hook .
  useEffect(() => {
    async function getTodos() {
      setLoading(true);
      const historyData = await SublyApi.exerciseHistory(token, currentUser.device_id, currentUser.device_timezone);
      if (
        historyData &&
        historyData.data.error_type === "USER_NOT_FOUND" &&
        historyData.data.status_code === 400
      ) {
        signOut();
      }
      else {
        if (historyData) {
          if (historyData.data.data_found == false) {
            setLoading(false);
            setNotFound(historyData.message);
          } else {
            setLoading(false);
          }
        }
        setFormData(historyData ? historyData.data.exercise_history_list : "");
      }
    }

    getTodos(historyDataList);
  }, [resultfail === true]);



  // it's api work delete all history from history component.

  const clearAllHistory = async () => {
    setresultfail(false)
    const online = await getinternet();

    if (online === false) {
      Toast.fire({
        icon: "error",
        title: t("noiternet"),
      });
    }
    else {
      const result = await confirm(t("historyconfirmdel"));
      if (result == true) {
        await SublyApi.removeAllHistory(token, currentUser.device_id, currentUser.device_timezone).then((responsejson) => {
          if (
            responsejson &&
            responsejson.data.error_type == "USER_NOT_FOUND" &&
            responsejson.data.status_code === 400
          ) {
            signOut();
          }
          else {
            if (responsejson.data.status_code === 400) {
              Toast.fire({
                icon: "error",
                title: `${responsejson.data.message}`,
              });
            }
            else {
              if (result && responsejson.status_code === 200) {
                Toast.fire({
                  icon: "success",
                  title: t("historyallremove"),
                });
                setresultfail(true)
              }
            }
          }

        });
      }
    }
  }
  return (
      <section className={`HistoryBody ${resultfail == false ? "displaynone" : "HistoryBody"}`} >
      <Nav />

      <div className="historypopup" >
        <div className="HistoryContain">
          <div className="HistHeader">
            <div className="HisTitle">
              <h2> {t("HISTORY")}</h2>
            </div>

            {historyDataList ? (
              <div className="HisBtn">
                <a
                  href="javascript:void(0);"
                  onClick={historyDataList ? clearAllHistory : " read"}
                >
                  <img src={clearBtn} alt="clearlogo" />
                  <span>{t("CLEARALL")}</span>
                </a>
              </div>
            ) : (
              ""
            )}
          </div>
          <span>{loading ? <Loader /> : null}</span>
          <div style={{ paddingBottom: "39px", marginTop: "5px" }}>
            <div className="HistList">
              {historyDataList ? (
                historyDataList.map((item, index) => (
                  <div className="HistItem">
                    <NavLink
                      key={index}
                      exact
                      to={`/MyHistory/HistItemDetail/${item.exerciseHistoryID}`}
                    >
                      <div className="HisText">
                        <h3>{item.exercise.title?.toLowerCase()} </h3>
                      </div>
                      <div className="HistIcon">
                        <span>
                          {item.exercise.goal} -
                          {moment.utc(item.created_at).local().format("YYYY-MM-DD | hh:mm:ss")}
                        </span>
                        <img src={NextArrow} alt="arrow" />
                      </div>
                    </NavLink>
                  </div>
                ))
              ) : (
                <p className="notrecord">{notfound}</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
}

export default MyHistory;
