import { useState } from "react";
import { Modal } from "react-bootstrap";
import { Carousel } from "react-bootstrap";
import ReactPlayer from "react-player";
import { ImCross } from "react-icons/im";
export async function Getlanguage(props) {
    let language;
    switch (props) {
        case 1:
            language = "en"
            break;
        case 2:
            language = "fr"
            break;
        case 3:
            language = "ge"
            break;
        case 4:
            language = "arab"
            break;
        case 5:
            language = "uk"
            break;
        case 6:
            language = "es"
            break;
        default:
            language = "en"
    }
    return language;

}
export function getinternet() {
    const online = navigator.onLine;
    if (online == false) {
        return false;
    }
    else {
        return true;
    }
}

export function Reactplayers({ shows, setShows }) {
    return (
        <>
        <div>
            <Modal
                show={shows}
                onHide={() => setShows(false)}
                className="common_reactplayer"
            >
                <Modal.Header>
                        <ImCross color="white" onClick={() => setShows(false)} />
                </Modal.Header>
                <Modal.Body>
                            <ReactPlayer
                                playsinline
                                config={{
                                    file: {
                                        attributes: { controlsList: "nodownload" },
                                    },
                                }}
                                controls={true}
                                url="https://youtu.be/lTUcMZjhhFY"
                                width="100%"
                                borderRadius="12px"
                                height="338px"
                                playing={true}
                            />
                </Modal.Body>
            </Modal>
            </div>
        </>

    );
}


