import { responsivePropType } from "react-bootstrap/esm/createUtilityClasses";
import SublyApi from "../helpers/Api";
import { showErr, clearErr } from "./errors";
import {FETCH_HOMESESSION,REMOVE_HOMESCREEN
} from "./types";

export function homeList(data) {
return async function (dispatch) {
    try {
      dispatch(clearErr());
      const defaultData = await SublyApi.getHomeList(data);
      dispatch(getDefault(defaultData.data));
     } catch (err) {
      dispatch(showErr(err));
    }
  };
}

 

function getDefault(defaultData) {
  return {
    type: FETCH_HOMESESSION,
    defaultData,
  };
}
 
export function deleteDefault(defaultData) {
  return {
    type: REMOVE_HOMESCREEN,
    defaultData,
  };
}

 