import React from "react";
import "./AboutUs.css";
import Nav from "../../Header/header";
import { Icon } from "@iconify/react";
import { useHistory } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import stronghealth from "../../../Assets/Images/strong1.svg";
import brain from "../../../Assets/Images/brain1.svg";
import bedIcon from "../../../Assets/Images/bed1.svg";
import { TRANSLATIONS_EN } from "../../../translations/en/translation";
function AboutUs() {
  const history = useHistory();
  return (
    <>
      <section>
        <Nav></Nav>
        <div className="aboutUs_Container">
          <div className="aboutUs_Body">
            <div className="aboutUs_Header">
              <div className="aboutUsrBack_Icon">
                <Icon
                  icon="eva:arrow-ios-back-fill"
                  color="white"
                  width="24"
                  cursor="pointer"
                  height="24"
                  onClick={() => {
                    history.goBack();
                  }}
                />
              </div>
              <div className="aboutUs_Title">
                <h5>   {TRANSLATIONS_EN.setting_about_title}</h5>
              </div>
            </div>
            <p className="title_Explain">
              {TRANSLATIONS_EN.setting_about_title_dis}
            </p>
            <div className="aboutUs_Area">
              <Row className="stress_Body">
                <Col lg={2}>
                  <div className="build_stress_Icon">
                    <img src={stronghealth} alt="Build Stress" />
                  </div>
                </Col>
                <Col lg={10}>
                  <div className="Stress_Contain">
                    <h5>   {TRANSLATIONS_EN.setting_about_health_title}</h5>
                    <p>
                      {TRANSLATIONS_EN.setting_about_health_disc}
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className="improve_Body">
                <Col lg={2}>
                  <div className="improve_Task_Icon">
                    <img src={brain} alt="improve task" />
                  </div>
                </Col>
                <Col lg={10}>
                  <div className="improve_Contain">
                    <h5> {TRANSLATIONS_EN.setting_about_mind_title}</h5>
                    <p>
                      {TRANSLATIONS_EN.setting_about_mind_disc}
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className="fall_Body">
                <Col lg={2}>
                  <div className="all_Asleep_Icon">
                    <img src={bedIcon} alt=" fall asleep" />
                  </div>
                </Col>
                <Col lg={10}>
                  <div className="asleep_Contain">
                    <h5>  {TRANSLATIONS_EN.setting_about_sleep_title}</h5>
                    <p>
                      {TRANSLATIONS_EN.setting_about_sleep_disc}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AboutUs;
