import React, { useState, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import "./Forgot.css";
import username from "../../../Assets/Images/username.svg";
import arrowimage from "../../../Assets/Images/backarrow.svg";
import SublyApi from "../../../helpers/Api";
import smalllogo from "../../../Assets/Images/Logo1.svg";
import { useForm } from "react-hook-form";
import { Toast } from "../../../commonFile";
import { SmallLoader } from "../../../commonFile";
import { useTranslation } from "react-i18next";
import { Getlanguage } from "../../../commonfunction";
import { useSelector } from "react-redux";
import { getTokenArr } from "../../../firebase";
import { getinternet } from "../../../commonfunction";
function Forgot() {
  const [isTokenFound, setTokenFound] = useState();
  const [serverErrorMessage, setServerErrorMessage] = useState("");
  //it's state use show success messages
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState("");
  // medtitaion value get by redux
  const language = useSelector((st) => st.getlanguage);
  // get survey convert to number because api get value in number
  const changelanguage = Number(language.defaultData);
  const history = useHistory();
  const currentUser = useSelector((st) => st.currentUser);
  const { t, i18n } = useTranslation();
  //
  useEffect(() => {
    async function setlanguage() {
      const geti18value = await Getlanguage(parseInt(currentUser.default_language ? currentUser.default_language : changelanguage));
      i18n.changeLanguage(geti18value);
    }
    setlanguage();
  }, []);
  getTokenArr(setTokenFound);
  const INITIAL_STATE = {
    email: "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // ----sending email to API----

  async function OnSubmit(formData) {
    const online = await getinternet();

    if (online === false) {
      Toast.fire({
        icon: "error",
        title: t("noiternet"),
      });
    }
    else {
      setLoading(true)
      const timezone = await Intl.DateTimeFormat().resolvedOptions().timeZone;
      var urlencoded = new URLSearchParams();
      urlencoded.append("email", `${formData.email}`);
      urlencoded.append("firebase_token", isTokenFound ? isTokenFound : "");
      await SublyApi.resetPassword(urlencoded, isTokenFound, timezone, changelanguage ? changelanguage : 1)
        .then((responsejson) => {
          if (responsejson.status == 400) {
            setLoading(false)
            Toast.fire({
              icon: "error",
              title: `${responsejson.data.message}`,
            })
            setSuccessMessage("");
          } else {
            setLoading(false)
            setServerErrorMessage("");
            setSuccessMessage(responsejson.message);
            Toast.fire({
              icon: "success",
              title: `${responsejson.message}`,
            })
            history.push("/login")
          }
        })
        .catch(() => {
        });
    }
  }


  return (
    <>
      <section className="ForGotBody">
        <div className="container">
          <div className="ForgotLogo" >
            <button style={{ cursor: "pointer" }}>
              <img style={{ cursor: "pointer", width: "294px"}} src={smalllogo} alt="samlllogo" onClick={()=>{ if(!currentUser.userID) { history.push("/")}}} />
            </button>
          </div>
          <div className="ForgotContainer">
            <div className="ForgotConatin">
              <div className="forgotinnerbody">
                <div className="arrowimage">
                  <NavLink to="/LogIn">
                    <img src={arrowimage} alt="forgotarrow" width="40px" height="40px" style={{ verticalAlign: "-webkit-baseline-middle" }} />
                  </NavLink>
                </div>
                <div className="forgotouterbody">
                  <div className="forgotform">
                    <div className="ForgotHead">
                      <h3>{t("RESETPASSWORD")}</h3>
                      <span>{t("ENTERYOURDET")}</span>
                    </div>
                    <form
                      autoComplete="off"
                      className="Form_Area"
                      onSubmit={handleSubmit(OnSubmit)}
                    >
                      <div className="forgothomebody">
                        <div className="forgotformimage">
                          <img src={username} alt="username" style={{ verticalAlign: "-webkit-baseline-middle" }} />
                        </div>
                        <input
                          className="inputfieldauto"
                          id="email"
                          name="email"
                          placeholder={t("emailadd")}
                          {...register("email", {
                            required: t("emailreq"),
                            pattern: {
                              value:
                                /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/,
                              message: t("pentvalemail"),
                            },
                          })}
                          autoComplete="off"
                        />
                      </div>
                      {
                        <p className="errorMessageColor">
                          {errors.email?.message}
                        </p>
                      }
                      {errors.email == null ? (
                        <p className="errorMessageColor">
                          {serverErrorMessage}
                        </p>
                      ) : (
                        ""
                      )}

                      {errors.email == null ? (
                        <p className="MessageColor">{successMessage}</p>
                      ) : (
                        ""
                      )}
                      {loading ? <SmallLoader /> : ""}
                      <button className="btn" type={`${loading ? "button" : "submit"}`}>{t("RESET")}</button>
                      <div className="LinksArea">
                        <div>
                          <p>{t("DONTACCOUNT")}</p>
                        </div>
                        <div>
                          <NavLink to="/SignUp">{t("SIGNUP")}</NavLink>
                        </div>
                      </div>
                      <div className="LinksArea">
                        <div>
                          <p>{t("ALREADYACC")}</p>
                        </div>
                        <div>
                          <NavLink to="/LogIn">{t("SIGNIN")}</NavLink>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Forgot;
