import React from "react";
import './HomePolicy.css';
import PolicyHeader from "../PolicyHeader/PolicyHeader";
import PolicyFooter from "../PolicyFooter/PolicyFooter";

function HomeCondition() {
    return (
        <>
            <PolicyHeader />
            <section>
                <div className="PolicyContain">
                    <div className="container">
                        <h4>Privacy Policy</h4>

                        <p>This Breath Conductor™ SERVICE is provided by Muvik Labs, LLC at no cost and is intended for beta testing use. This page is used to inform visitors regarding Muvik Labs’ policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service.</p>
                        <p>If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.</p>
                        <p>Muvik Labs takes user privacy very seriously. The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at Breath Conductor™ unless otherwise defined in this Privacy Policy.</p>

                        <h4>Information Collection and Use</h4>

                        <p>For a better experience, while using our Service, we may ask you to provide us with certain personally identifiable information, including but not limited to age, name, meditation experience, feeling rating and feedback on an exercise. The information that we request will be retained by us to improve the product experience and used as described in this privacy policy.</p>
                        <p>We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>

                        <h4>Links to Other Sites</h4>

                        <p>This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>

                        <h4>Children’s Privacy</h4>

                        <p>These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.</p>

                        <h4>Changes to This Privacy Policy</h4>

                        <p>We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. This policy is effective as of 2020-07-07.</p>
                        
                        <h4>Contact Us</h4>

                        <p>If you have any questions or suggestions about our Terms and Conditions, do not hesitate to contact us at contact@breathconductor.com.</p>
                    </div>
                </div>
            </section>
            <PolicyFooter />
        </>
    )
}

export default HomeCondition;