import "./Tutorial.css";
import "./VideoSlider.css";
import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import { Carousel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import { useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import SublyApi from "../../helpers/Api";
import Nav from "../../Components/Header/header";
import Footer from "../Footer/Footer";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Getlanguage } from "../../commonfunction";
import { deleteToken } from "../../actions/currentUser";
import { logout } from "../../actions/currentUser";
import { deleteDefaultList } from "../../actions/defaultList";
import { clearErr } from "../../actions/errors";
import { deleteExcerciseList } from "../../actions/getExcercise";
import { deleteExperience } from "../../actions/getexperiense";
import { deleteGender } from "../../actions/getgender";
import { deleteLanguage } from "../../actions/getlanguage";
import { deleteAge } from "../../actions/getsurvey";
import { deletTimeNarration } from "../../actions/timeNarrationList";
import { removeTempData } from "../../actions/tempData";
import { deleteNarrationTheme } from "../../actions/narrationTheme";
import { deleteDefault } from "../../actions/homeSession";
import { deleteGoalTimeList } from "../../actions/goalTimeList";
import { deleteNewDefaultList } from "../../actions/defaultList";


const NewTutorial = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  //language localization work
  useEffect(() => {
    async function setlanguage() {
      const geti18value = await Getlanguage(parseInt(currentUser.default_language ? currentUser.default_language : 1));
      i18n.changeLanguage(geti18value);
    }
    setlanguage();
  }, []);

  const [defaultList, setdefaultList] = useState("");
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector((st) => st.currentUser);
  const signOut = () => {
    dispatch(logout());
    dispatch(deleteToken());
    dispatch(deleteDefaultList());
    dispatch(clearErr());
    dispatch(deleteExcerciseList());
    dispatch(deleteExperience());
    dispatch(deleteGender());
    dispatch(deleteLanguage());
    dispatch(deleteAge());
    dispatch(deletTimeNarration());
    dispatch(removeTempData());
    dispatch(deleteNarrationTheme());
    dispatch(deleteDefault());
    dispatch(deleteGoalTimeList());
    dispatch(deleteNewDefaultList());
    dispatch(deleteDefaultList());
    history.push("/login");
  };
  const token = useSelector((st) => st.token);
  //it's api work show default video show.
  useEffect(() => {
    async function gettutorial() {
      setLoading(true);
      const infoItem = await SublyApi.settingTutorial(token, currentUser, currentUser.device_id, currentUser.device_timezone);
      if (
        infoItem &&
        infoItem.data.error_type === "USER_NOT_FOUND" &&
        infoItem.data.status_code === 400
      ) {
        // Toast.fire({
        //   icon: "error",
        //   title: historyData.data.message,
        // });
        signOut();
      }
      else {
        setLoading(false);
        setdefaultList(infoItem.data);
      }
    }
    gettutorial();
  }, []);



  return (
    <section>
      <Nav></Nav>
      <div className="new_turo_wrapper">
        <div className="new_tur_innerbody">
          <div className="new_tut_title">
            <h3>{t("TUTORIAL_TITLE")}</h3>
          </div>

          <Carousel itemsToShow={1} showArrows={false} className="carousel_tutorial" keyboard={false}>
            {defaultList
              ? defaultList.tutorial_video_list.map((item, index) => {
                return (
                  <Carousel.Item style={{ margiTop: "51px" }} data-keyboard="false" key={index}>

                    <ReactPlayer
                      playsinline
                      config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                      url={item.video_url}
                      width="100%"
                      height="259px"
                      playing={true}
                      muted={false}
                      controls={true}
                    />

                  </Carousel.Item>
                );
              })
              : ""}
          </Carousel>

          <NavLink exact to="/home">
            <div className="skip_Tuto_button">
              {t("SKIPINTRO_T")}
            </div>
          </NavLink>
        </div>
      </div>
      <Footer />

    </section >
  );
};
export default NewTutorial;
