import { FETCH_NEW_DEFAULT_LIST, REMOVE_NEW_DEFAULT_LIST } from '../actions/types';

export default function rootReducer(state = {}, action) {
	switch (action.type) {

		case FETCH_NEW_DEFAULT_LIST:
			return { ...state, ...action.newDefaultData };
		case REMOVE_NEW_DEFAULT_LIST:
			return {};
		default:
			return state;
	}
}
