const calibrarionVideoSpeeds = [
  {
    id: 1,
    videoUrl:
      "https://breathconductor-com.s3.us-east-2.amazonaws.com/uploads/calibration/calibration_4.5.mp4",
    title: "4.5",
  },

  {
    id: 2,
    videoUrl:
      "https://breathconductor-com.s3.us-east-2.amazonaws.com/uploads/calibration/calibration_4.8.mp4",
    title: "4.8",
  },
  {
    id: 3,
    videoUrl:
      "https://breathconductor-com.s3.us-east-2.amazonaws.com/uploads/calibration/calibration_5.0.mp4",
    title: "5",
  },
  {
    id: 4,
    videoUrl:
      "https://breathconductor-com.s3.us-east-2.amazonaws.com/uploads/calibration/calibration_5.5+.mp4",
    title: "5.5",
  },
  {
    id: 5,
    videoUrl:
      "https://breathconductor-com.s3.us-east-2.amazonaws.com/uploads/calibration/calibration_6.0.mp4",
    title: "6",
  },
];

export default calibrarionVideoSpeeds;
