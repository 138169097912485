import { NARRATION_THEME_LIST,REMOVE_NARRATION_THEME_LIST } from '../actions/types';
export default function rootReducer(state = {}, action) {
 	switch (action.type) {
		
		case NARRATION_THEME_LIST:
			return {...state,...action.defaultData};
		case REMOVE_NARRATION_THEME_LIST:
			return {};
	    
		default:
			return state;
	}
}
 