import { NavLink } from "react-router-dom";
import "./Question.css";
import SignupHeader from "./SignupHeader";
import SublyApi from "../../../helpers/Api";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGenderValue } from "../../../actions/getgender";
import { Loader } from "../../../commonFile";
import { useTranslation } from "react-i18next";
import { Getlanguage } from "../../../commonfunction";
import { useHistory } from "react-router-dom";
const GenderQuestion = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const language = useSelector((st) => st.getlanguage);
  const changelanguage = Number(language.defaultData);
  useEffect(() => {

    async function setlanguage() {
      const geti18value = await Getlanguage(parseInt(currentUser.default_language ? currentUser.default_language : changelanguage));
      i18n.changeLanguage(geti18value);
    }
    setlanguage();
  }, []);

  const dispatch = useDispatch();
  //show api error & response
  const gender = useSelector((st) => st.getgender);
  const token = useSelector((st) => st.token);
  const currentUser = useSelector((st) => st.currentUser);
  const [genders, setgenders] = useState();
  // get option by api 
  useEffect(() => {
    async function getsurvey() {
      const value = await SublyApi.getsurvey();
      setgenders(value ? value.data.gender_list : "")
    }
    getsurvey();
  }, []);
  // get and set value in redux
  function getgenvalu(e) {
    const gendervalue = e.currentTarget.id;
    dispatch(getGenderValue(gendervalue));
    history.push("/language-question")
  }
  const myComponentStyle = {
    fontSize:"14px"
 }
  return (
    <>
      <section>
        <SignupHeader></SignupHeader>
        <div
          className="GenderQuestionbody"
          style={{ marginBottom: "20px", position: "sticky" }}
        >
          <div className="GenderQuestioninnerbody">
            <div className="GenderQuestiontitlbody">
              <div className="titlegender">
                <p className="numbering">2/4</p>
                <h2 >
                  {t("GENDERTITLE")}
                </h2>
              </div>
            </div>
            {genders ?
              <form className="Featurgoalsubtitlesgender">
                <div style={{ paddingTop: "10px" }}>
                  {genders ? genders.map((item, index) => (
                    <label className="genderlabel" key={index}>
                      <input
                        type="radio"
                        name="gemder"
                        id={item.id}
                        className="inputfieldgender"
                        defaultChecked={!(currentUser.userID) ?
                          gender.defaultData ? gender.defaultData == item.id : "" :
                          !(currentUser.gender_id)
                            ? ""
                            : currentUser.gender_id == item.id}
                        onClick={getgenvalu}
                      ></input>
                      <span className="geekmarks">
                        <span className="Questioninputtext">{item.gender_name}</span>
                      </span>
                    </label>
                  ))
                    : ""}
                </div>

                <div className="GenderQuestionbutton">
                  <NavLink exact to="age-question">
                    <div className="GenderQuestionPreviousbutton">
                      <button style={myComponentStyle}> {t("PREVIOUSBUTTON")}</button>
                    </div>
                  </NavLink>
                  {/* <NavLink exact to="language-question">
                    <div className="GenderQuestionNextbutton">
                      <button type="button" >{t("NEXTBUTTON")}</button>
                    </div>
                  </NavLink> */}
                  <NavLink exact to={`${!currentUser.userID ? "/signup" : currentUser.userID && currentUser.is_tutorial == 0 ? "/home" : "/new-tutorial"}`}>
                    <div className="Skipsurveybutton">
                      <button style={myComponentStyle}>{t("SKIPBUTTON")}</button>
                    </div>
                  </NavLink>
                </div>
              </form>
              : <Loader />}
          </div>
        </div>
      </section>
    </>
  );
};
export default GenderQuestion;
