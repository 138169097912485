import React from "react";
import './PolicyFooter.css';

function PolicyFooter() {
    return (
        <section className="FooterContainer">
            <div className="FooterArea">
                <p>© 2020-2021
                    <a href="">Breathconductor.com</a>.
                    All rights reserved.
                </p>
            </div>
        </section>
    )
}

export default PolicyFooter;