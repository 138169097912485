import { STORE_TEMP_DATA, REMOVE_TEMP_DATA } from '../actions/types';
export default function rootReducer(state = {}, action) {
    switch (action.type) {

        case STORE_TEMP_DATA:
            return { ...state, ...action.tempData };
        case REMOVE_TEMP_DATA:
            return {};

        default:
            return state;
    }
}