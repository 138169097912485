import React from "react";
import {  useHistory } from "react-router-dom";
import headerbrithlogo from "../../../Assets/Images/Logo1.svg";
import { useSelector } from "react-redux";
function SignupHeader() {
  const currentUser = useSelector((st) => st.currentUser);
  const history =useHistory();
  
  return (
    <section className="fullsection" style={{ zIndex: "999", position: "sticky" }}>
      <div className="headercontainer ">
        <div className="headernewimage">
          <a className="logo" style={{ width: "100%" }} >
            <img style={{width: "294px"}} src={headerbrithlogo} alt="breathhealogo"  onClick={()=>{ if(!currentUser.userID) { history.push("/")}}}/>
          </a>
        </div>
      </div>
    </section>
  );
}

export default SignupHeader;
