import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./Support.css";
import Nav from "../../../Components/Header/header";
import Footer from "../../Footer/Footer";
import SublyApi from "../../../helpers/Api";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Icon } from "@iconify/react";
import { SmallLoader, Toast } from "../../../commonFile";
import { Getlanguage } from "../../../commonfunction";
import { useTranslation } from "react-i18next";
import { deleteToken } from "../../../actions/currentUser";
import { logout } from "../../../actions/currentUser";
import { deleteDefaultList } from "../../../actions/defaultList";
import { clearErr } from "../../../actions/errors";
import { deleteExcerciseList } from "../../../actions/getExcercise";
import { deleteExperience } from "../../../actions/getexperiense";
import { deleteGender } from "../../../actions/getgender";
import { deleteLanguage } from "../../../actions/getlanguage";
import { deleteAge } from "../../../actions/getsurvey";
import { deletTimeNarration } from "../../../actions/timeNarrationList";
import { removeTempData } from "../../../actions/tempData";
import { deleteNarrationTheme } from "../../../actions/narrationTheme";
import { deleteDefault } from "../../../actions/homeSession";
import { deleteGoalTimeList } from "../../../actions/goalTimeList";
import { useDispatch } from "react-redux";
import { deleteNewDefaultList } from "../../../actions/defaultList";

function Support() {
  const [load, setLoad] = useState(false)
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const currentUser = useSelector((st) => st.currentUser);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const token = useSelector((st) => st.token);
  const signOut = () => {
    dispatch(logout());
    dispatch(deleteToken());
    dispatch(deleteDefaultList());
    dispatch(clearErr());
    dispatch(deleteExcerciseList());
    dispatch(deleteExperience());
    dispatch(deleteGender());
    dispatch(deleteLanguage());
    dispatch(deleteAge());
    dispatch(deletTimeNarration());
    dispatch(removeTempData());
    dispatch(deleteNarrationTheme());
    dispatch(deleteDefault());
    dispatch(deleteGoalTimeList());
    dispatch(deleteNewDefaultList());
    dispatch(deleteDefaultList());
    history.push("/login");
  };
  //language localization work
  useEffect(() => {
    async function setlanguage() {
      const geti18value = await Getlanguage(parseInt(currentUser.default_language ? currentUser.default_language : 1));
      i18n.changeLanguage(geti18value);
    }
    setlanguage();
  }, []);
 

  // mail send api work
  const OnSubmit = async (supportData) => {
    setLoad(true)
    // let requestData = new FormData();
    // requestData.append("cc", `${supportData && supportData.cc}`);
    // requestData.append("subject", `${supportData && supportData.subject}`);
    // requestData.append("Message", `${ supportData && supportData.Message}`);
    await SublyApi.SupportApi(supportData, token, currentUser.device_id, currentUser.device_timezone)
      .then((responsejson) => {
        if (responsejson &&
          responsejson.data.error_type == "USER_NOT_FOUND" &&
          responsejson.data.status_code === 400) {
          // Toast.fire({
          //   icon: "error",
          //   title: responsejson.data.message,
          // });
          signOut();
        }
        else {
          if (responsejson.data.status_code === 400) {
            Toast.fire({
              icon: "error",
              title: responsejson.data.message,
            });
            setLoad(false)
          } else {
            Toast.fire({
              icon: "success",
              title: responsejson.message,
            });
            setLoad(false)
            history.push("/Setting");
          }
        }
      })

      .catch((error) => { });
  };

  return (
    <>
      <section className="fullcontainerSupport">
        <Nav></Nav>

        <div className="ouutercontainerSupport">
          <div className="innercontainerSupport">
            <form autoComplete="off" onSubmit={handleSubmit(OnSubmit)}>
              <div className="terminnercontainertext">
                <div className="support_Header">
                  <span className="inviteBack_Icon">
                    <Icon
                      icon="eva:arrow-ios-back-fill"
                      color="white"
                      width="24"
                      cursor="pointer"
                      height="24"
                      onClick={() => {
                        history.goBack();
                      }}
                    />
                  </span>
                  <h4 className="SupportText">{t("Support")}</h4>
                </div>
                <h2>{t("TO")}</h2>
                <input
                  id="to"
                  name="to"
                  readOnly
                  value="contact@breathconductor.com"
                />

                <h2>{t("CC")}</h2>
                <input
                  id="cc"
                  name="cc"
                  placeholder="Cc/Bcc Email"
                  {...register("cc", {
                    required: t("emailreq"),
                    pattern: {
                      value:
                        /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/,
                      message: t("pentvalemail"),
                    },
                  })}
                />
                {errors.cc && (
                  <span className="errorMessageColor">{errors.cc.message}</span>
                )}
                <h2>{t("SUBJECT")}</h2>
                <input
                  id="subject"
                  name="subject"
                  placeholder={t("entersubject")}
                  {...register("subject", {
                    required: t("subjereq"),
                  })}
                />
                {errors.subject && (
                  <span className="errorMessageColor">
                    {errors.subject.message}
                  </span>
                )}
                <h2>{t("MESSAGE")}</h2>
                <textarea
                  id="Message"
                  name="Message"
                  placeholder={t("message")}
                  {...register("Message", {
                    required: t("messagerequire"),
                  })}
                />
                {errors.Message && (
                  <p className="errorMessageColor errorSpace">{errors.Message.message}</p>
                )}
                {load ? <SmallLoader /> : ""}
                <div className="spport_Submit_Btn">
                  <button className="SubButton">{t("SUBMITBUTTON")}</button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <Footer />

      </section>
    </>
  );
}

export default Support;
