import { initializeApp } from 'firebase/app';
import {  getToken, onMessage } from "firebase/messaging";
import { getMessaging } from "firebase/messaging/sw";
import 'array.prototype.fill'
var firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);
// const messaging = "adas"


export const getTokenArr = (setTokenFound) => {
    if ("serviceWorker" in navigator) {
        navigator.serviceWorker
            .register("./firebase-messaging-sw.js")
            .then(function (registration) {
                return getToken(messaging, {
                    vapidKey: process.env.REACT_APP_FIREBASE_SERVER_KEY
                }).then((currentToken) => {
                    if (currentToken) {
                        setTokenFound(currentToken);
                        // Track the token -> client mapping, by sending to backend server
                        // show on the UI that permission is secured
                    } else {
                        setTokenFound(false);
                        // shows on the UI that permission is required 
                    }
                }).catch((err) => {
                    // catch error while creating client token
                });
            })
            .catch(function (err) {
            });
    }
}


export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });