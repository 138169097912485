import { NavLink } from "react-router-dom";
import "./Question.css";
import SignupHeader from "./SignupHeader";
import SublyApi from "../../../helpers/Api";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLanguageValue } from "../../../actions/getlanguage";
import { Loader } from "../../../commonFile";
import { useTranslation } from "react-i18next";
import { Getlanguage } from "../../../commonfunction";
import { useHistory } from "react-router-dom";
const LanguageQuestion = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();


  const dispatch = useDispatch();
  //show api error & response
  // get value from redux
  const language = useSelector((st) => st.getlanguage);
  const changelanguage = Number(language.defaultData);
  useEffect(() => {
    async function setlanguage() {
      const geti18value = await Getlanguage(parseInt(currentUser.default_language ? currentUser.default_language : changelanguage));
      i18n.changeLanguage(geti18value);
    }
    setlanguage();
  }, []);
  const token = useSelector((st) => st.token);
  const currentUser = useSelector((st) => st.currentUser);
  const [languages, setlanguages] = useState("");
  // get option by api 
  useEffect(() => {
    async function getsurvey() {
      const value = await SublyApi.getsurvey();
      setlanguages(value ? value.data.language_list : "")
    }
    getsurvey();
  }, []);
  // get and set value in redux
  function getlanvalu(e) {
    const languagevalue = e.currentTarget.id;
    dispatch(getLanguageValue(languagevalue));
    history.push("/experience-question")
  }

  const myComponentStyle = {
    fontSize:"14px"
 }

  return (
    <>
      <section>
        <SignupHeader></SignupHeader>

        <div
          className="LanguageQuestionbody"
          style={{ marginBottom: "20px", position: "sticky" }}
        >
          <div className="LanguageQuestioninnerbody">
            <div className="LanguageQuestiontitlbody">
              <div className="titlelangauage">
                <p className="numbering">3/4</p>
                <h2 style={{ marginTop: "-14px" }}>
                  {t("LANGUAGETITLE")}
                </h2>
              </div>
            </div>
            {languages ?
              <form className="Featurgoalsubtitleslanguage">
                <div style={{ paddingTop: "7px" }}>
                  {languages ? languages.map((item, index) => (
                    <label className="languagelevel" key={index}>
                      <input
                        type="radio"
                        name="language"
                        id={item.id}
                        className="inputfieldlanguage"
                        defaultChecked={!(currentUser.userID) ?
                          language.defaultData ?
                            language.defaultData == item.id : "" :
                          !(currentUser.default_language) || (currentUser.default_language == "0") ? ""
                            : currentUser.default_language == item.id}
                        onClick={getlanvalu}
                      ></input>
                      <span className="geekmarks">
                        <span className="Questioninputtext">{item.value}</span>
                      </span>
                    </label>
                  ))
                    : ""}
                </div>

                <div className="LanguageQuestionbutton">
                  <NavLink exact to="gender-question">
                    <div className="LanguageQuestionPreviousbutton">
                      <button style={myComponentStyle}>{t("PREVIOUSBUTTON")}</button>
                    </div>
                  </NavLink>
                  {/* <NavLink exact to="experience-question">
                    <div className="LanguageQuestionNextbutton">
                      <button type="button" >{t("NEXTBUTTON")}</button>
                    </div>
                  </NavLink> */}
                  <NavLink exact to={`${!currentUser.userID ? "/signup" : currentUser.userID && currentUser.is_tutorilal == 0 ? "/home" : "/new-tutorial"}`}>
                    <div className="Skipsurveybutton">
                      <button style={myComponentStyle}>{t("SKIPBUTTON")}</button>
                    </div>
                  </NavLink>
                </div>
              </form>
              : <Loader />}
          </div>
        </div>
      </section>
    </>
  );
};
export default LanguageQuestion;
