import { showErr, clearErr } from "./errors";
import {
    GENDER_LIST, REMOVE_GENDER_LIST
} from "./types";


// save gender survey value
export function getGenderValue(gendervalue) {
    return async function (dispatch) {
        try {
            dispatch(clearErr());
            dispatch(getGender(gendervalue));
        } catch (err) {
            dispatch(showErr(err));
        }
    };
};







function getGender(defaultData) {
    return {
        type: GENDER_LIST,
        defaultData,
    };
}




export function deleteGender(defaultData) {
    return {
        type: REMOVE_GENDER_LIST,
        defaultData,
    };
}