import { responsivePropType } from "react-bootstrap/esm/createUtilityClasses";
import SublyApi from "../helpers/Api";

import { showErr, clearErr } from "./errors";

import {
  FETCH_CURRENT,
  FETCH_TOKEN,
  REMOVE_TOKEN,
  REMOVE_CURRENT,
} from "./types";

export const incNumber = () => {
  return {
    type: "INCREMENT",
  };
};

export const decNumber = () => {
  return {
    type: "DECREMENT",
  };
};
export function userLogin(user) {
  return async function (dispatch) {
    try {
      dispatch(clearErr());
      dispatch(getUser(user.data.user_details));
      dispatch(getTokens(user.data.user_details.auth_token));
    } catch (err) {
      dispatch(showErr(err));
    }
  };
}

export function userSignUp(user) {
  return async function (dispatch) {
    try {
      dispatch(clearErr());

      delete user.data.user_details.password;
      // dispatch(getUser(user.data.user_details));
      // dispatch(getTokens(user.data.user_details.auth_token));
    } catch (err) {
      dispatch(showErr(err));
    }
  };
}

export function checkSocialLogin(user) {
  return async function (dispatch) {
    try {
      dispatch(clearErr());
      delete user.data.user_details.password;
      dispatch(getUser(user.data.user_details));
      dispatch(getTokens(user.data.user_details.auth_token));
    } catch (err) {
      dispatch(showErr(err));
    }
  };
}

export function SocialLogin(user) {
  return async function (dispatch) {
    try {
      dispatch(clearErr());
      delete user.data.user_details.password;
      dispatch(getUser(user.data.user_details));
      dispatch(getTokens(user.data.user_details.auth_token));
    } catch (err) {
      dispatch(showErr(err));
    }
  };
}


export function getUser(user) {
  return {
    type: FETCH_CURRENT,
    user,
  };
}
export function getTokens(token) {
  return {
    type: FETCH_TOKEN,
    token,
  };
}
export function deleteToken(token) {
  return {
    type: REMOVE_TOKEN,
    token,
  };
}

export function logout() {
  return {
    type: REMOVE_CURRENT,
  };
}
