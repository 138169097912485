import { NavLink, useHistory } from "react-router-dom";
import "./Question.css";
import SignupHeader from "./SignupHeader";
import SublyApi from "../../../helpers/Api";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getExperienceValue } from "../../../actions/getexperiense";
import { Toast } from "../../../commonFile";
import { Loader } from "../../../commonFile";
import { logout } from "../../../actions/currentUser";
import { deleteToken } from "../../../actions/currentUser";
import { getUser } from "../../../actions/currentUser";
import { useTranslation } from "react-i18next";
import { Getlanguage } from "../../../commonfunction";
import { getinternet } from "../../../commonfunction";
import { deleteDefaultList } from "../../../actions/defaultList";
import { clearErr } from "../../../actions/errors";
import { deleteExcerciseList } from "../../../actions/getExcercise";
import { deleteExperience } from "../../../actions/getexperiense";
import { deleteGender } from "../../../actions/getgender";
import { deleteLanguage } from "../../../actions/getlanguage";
import { deleteAge } from "../../../actions/getsurvey";
import { deletTimeNarration } from "../../../actions/timeNarrationList";
import { removeTempData } from "../../../actions/tempData";
import { deleteNarrationTheme } from "../../../actions/narrationTheme";
import { deleteDefault } from "../../../actions/homeSession";
import { deleteGoalTimeList } from "../../../actions/goalTimeList";
import { deleteNewDefaultList } from "../../../actions/defaultList";

const ExperienceQuestion = () => {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    async function setlanguage() {
      const geti18value = await Getlanguage(parseInt(currentUser.default_language ? currentUser.default_language : changelanguage));
      i18n.changeLanguage(geti18value);
    }
    setlanguage();
  }, []);

  const history = useHistory();
  //show api error & response
  const dispatch = useDispatch();
  const [experiences, setexperiences] = useState();
  // get value from redux
  // medtitaion value get by redux
  const experience = useSelector((st) => st.getexperiense);
  const language = useSelector((st) => st.getlanguage);
  const gender = useSelector((st) => st.getgender);
  const age = useSelector((st) => st.getsurvey);
  const token = useSelector((st) => st.token);
  const currentUser = useSelector((st) => st.currentUser);
  // get survey convert to number because api get value in number
  const chanexperience = Number(experience.defaultData);
  const changeage = Number(age.defaultData);
  const changegender = Number(gender.defaultData)
  const changelanguage = Number(language.defaultData);

  const [isCalibrated, setCalibrated] = useState();

  useEffect(() => {
    async function getSpeed() {
      const calibration = await SublyApi.GetUserCalibration(token, currentUser.userID);
      setCalibrated(calibration.data.calibration);
    }
    getSpeed();
  }, []);

  // get option by api 
  useEffect(() => {

    async function getsurvey() {
      const value = await SublyApi.getsurvey();
      setexperiences(value ? value.data.experience_list : "")
    }
    getsurvey();
  }, []);

  const signOut = () => {
    dispatch(logout());
    dispatch(deleteToken());
    dispatch(deleteDefaultList());
    dispatch(clearErr());
    dispatch(deleteExcerciseList());
    dispatch(deleteExperience());
    dispatch(deleteGender());
    dispatch(deleteLanguage());
    dispatch(deleteAge());
    dispatch(deletTimeNarration());
    dispatch(removeTempData());
    dispatch(deleteNarrationTheme());
    dispatch(deleteDefault());
    dispatch(deleteGoalTimeList());
    dispatch(deleteNewDefaultList());
    dispatch(deleteDefaultList());
    history.push("/login");
  };
  // get and set value in redux
  function getexpvalu(e) {
    const experiencevalue = e.currentTarget.id;
    setexperiencevalue(experiencevalue);
    dispatch(getExperienceValue(experiencevalue));
    seterror("")
  }
  // set error function if user not select any meditation experience
  const [error, seterror] = useState("");


  function getexper() {
    if (!experience.defaultData) {
      seterror(t("medmandator"))
    }
    else {
      Toast.fire({
        icon: "success",
        title: t("surupdmsg"),
      })
      seterror("")
      history.push("/signup")
    }
  }

  //get value
  const [getexpv, setexperiencevalue] = useState("");
  //api for set all survey save 
  const onSubmit = async () => {
    const online = await getinternet();

    if (online === false) {
      Toast.fire({
        icon: "error",
        title: t("noiternet"),
      });
    }
    else {
      let requestData = new FormData();
      requestData.append("meditation", chanexperience ? chanexperience : currentUser.meditation ? currentUser.meditation : "");
      requestData.append("default_language", changelanguage ? changelanguage : currentUser.default_language ? currentUser.default_language : "");
      requestData.append("age_group", changeage ? changeage : currentUser.age_group ? currentUser.age_group : "");
      requestData.append("gender_id", changegender ? changegender : currentUser.gender_id ? currentUser.gender_id : "");
      requestData.append("firebase_token", currentUser.device_id);
      await SublyApi.getfullsurvey(requestData, token, currentUser.device_id, currentUser.device_timezone)
        .then((responsejson) => {

          if (
            responsejson &&
            responsejson.data.error_type == "USER_NOT_FOUND" &&
            responsejson.data.status_code === 400
          ) {
            // Toast.fire({
            //   icon: "error",
            //   title: responsejson.data.message,
            // });
            signOut();
          }
          else {
            Toast.fire({
              icon: "success",
              title: t("surupdmsg"),
            })
            dispatch(getUser({
              is_survey_complete: responsejson.data.survey_data.is_survey_complete ? responsejson.data.survey_data.is_survey_complete : "",
              default_language: responsejson.data.survey_data.default_language ? responsejson.data.survey_data.default_language : "",
              age_group: responsejson.data.survey_data.age_group ? responsejson.data.survey_data.age_group : "",
              meditation: responsejson.data.survey_data.meditation ? responsejson.data.survey_data.meditation : "",
              gender_id: responsejson.data.survey_data.gender_id ? responsejson.data.survey_data.gender_id : "",
            }))
            if (isCalibrated == 0) {
              history.push("/calibration")
            }
            else if (currentUser.is_tutorial == 0) {
              history.push("/home")
            }
            else {
              history.push("/new-tutorial")
            }
          }
        })
        .catch((error) => {
        });
    }
  }
  const myComponentStyle = {
    fontSize: "14px"
  }
  return (
    <>
      <section>
        <SignupHeader></SignupHeader>

        <div
          className="ExperienceQuestionbody"
          style={{ marginBottom: "20px", position: "sticky" }}
        >
          <div className="ExperienceQuestioninnerbody">
            <div className="ExperienceQuestiontitlbody">
              <div className="titleexperience">
                <p className="numbering">4/4</p>
                <h2 style={{ marginTop: "-10px" }}>
                  {t("EXPERIENCETITLE")}
                </h2>
              </div>
            </div>
            {experiences ?
              <form className="Featurgoalexperiense">
                <div style={{ paddingTop: "5px" }}>
                  {experiences ? experiences.map((item, index) => (
                    <label className="containerlevelexperiense" key={index}>
                      <input
                        type="radio"
                        id={item.id}
                        name="experiense"
                        className="inputfieldexperinse"
                        defaultChecked={!(currentUser.userID) ?
                          experience.defaultData ? experience.defaultData == item.id : "" :
                          !(currentUser.meditation) || (currentUser.meditation == "0")
                            ? "" :
                            currentUser.meditation == item.id}
                        onClick={getexpvalu}
                      ></input>
                      <span className="geekmarks">
                        <span className="Questioninputtext">{item.expName}</span>
                      </span>
                    </label>
                  ))
                    : <Loader />}
                </div>
                <p style={{ color: "red" }}>{error}</p>
                <div className="ExperienceQuestionbutton">
                  <NavLink exact to="language-question">
                    <div className="ageQuestionsubmitbutton">
                      <button style={myComponentStyle}> {t("PREVIOUSBUTTON")}</button>
                    </div>
                  </NavLink>

                  <div className="ageQuestionskipbutton">
                    <button type="button" style={myComponentStyle} onClick={() => { currentUser.userID ? onSubmit() : getexper() }}>{t("SUBMITBUTTON")}</button>
                  </div>

                </div>
              </form>
              : <Loader />}
          </div>
        </div>
      </section>
    </>
  );
};
export default ExperienceQuestion;
