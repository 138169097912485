import React from "react";
import twitter from "../Assets/Images/twitter.svg";
import linkedIn from "../Assets/Images/linkedin.svg";
import whatsapp from "../Assets/Images/whatsapp.svg";
import { ImCross } from "react-icons/im";
import ReactPlayer from "react-player";
import {
  WhatsappShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookShareButton,
} from "react-share";
import { Icon } from '@iconify/react';
import { t } from "i18next";
import { useState, useEffect } from "react";

const pairs = [
  { shareH: "shareH1", shareB: "shareB1" },
  { shareH: "shareH2", shareB: "shareB2" },
  { shareH: "shareH3", shareB: "shareB3" },
  { shareH: "shareH4", shareB: "shareB4" },
];

export function SharePopup(props) {

  const [randomPair, setRandomPair] = useState({});

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * pairs.length);
    setRandomPair(pairs[randomIndex]);
  }, []);

  return (
    <>
      <div className="shareWrappers">
        <div className=" shareBoxs">
          <div className="imagesharboxcross" style={{ marginBottom: "0px" }} >
            <button onClick={() => props.setShareBox(false)}>
              <ImCross />
            </button>
          </div>
          <div className="sharboxtitle" >
            <h3>{t("shareBc")}</h3>
          </div>
          <div className="playerwithtitle" >
            <div className="player">
              <ReactPlayer
                playsinline
                config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                url={props.trailer ? props.trailer : "" }
                controls
                width="100%"
                height="100%"
                muted={false}
                
              />
            </div>

            <div className="title">
            <h3>{t(randomPair.shareH)}</h3>
              <p>
                {t(randomPair.shareB)}
              </p>
            </div>
          </div>
          <div>
            <div className="shareIconss">
              <div className="shareFacebook">
                <FacebookShareButton
                  url={t(randomPair.shareB) + t("signUpClosing") + t("signUpLink")}
                >
                  <Icon icon="fa:facebook-square" className="facebookimage" color="#3b5a98" />

                  {/* <img src={facebook} alt="facebook" /> */}
                  <span>{t("Facebook")}</span>
                </FacebookShareButton>
              </div>
              <div className="shareTwitter">
                <TwitterShareButton
                  url={t(randomPair.shareB) + t("signUpClosing") + t("signUpLink")}
                >
                  <img src={twitter} alt="twitter" />
                  <span>{t("Twitter")}</span>
                </TwitterShareButton>
              </div>
              <div className="LinkedInIcon">
                <LinkedinShareButton
                  url={t(randomPair.shareB) + t("signUpClosing") + t("signUpLink")}
                >
                  <img src={linkedIn} alt="linkedIn" />
                  <span>{t("Linkedin")}</span>
                </LinkedinShareButton>
              </div>
              <div className="shareWhatsapp">
                <WhatsappShareButton
                  url={t(randomPair.shareB) + t("signUpClosing") + t("signUpLink")}
                >
                  <img src={whatsapp} alt="whatsapp" />
                  <span>{t("whatsapp")}</span>
                </WhatsappShareButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

// export default SharePopup;
