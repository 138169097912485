import React, { useEffect, useRef } from "react";
import { useState } from "react";
import "./Algorithm.css";
import playicon from "../../Assets/Images/playicon.svg";
import pauseicon from "../../Assets/Images/pauseicon.svg";
import keylogo from "../../Assets/Images/Key.svg";
import RelaxGoal from "../Auth/Signup/RelaxGoal";
import { Icon } from "@iconify/react";
import ReactPlayer from "react-player";
import SublyApi from "../../helpers/Api";
import { Loader } from "../../commonFile";
import { useSelector } from "react-redux";
import { logout } from "../../actions/currentUser";
import { deleteToken } from "../../actions/currentUser";
import { useDispatch } from "react-redux";
import { Toast } from "../../commonFile";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Getlanguage } from "../../commonfunction";
import { getinternet } from "../../commonfunction";
import { deleteDefaultList } from "../../actions/defaultList";
import { clearErr } from "../../actions/errors";
import { deleteExcerciseList } from "../../actions/getExcercise";
import { deleteExperience } from "../../actions/getexperiense";
import { deleteGender } from "../../actions/getgender";
import { deleteLanguage } from "../../actions/getlanguage";
import { deleteAge } from "../../actions/getsurvey";
import { deletTimeNarration } from "../../actions/timeNarrationList";
import { removeTempData } from "../../actions/tempData";
import { deleteNarrationTheme } from "../../actions/narrationTheme";
import { deleteDefault } from "../../actions/homeSession";
import { deleteGoalTimeList } from "../../actions/goalTimeList";
import { deleteNewDefaultList } from "../../actions/defaultList";
import headerbrithlogo from "../../Assets/Images/Logo1.svg";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

let planToPurchase = "";
let amount = 0;
export function Alogoritm() {
  const [Relax, setRelax] = useState("");
  const [open, setopen] = useState(false);
  const [icons, seticons] = useState(false);
  const [valueid, setvalue] = useState([]);
  const [datapack, setdatapack] = useState([]);
  const [feature, setfeature] = useState();
  const [getcheckrest] = useState(true);
  const [getcheckrest2, setgetcheckrest2] = useState();
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const currentUser = useSelector((st) => st.currentUser);
  const token = useSelector((st) => st.token);
  const { t, i18n } = useTranslation();
  const [newpopup, setnewpopup] = useState(false);
  const [accessCode, setAccessCode] = useState("");
  useEffect(() => {
    async function setlanguage() {
      const geti18value = await Getlanguage(
        parseInt(
          currentUser.default_language ? currentUser.default_language : 1
        )
      );
      i18n.changeLanguage(geti18value);
    }
    setlanguage();
  }, []);
  const signOut = () => {
    dispatch(logout());
    dispatch(deleteToken());
    dispatch(deleteDefaultList());
    dispatch(clearErr());
    dispatch(deleteExcerciseList());
    dispatch(deleteExperience());
    dispatch(deleteGender());
    dispatch(deleteLanguage());
    dispatch(deleteAge());
    dispatch(deletTimeNarration());
    dispatch(removeTempData());
    dispatch(deleteNarrationTheme());
    dispatch(deleteDefault());
    dispatch(deleteGoalTimeList());
    dispatch(deleteNewDefaultList());
    dispatch(deleteDefaultList());
    history.push("/login");
  };
  function redirecthome() {
    dispatch(logout());
    dispatch(deleteToken());
    dispatch(deleteDefaultList());
    dispatch(clearErr());
    dispatch(deleteExcerciseList());
    dispatch(deleteExperience());
    dispatch(deleteGender());
    dispatch(deleteLanguage());
    dispatch(deleteAge());
    dispatch(deletTimeNarration());
    dispatch(removeTempData());
    dispatch(deleteNarrationTheme());
    dispatch(deleteDefault());
    dispatch(deleteGoalTimeList());
    dispatch(deleteNewDefaultList());
    dispatch(deleteDefaultList());
    history.push("/");
  }

  //get plan data api work
  useEffect(() => {
    async function getplandata() {
      const datapacks = [...datapack];
      const getplan = await SublyApi.planSubscription(
        token ? token : currentUser.auth_token,
        currentUser.device_id,
        currentUser.device_timezone
      );
      const specialPlans = await SublyApi.WhitelistedUserPlans(
        token,
        currentUser.email
      );
      if (
        getplan &&
        getplan.data.error_type == "USER_NOT_FOUND" &&
        getplan.data.status_code === 400
      ) {
        // Toast.fire({
        //   icon: "error",
        //   title: getplan.data.message,
        // });
        signOut();
      } else {
        if (getplan && getplan.data && getplan.data.plan_data) {
          var specialPlanIDvalues = specialPlans.data.whitelisted_plans.map(
            (plan) => plan.planID
          );
          getplan.data.plan_data.forEach((element) => {
            if (
              (element.plan_type == 1 ||
                specialPlanIDvalues.includes(element.id)) &&
              element.state == 1
            ) {
              // specialPlan.data.whitelisted_plan.values('planID').includes(element.id)) {
              datapacks.push(element);
            }
          });
        }
        setdatapack(datapacks);
        setfeature(getplan ? getplan.data.feature_goals : "");
      }
    }
    getplandata();
  }, []);
  //it's state call for start video/icon
  function startvideo() {
    icons === false ? seticons(true) : seticons(false);
  }

  async function openpayment() {
    const online = await getinternet();
    if (online === false) {
      Toast.fire({
        icon: "error",
        title: t("noiternet"),
      });
    } else {
      if (open === false) {
        setopen(true);
      } else {
        setopen(false);
      }
    }
  }
  const getid = async (e) => {
    const online = await getinternet();
    if (online === false) {
      Toast.fire({
        icon: "error",
        title: t("noiternet"),
      });
    } else {
      let addvalue = [];
      var inputElements = document.getElementsByClassName("messageCheckbox");
      for (var i = 0; inputElements[i]; ++i) {
        if (inputElements[i].checked) {
          addvalue.push(inputElements[i].value);
          setvalue(addvalue);
        }
      }
    }
  };

  const handleSubmitAccessCode = () => {
    if (accessCode === "") {
      Toast.fire({
        icon: "error",
        title: "Please enter access code",
      });
    } else {
      async function getAccessCode() {
        var formdata = new FormData();
        formdata.append("access_code", accessCode);
        const response = await SublyApi.finishSignupWithAccessCode(
          formdata,
          token,
          currentUser.device_id,
          currentUser.device_timezone
        );
        if (response.status_code === 200) {
          window.location = "/algorithm";
        } else {
          Toast.fire({
            icon: "error",
            title: response.data.message,
          });
        }
      }
      getAccessCode();
    }
  };
  function planSelection(pack, e) {
    if (e.target.checked) {
      planToPurchase = pack;
    }
    if (planToPurchase == "") {
      document.getElementById("purchaseButton").disabled = true;
      document.getElementById("purchaseButton").style.filter =
        "brightness(50%)";
    } else {
      document.getElementById("purchaseButton").disabled = false;
      document.getElementById("purchaseButton").style.filter =
        "brightness(100%)";
    }
  }
  function getPlanSelectionTotal() {
    amount = 0;
    if (planToPurchase != "") {
      amount = planToPurchase.plan_price;
    }
    return amount;
  }
  function getPlanPrice() {
    var planName = "";
    if (planToPurchase != "") {
      planName = planToPurchase.plan_name;
    }
    return planName;
  }
  window.addEventListener(
    "load",
    function () {
      document.getElementById("purchaseButton").disabled = true;
    },
    false
  );
  return (
    <>
      <section>
        <section
          className="fullsection"
          style={{ zIndex: "999", position: "sticky" }}
        >
          <div className="headercontainer ">
            <div className="headernewimage">
              <img
                style={{ width: "294px" }}
                src={headerbrithlogo}
                alt="breathhealogo"
              />
            </div>
            <div id="logoutButton" className="redirecthome">
              <button
                type="button"
                onClick={() => {
                  redirecthome();
                }}
              >
                {t("LOGOUT")}
              </button>
            </div>
          </div>
        </section>
        <div className="Algorithminnerbody">
          <div
            className={`${
              icons || Relax || open ? "fixedAlgo" : "Algorithmformbody"
            }`}
          >
            <div className="accessCodeBody">
              <h2 className="topheadertitle">Do you have Access Code?</h2>
              <div>
                <div className="accessCodeInput">
                  <div class="forgotformimage">
                    <img
                      src={keylogo}
                      alt="keylogo"
                      width="23px"
                      height="23px"
                    />
                  </div>
                  <input
                    className="inputfieldauto"
                    autoFocus="autofocus"
                    autoComplete={false}
                    id="email"
                    name="email"
                    placeholder={t("enteraccesscode")}
                    onChange={(e) => setAccessCode(e.target.value)}
                  />
                </div>
              </div>
              <div>
                <button
                  className="btn btn-primary px-5"
                  type="button"
                  onClick={handleSubmitAccessCode}
                >
                  {t("redeemAccessCode")}
                </button>
              </div>
            </div>

            <h2 className="topheadertitle">{t("ALGORITHM_TITLE")}</h2>
            <div style={{ marginLeft: "30px", marginTop: "20px" }}>
              <span onClick={startvideo}>
                <span style={{ verticalAlign: "middle", cursor: "pointer" }}>
                  {icons === false ? (
                    <img
                      src={playicon}
                      width="40px"
                      height="40px"
                      alt="playicon"
                    />
                  ) : (
                    <img
                      src={pauseicon}
                      width="40px"
                      height="40px"
                      alt="pauseicon"
                    />
                  )}
                </span>
                <span className="videotraildertitle">{t("PRMOTIONAL_T")}</span>
              </span>
            </div>

            {feature && feature.length > 0 ? (
              <form className="myform">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    alignItems: "flex-start",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {datapack.map((pack) => (
                    <div
                      style={{ width: "45%", margin: "10px" }}
                      className={`inputupperbody2 ${
                        getcheckrest === true ? "activework" : "activework2"
                      }`}
                    >
                      <div className="firstpackagetitle">
                        <div>
                          <label className="labels">
                            <input
                              id="packagemain"
                              type="radio"
                              name="package"
                              className="inputfieldfirst messageCheckbox"
                              //checked
                              value={pack.id}
                              onChange={(e) => planSelection(pack, e)}
                            ></input>
                            <span
                              className="geekmark"
                              style={{ marginTop: "3px" }}
                            ></span>
                          </label>
                        </div>
                        <div className="h6title">
                          <h3>{pack.plan_name}</h3>
                          <p className="toptitlepahra">
                            <i> {t("BYMUBIK_T")}</i>
                          </p>
                        </div>
                        <div
                          className="packagevalue"
                          style={{ alignSelf: "flex-start" }}
                        >
                          <h2 className="packagevalues">${pack.plan_price}</h2>
                          <p
                            className="toptitlepahra"
                            style={{ marginLeft: "14px" }}
                          >
                            {pack.plan_interval}
                          </p>
                        </div>
                      </div>
                      <div className="Featured">
                        <div className="Featuredtitle">
                          <p style={{ marginBottom: "0px" }}>
                            {t("FEATUREGOAL_T")}:
                          </p>
                        </div>
                        <div className="firstpackagesubtitle">
                          {/* {feature ? feature.map((item, index) => ( */}
                          <div onClick={() => setRelax(feature[0])}>
                            <span>
                              {feature &&
                                feature.length > 0 &&
                                feature[0].title}
                            </span>
                            {/* <span style={{ marginLeft: "5px", marginRight: "5px" }}>{(feature.length - 1 > index) && "|"}</span> */}
                          </div>
                          {/* )) : ""} */}
                        </div>
                      </div>
                      <p
                        className="lasttitlefirstinput"
                        style={{ marginBottom: "3px" }}
                      >
                        {pack.plan_name !== "Basic Plan Monthly"
                          ? t("FREE7_T")
                          : null}
                      </p>
                    </div>
                  ))}
                </div>
                {/* <div>
                  <div className="divider_Boxs">
                    <hr className="divider" />
                  </div>

                  <div className="flexbodysecondinput">
                    {datapack && datapack.length > 1 &&
                      <div
                        className={`inputupperbody  ${getcheckrest2 ? "activework" : "activework2"
                          }`}
                      >
                        <div className="firstpackagetitle">
                          <div>
                            <label className="labels">
                              <input
                                id="packageadd"
                                type="checkbox"
                                name="package2"
                                className="inputfieldfirst messageCheckbox"
                                value={datapack[1].id}
                                onChange={getchecked}
                              ></input>
                              <span className="geekmark" style={{ marginTop: "3px" }}></span>
                            </label>
                          </div>
                          <div className="h6title">
                            <h3>{datapack[1].plan_name}</h3>
                            <p>
                              <i >{t("BYMUBIK_T")}</i>
                            </p>
                          </div>
                          <div
                            style={{ alignSelf: "flex-start" }}
                          >
                            <h2 className="packagevalues">${(datapack[1].plan_price)}</h2>
                            <p style={{ marginLeft: "14px" }}>{(datapack[1].plan_interval)}</p>
                          </div>
                        </div>
                        <p
                          className="lasttitlesecondinput"

                        >
                          {t("FEATUREGOAL_SUB_T")}
                        </p>
                      </div>
                    }
                    <div className="inputupperbody  responsivetim">
                      <div className="firstpackagetitle">
                        <div className="newplantitle" style={{ marginBottom: "26px" }}>
                          <h3>  {t("PRODUCTIVITY_T")}</h3>
                          <p>
                            <i style={{ color: "rgb(227, 241, 255)" }}>{t("BYMUBIK_T")}</i>
                          </p>
                        </div>
                        <div className="subtitletext">
                          <h6>
                            <i >
                              {t("COMINGSOON_T")}
                            </i>
                          </h6>
                        </div>
                      </div>
                      <p className="lasttitle3">
                        {t("FEATUREGOAL_T")}- <i> {t("COMINGSOON_T")}</i>
                      </p>
                    </div>
                  </div>
                  <div className="inputupperbody " style={{ marginTop: "20px" }}>
                    <div className="firstpackagetitle">
                      <div>
                        <h3>{t("COACH_T")}</h3>
                      </div>
                      <div className="subtitletext">
                        <h6>
                          <i style={{ color: "rgb(227, 241, 255)" }}>  {t("COMINGSOON_T")}</i>
                        </h6>
                      </div>
                    </div>
                    <p className="lasttitle" >
                      {t("ALGORITHAMLIBARY_T")}
                    </p>
                  </div>
                </div> */}
                {/* <NavLink exact to={currentUser.is_survey_complete === 0 ? "/age-question" : "/new-tutorial"}> */}
                <div className="Alogorithbutton Purchasebutton">
                  <button
                    id="purchaseButton"
                    type="button"
                    onClick={() => {
                      getid();
                      openpayment();
                      setnewpopup(true);
                    }}
                  >
                    {t("PURCHASE_T")}
                  </button>
                </div>
                {/* </NavLink> */}
              </form>
            ) : (
              <Loader />
            )}
          </div>
        </div>

        {Relax && <RelaxGoal setRelax={setRelax} Relax={Relax} />}
        <div
          className={
            newpopup == false ? " open_goal_about_none" : "open_goal_about"
          }
        >
          <div id="payment" className="payment_body">
            <div style={{ cursor: "pointer", float: "right" }}>
              <Icon
                onClick={() => setnewpopup(false)}
                icon="bytesize:close"
                color="black"
                width="20"
                height="20"
              />
            </div>
            <h1>{getPlanPrice()}</h1>
            <h2>
              Total due: ${getPlanSelectionTotal()} per{" "}
              {planToPurchase.plan_interval}
              {planToPurchase.plan_name !== "Basic Plan Monthly" &&
                " after 7 days"}
            </h2>
            <br></br>
            <PayPalScriptProvider
              options={{
                "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
                intent: "subscription",
                vault: true,
              }}
            >
              <PayPalButtons
                createSubscription={(data, actions) => {
                  return actions.subscription.create({
                    /* Creates the subscription */
                    plan_id: planToPurchase.plan_id,
                    custom_id: currentUser.userID + "," + planToPurchase.id,
                  });
                }}
                onApprove={(data, actions) => {
                  Toast.fire({
                    icon: "success",
                    title:
                      "Purchase succeeded! Please login after you receive a confirmation email from Muvik AI",
                    timer: 10000,
                  });
                  setnewpopup(false);
                  setTimeout(function () {
                    dispatch(logout());
                    history.push("/login");
                  }, 10000); // Gives paypal time to trigger the webhook
                }}
              />
            </PayPalScriptProvider>
          </div>
        </div>
      </section>
      <div
        className={icons === false ? "open_goal_about_none" : "open_goal_about"}
      >
        <div className="prmotion_video">
          <div className="prmotion_video_innerbody">
            <div className="Featuretitlbody ">
              <div className="video_title_body">
                <div>
                  <h4>{t("PRMOTIONALSUB_T")}</h4>
                </div>
                <div style={{ textAlign: "right" }} onClick={startvideo}>
                  <Icon
                    icon="bytesize:close"
                    color="white"
                    width="20"
                    height="20"
                    cursor={"pointer"}
                  />
                </div>
              </div>

              <div style={{ marginTop: "32px" }}>
                <ReactPlayer
                  playsinline
                  config={{
                    file: { attributes: { controlsList: "nodownload" } },
                  }}
                  url={
                    "https://dev-breathconductor-com.s3.us-east-2.amazonaws.com/uploads/Introduction/Breathconductor.0cc3a1bb.mp4"
                  }
                  controls
                  width="100%"
                  height="300px"
                  onEnded={icons == false}
                  autoplay
                  muted={false}
                  playing={icons == true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Alogoritm;
