import ReactPlayer from "react-player";
import { Icon } from '@iconify/react';
const RelaxGoal = (props) => {

  return (
    <>
      <section>
        <div className="backgrounone">
          <div className="Featurdgoalbody">
            <div className="Featureclaainnerbody" >
              <div className="Featuretitlbody">
                <div className="title">
                  <h2>{props ? props.Relax.title : ""}</h2>
                </div>
                <div
                  className="crossimage" style={{ alignSelf: "center" }}
                  onClick={() => props.setRelax(false)}
                >
                  <Icon icon="bytesize:close" color="white" width="20" height="20" />
                </div>
              </div>
              <div style={{ marginTop: "5px" }}>
                {props ?
                  <div>
                    <ReactPlayer
                      playsinline
                      config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                      url={props.Relax.video}
                      controls
                      width="100%"
                      height="210px"
                      autoplay
                      muted={false}
                      playing={true}
                    />
                  </div>
                  : ""}
                <div style={{ marginTop: "26px" }}>
                  <p
                    className="goalinformation"
                  >
                    {props.Relax.description}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default RelaxGoal;
