import Swal from "sweetalert2";
import loaders from "./Assets/Images/loader.gif";

// -----toaster popup-----

export const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

// ----loader function----

export const Loader = () => {
  return (
    <>
      <div className="historyDetailLoader">
        <div>
          <img src={loaders} alt="loader" />
        </div>
      </div>
    </>
  );
};

export const SmallLoader = () => {
  return (
    <>
      <div className="historyDetailLoader loadSet">
        <div>
          <img src={loaders} alt="loader" />
        </div>
      </div>
    </>
  );
};


export const BigLoader = () => {
  return (
    <>
      <div className="historyDetailLoader modifyLoader">
        <div>
          <img src={loaders} />
        </div>
      </div>
    </>
  );
};


// -----for page reload-----

