import { showErr, clearErr } from "./errors";
import {
    AGE_LIST, REMOVE_AGE_LIST
} from "./types";

// save age survey value

export function getAgeValue(getage) {
    return async function (dispatch) {
        try {
            dispatch(clearErr());
            dispatch(getAge(getage));
        } catch (err) {
            dispatch(showErr(err));
        }
    };
};







function getAge(defaultData) {
    return {
        type: AGE_LIST,
        defaultData,
    };
}




export function deleteAge(defaultData) {
    return {
        type: REMOVE_AGE_LIST,
        defaultData,
    };
}