import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import GoalIcon from "../../Assets/Images/Goal.svg";
import TimeIcon from "../../Assets/Images/Time.svg";
import VoiceIcon from "../../Assets/Images/Voice.svg";
import duplicate from "../../Assets/Images/DuplicateIcon.svg";
import Nav from "../Header/header";
import ReactPlayer from "react-player";
import { Icon } from "@iconify/react";
import deletesvg from "../../Assets/Images/delete.svg";
import { useSelector, useDispatch } from "react-redux";
import "./FavoriteDetail.css";
import { getExcercise } from "../../actions/getExcercise";
import { deleteExcerciseList } from "../../actions/getExcercise";
import SublyApi from "../../.../../helpers/Api";
import { confirm } from "react-confirm-box";
import { SmallLoader, Toast } from "../../commonFile";
import {
  deleteDefaultList,
  deleteNewDefaultList,
  setNewDefaultData,
} from "../../actions/defaultList";
import { SharePopup } from "../commonShare";
import { logout, deleteToken } from "../../actions/currentUser";
import { Getlanguage, Removeall, VideoLanguageChange } from "../../commonfunction";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { getinternet } from "../../commonfunction";
import { clearErr } from "../../actions/errors";
import { deleteExperience } from "../../actions/getexperiense";
import { deleteGender } from "../../actions/getgender";
import { deleteLanguage } from "../../actions/getlanguage";
import { deleteAge } from "../../actions/getsurvey";
import { deletTimeNarration } from "../../actions/timeNarrationList";
import { removeTempData } from "../../actions/tempData";
import { deleteNarrationTheme } from "../../actions/narrationTheme";
import { deleteDefault } from "../../actions/homeSession";
import { deleteGoalTimeList } from "../../actions/goalTimeList";

const BASE_URL = process.env.REACT_APP_API_URL_TEXT;
function NewFavouriteDetail() {
  const currentUser = useSelector((st) => st.currentUser);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    async function setlanguage() {
      const geti18value = await Getlanguage(parseInt(currentUser.default_language ? currentUser.default_language : 1));
      i18n.changeLanguage(geti18value);
    }
    setlanguage();
  }, []);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { Id } = useParams();
  const token = useSelector((st) => st.token);
  // it's use for open popup
  const [newpopup, setnewpopup] = useState(false);
  const [shareBox, setShare] = useState(false);
  const [favoriteDetail, setFavoriteDetailData] = useState("");
  const [buttondisable, setbuttondisable] = useState(true);
  const [resultfail, setresultfail] = useState(false)
  const [show, setshow] = useState();
  const options = {
    labels: {
      confirmable: "Yes",
      cancellable: "No"
    }
  }
  // make signout method
  const signOut = () => {
    dispatch(logout());
    dispatch(deleteToken());
    dispatch(deleteDefaultList());
    dispatch(clearErr());
    dispatch(deleteExcerciseList());
    dispatch(deleteExperience());
    dispatch(deleteGender());
    dispatch(deleteLanguage());
    dispatch(deleteAge());
    dispatch(deletTimeNarration());
    dispatch(removeTempData());
    dispatch(deleteNarrationTheme());
    dispatch(deleteDefault());
    dispatch(deleteGoalTimeList());
    dispatch(deleteNewDefaultList());
    dispatch(deleteDefaultList());
    dispatch(deleteDefaultList());
    history.push("/login");

  };
  useEffect(async () => {
    async function getFavoriteDetail() {
      setLoading(true);
      const favoritesData = await SublyApi.exerciseDetailFavorite(token, Id, currentUser.device_id, currentUser.device_timezone);
      if (
        favoritesData &&
        favoritesData.data.error_type === "USER_NOT_FOUND" &&
        favoritesData.data.status_code === 400
      ) {
        signOut();
      }
      else {
        if (favoritesData &&
          favoritesData.data.status_code === 400) {
          setLoading(false);
        }
        if (favoritesData &&
          favoritesData.status_code === 200) {
          setLoading(false);
          favoritesData &&
            setFavoriteDetailData({
              goal: favoritesData.data.favorite_exercise_detail[0].exercise.goal,
              durationMinutes:
                favoritesData.data.favorite_exercise_detail[0].exercise
                  .duration_minutes,
              narration:
                favoritesData.data.favorite_exercise_detail[0].exercise.narration,
              theme: favoritesData.data.favorite_exercise_detail[0].exercise.theme,
              title: favoritesData.data.favorite_exercise_detail[0].exercise.title,
              experience_meta_id:
                favoritesData.data.favorite_exercise_detail[0].exercise.exerciseID,
              goal_id:
                favoritesData.data.favorite_exercise_detail[0].exercise.goal_id,
              duration_minute_id:
                favoritesData.data.favorite_exercise_detail[0].exercise
                  .duration_minute_id,
              narration_id:
                favoritesData.data.favorite_exercise_detail[0].exercise
                  .narration_id,
              theme_id:
                favoritesData.data.favorite_exercise_detail[0].exercise.theme_id,
              trailer:
                favoritesData.data.favorite_exercise_detail[0].exercise
                  .trailer_video.trailer,
              feedback_data: favoritesData.data.feedback_data,
              goal_description:
                favoritesData.data.favorite_exercise_detail[0].goal
                  .goal_description,
              goal_video:
                favoritesData.data.favorite_exercise_detail[0].goal.goal_video,
              breathing_pace: favoritesData.data.favorite_exercise_detail[0].exercise.breathing_pace, 
              langaue: favoritesData.data.favorite_exercise_detail[0].exercise.language_id
        })
      }
    }
  }
    getFavoriteDetail();
  }, [resultfail]);

  //API for Remove Single Favorite Item
  const clearSingleFavorite = async () => {
    const online = await getinternet();
    if (online === false) {
      Toast.fire({
        icon: "error",
        title: t("noiternet"),
      });
    }
    else {
      setbuttondisable(false);
      const result = await confirm(t("remthifavour"));
      if (result) {
        SublyApi.removeSingleFavorite(token, Id, currentUser.device_id, currentUser.device_timezone).then((responsejson) => {
          if (responsejson &&
            responsejson.data.error_type == "USER_NOT_FOUND" &&
            responsejson.data.status_code === 400) {
            signOut();
          }
          else {
            if (responsejson.data.status_code === 400) {
              Toast.fire({
                icon: "error",
                title: `${responsejson.data.message}`,
              });
            }
            else {
              if (result && responsejson.status_code == 200) {
                Toast.fire({
                  icon: "success",
                  title: t("favouriteremove"),
                });
                history.push("/MyFavorites")
              }
            }
          }
        });

      }
    }
  };

  //replay the execise and create the new history for that
  const setReplayHistoryList = async () => {
    const online = await getinternet();
    if (online === false) {
      Toast.fire({
        icon: "error",
        title: t("noiternet"),
      });
    }
    else {
      setbuttondisable(false);
      const exerciseData = await SublyApi.exerciseDetail(token, Id, currentUser.device_id, currentUser.device_timezone);
      if (
        exerciseData &&
        exerciseData.data.error_type === "USER_NOT_FOUND" &&
        exerciseData.data.status_code === 400
      ) {
        signOut();
        setbuttondisable(true);
      }
      else {
        if (exerciseData &&
          exerciseData.data.status_code === 400) {

        }
        if (exerciseData &&
          exerciseData.status_code === 200) {

          dispatch(deleteExcerciseList());
          dispatch(
            getExcercise([{
              exercise_video: exerciseData.data.exercise.exercise_video,
              exerciseid: exerciseData.data.exercise.exerciseID,
              after_questions: exerciseData.data.exercise.after_questions,
              before_questions: exerciseData.data.exercise.before_questions,
              is_favorite: exerciseData.data.exercise.is_favorite,
              goal_rating: exerciseData.data.exercise.goal_rating,
              duration_minutes: exerciseData.data.exercise.duration_minutes,
              average_speed: exerciseData.data.exercise.average_speed,
              exerciseID: exerciseData.data.exercise.exerciseID,
              trailer_video: exerciseData.data.exercise.trailer_video,
              is_like: exerciseData.data.exercise.is_like,
            }])
          );
          setbuttondisable(true);
          history.push("/Home/before-rating-section");
        }
      }
    }
  };

  //update the new default list for and redirect to home
  const setNewDuplicateList = async () => {
    const online = await getinternet();
    if (online === false) {
      Toast.fire({
        icon: "error",
        title: t("noiternet"),
      });
    }
    else {
      await dispatch(
        setNewDefaultData([
          {
            default: {
              id: "1",
              goal_id: favoriteDetail.goal_id,
              age_group_id: "1",
              gender_id: "1",
              is_default: "1",
              experience_meta_id: favoriteDetail.experience_meta_id,
              duration_minute_id: favoriteDetail.duration_minute_id,
              narration_id: favoriteDetail.narration_id,
              theme_id: favoriteDetail.theme_id,
              minute: favoriteDetail.durationMinutes
            },
          },
        ])
      );
      history.push("/home/" + favoriteDetail.experience_meta_id);
    }
  };

  // ----single feedback delete----

  const deleteFeedBack = async (idValue) => {
    const online = await getinternet();
    if (online === false) {
      Toast.fire({
        icon: "error",
        title: t("noiternet"),
      });
    }
    else {
      var formdata = new FormData();
      formdata.append("feedbackid", idValue);
      setbuttondisable(false);
      setresultfail(false)
      await SublyApi.feedbackDelete(token, formdata, currentUser.device_id, currentUser.device_timezone)
        .then((responsejson) => {
          if (responsejson &&
            responsejson.data.error_type == "USER_NOT_FOUND" &&
            responsejson.data.status_code === 400) {
            signOut();
            setTimeout(() => {
              setbuttondisable(true)
            }, 3000);
          }
          else {
            if (responsejson && responsejson.data.status_code === 400) {
              Toast.fire({
                icon: "error",
                title: responsejson.data.message,
              });
              setTimeout(() => {
                setbuttondisable(true)
              }, 3000);

            }
            if (responsejson && responsejson.status_code === 200) {
              Toast.fire({
                icon: "success",
                title: responsejson.message,
              });
              setresultfail(true)
              setTimeout(() => {
                setbuttondisable(true)
              }, 3000);
            }
          }
        })
        .catch(() => { });
    }
  };

  async function videoLanguageChange() {
    if (currentUser.default_language == favoriteDetail.langaue) {
      setNewDuplicateList()
    }
    else {
      const result = await confirm(t("changelanugage"),options);
      if (result == true) {
        history.push(`/Setting/Language-setting/${1}`)
      }
      else {
        setNewDuplicateList()
      }
    }

  }

  return (
    <>
      <Nav></Nav>
      <div className="newhistorydetailuperbody">
        <div
          className={`${shareBox || newpopup ? "fixedHis" : "newhistorydetailinnerbody"
            }`}
        >
          {favoriteDetail ? (
            <>
              <div className="newHisDetTitle">
                <div className="historylogo">
                  <div className="backimagetitle">
                    <div
                      style={{ marginLeft: "0px" }}
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      <span>
                        <Icon
                          icon="ion:chevron-back-outline"
                          color="white"
                          width="20px"
                          height="20px"
                          cursor="pointer"
                          style={{ verticalAlign: "middle" }}
                        />
                      </span>
                    </div>

                    <div className="newhistoryexname">
                      <h2>Calm</h2>
                    </div>
                  </div>
                </div>
                <div className="newhistorydelete">
                  <div className="newdelicon" onClick={clearSingleFavorite}>
                    <div className="icondelete">
                      <div className="delimageback">
                        <img src={deletesvg} alt="deletsvg"></img>
                      </div>
                    </div>
                    <div className="deletetitle">
                      <p style={{ marginBottom: "0px" }}>{t("REMOVEFAVORITE")}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="exerciseitemshow" style={{ marginTop: "71px" }}>
                <div className="item1block">
                  <div className="exercoseitembackground">
                    <div className="newhistoryitem" style={{ marginLeft: "-12px" }}>
                      <div className="goalicons">
                        <img src={GoalIcon} alt="goalicons"></img>
                      </div>
                      <div className="goalicontitle " >
                        <p>{favoriteDetail.goal}</p>
                        <span>
                          {t("GOAL_T")}
                          <Icon
                            icon="ant-design:info-circle-filled"
                            color="#29aae3"
                            width="20"
                            height="20"
                            cursor={"pointer"}
                            style={{ marginLeft: "5px", cursor: "pointer" }}
                            onClick={() => setnewpopup(true)}
                          />
                          <span></span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item2block">
                  <div className="exercoseitembackground">
                    <div
                      className="newhistoryitem"
                      style={{ marginLeft: "-5px" }}
                    >
                      <div className="goalicons">
                        <img src={TimeIcon} alt="TimeIcon"></img>
                      </div>
                      <div className="goalicontitle">
                        <p>{favoriteDetail.durationMinutes == -1 ? <Icon icon="typcn:infinity" color="grey" width="24" height="24" /> : favoriteDetail.durationMinutes}</p>
                        <span> {t("TIMEMIN_T")}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item3block">
                  <div className="exercoseitembackground">
                    <div
                      className="newhistoryitem"
                      style={{ marginLeft: "-9px" }}
                    >
                      <div className="goalicons">
                        <img src={VoiceIcon} alt="VoiceIcon"></img>
                      </div>
                      <div
                        className="goalicontitle"
                        style={{ marginLeft: "-5px" }}
                      >
                        <p>
                          {favoriteDetail.narration
                            ? favoriteDetail.narration
                            : "--"}
                        </p>
                        <span> {t("NARRATION")}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item5block">
                  <div className="exercoseitembackground responseextra exercoseitembackgrounds">
                    <div
                      className="newhistoryitem response_breath_icon"
                      style={{ marginLeft: "13px" }}
                    >
                      <div className="goalicons fixing_icon">
                        <Icon
                          icon="ph:waves-bold"
                          color="#4cabe3"
                          width="25"
                          height="25"
                        />
                      </div>
                      <div
                        className="goalicontitle response_bigtitle"
                        style={{ marginLeft: "17px", marginTop: "12px" }}
                      >
                        <p>
                          {favoriteDetail.breathing_pace
                            ? favoriteDetail.breathing_pace
                            : "--"}
                        </p>
                        <p
                          style={{
                            marginTop: "7px",
                            color: "#a8a4a4",

                            font: "normal normal normal 12px/18px Rubik",
                          }}
                        >
                          {t("BREATHPASE_T")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="allfeedback">
                <div className="feedbacknumber">
                  <p>{t("FEEDBACK")}({favoriteDetail.feedback_data.length})</p>
                </div>
                <div className="scroll_cus_feedback">
                  {favoriteDetail.feedback_data
                    ? favoriteDetail.feedback_data.map((item, index) => (
                      <div className="feedback_bottom_area" key={index}>
                        <div className="feedbackhistory">
                          <div className="feedbacktime">
                            <p>
                              {moment.utc(item.created_at).local().format("YYYY-MM-DD | hh:mm:ss")}
                            </p>
                          </div>
                          <div
                            className="feedbackdelete" style={buttondisable == false ? { pointerEvents: "none" } : {}}
                            onClick={() => (buttondisable == true ? deleteFeedBack(item.exerciseFeedbackID) : "")

                            }
                          >
                            <span>
                              <img
                                src={deletesvg}
                                alt="deletsvg"
                                style={{ verticalAlign: "middle" }}
                              ></img>
                              <span
                                className="Deleteonefeedback"
                                style={{ marginLeft: "5px" }}
                              >
                                {t("DELETE")}
                              </span>
                            </span>
                          </div>
                        </div>
                        <span className="fulldiscription">
                          {item.description}
                        </span>
                      </div>
                    ))
                    : ""}
                </div>
                {favoriteDetail.feedback_data.length === 0 ? (
                  <p className="noDataFound">  {t("NOFEEDBACK")}</p>
                ) : (
                  ""
                )}
              </div>
              <div className="divider_Boxes" >
                <hr className="dividers"></hr>
              </div>
              <div className="newhistfootersec">
                <div
                  style={{ maxWidth: "34%", width: "100%" }}
                  className="changesresponse"
                >
                  <button
                    className="button_replay"
                    style={buttondisable == false ? { pointerEvents: "none" } : {}}
                    onClick={setReplayHistoryList}
                  >
                    <span style={{ marginRight: "10px" }}>
                      <Icon
                        icon="ic:round-replay"
                        color="rgba(0, 0, 0, 0.9)"
                        width="25"
                        height="25"
                      />
                    </span>
                    {t("REPLAY")}
                  </button>
                </div>
                <div
                  style={{ maxWidth: "66%", width: "100%" }}
                  className="responsefooterwork2"
                >
                  <div className="footersecondpart footersecondpart">
                    <button onClick={videoLanguageChange}>
                      <span>
                        <span style={{ marginRight: "10px" }}>
                          <img
                            src={duplicate}
                            alt="duplicate"
                            width="20px"
                            height="20px"
                            style={{ verticalAlign: "middle" }}
                          />
                        </span>
                        {t("NEWDUPLICATE")}
                      </span>
                    </button>
                    <button onClick={() => setShare(true)}>
                      <span style={{ marginRight: "10px" }}>
                        <Icon
                          icon="dashicons:share"
                          color="#FFFFFF"
                          width="20"
                          height="20"
                        />
                      </span>
                      {t("SHARE")}
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <SmallLoader />
          )}
        </div>
      </div>

      {/* ------Share popup on click------ */}

      <div className={shareBox ? "cares2" : "cares"}>
        <SharePopup shareBox={shareBox} setShareBox={setShare} trailer={favoriteDetail.trailer} BASE_URL={BASE_URL} />
      </div>

      {/* ------this one is popup on goal------ */}

      <div className={newpopup == false ? "hide_player" : "backgrounone"}>
        <div className="Featurdgoalbody">
          <div className="Featureclaainnerbody">
            <div className="Featuretitlbody">
              <div className="title">
                <h2>{favoriteDetail.goal}</h2>
              </div>
              <div className="crossimage" style={{ alignSelf: "center" }}>
                <Icon
                  icon="bytesize:close"
                  color="white"
                  width="20"
                  height="20"
                  cursor={"pointer"}
                  onClick={() => setnewpopup(false)}
                />
              </div>
            </div>
            <div style={{ marginTop: "5px" }}>
              <div>
                <ReactPlayer
                  playsinline
                  config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                  url={favoriteDetail.goal_video}
                  controls
                  width="100%"
                  height="210px"
                  autoplay
                  muted={false}
                  playing={newpopup}
                />
              </div>
              <div style={{ marginTop: "26px" }}>
                <p className="goalinformation">
                  {favoriteDetail.goal_description}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <VideoLanguageChange show={show}/> */}

    </>
  );
}

export default NewFavouriteDetail;