import React, { useEffect, useState } from "react";
import "./LanguageSetting.css";
import Nav from "../../Header/header";
import { Icon } from "@iconify/react";
import { useHistory, useParams } from "react-router-dom";
import SublyApi from "../../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import loaders from "../../../Assets/Images/loader.gif";
import { getUser } from "../../../actions/currentUser";
import { Toast } from "../../../commonFile";
import { Loader } from "../../../commonFile";
import { Getlanguage } from "../../../commonfunction";
import { useTranslation } from "react-i18next";
import { getinternet } from "../../../commonfunction";
import { deleteToken } from "../../../actions/currentUser";
import { logout } from "../../../actions/currentUser";
import { deleteDefaultList } from "../../../actions/defaultList";
import { clearErr } from "../../../actions/errors";
import { deleteExcerciseList } from "../../../actions/getExcercise";
import { deleteExperience } from "../../../actions/getexperiense";
import { deleteGender } from "../../../actions/getgender";
import { deleteLanguage } from "../../../actions/getlanguage";
import { deleteAge } from "../../../actions/getsurvey";
import { deletTimeNarration } from "../../../actions/timeNarrationList";
import { removeTempData } from "../../../actions/tempData";
import { deleteNarrationTheme } from "../../../actions/narrationTheme";
import { deleteDefault } from "../../../actions/homeSession";
import { deleteGoalTimeList } from "../../../actions/goalTimeList";
import { deleteNewDefaultList } from "../../../actions/defaultList";


function LanguageSetting() {
  const [show, setShow] = useState();
  const [details, setDetails] = useState();
  const [detailslang, setDetailslang] = useState();
  const [load, setLoad] = useState();
  const history = useHistory();
  const token = useSelector((st) => st.token);
  const dispatch = useDispatch();
  const currentUser = useSelector((st) => st.currentUser);
  const { t, i18n } = useTranslation();
  const { route } = useParams();
  useEffect(() => {
    async function setlanguage() {
      const geti18value = await Getlanguage(parseInt(currentUser.default_language ? currentUser.default_language : 1));
      i18n.changeLanguage(geti18value);
    }
    setlanguage();
  }, []);

  // ----logOut while token expired----

  const signOut = () => {
    dispatch(logout());
    dispatch(deleteToken());
    dispatch(deleteDefaultList());
    dispatch(clearErr());
    dispatch(deleteExcerciseList());
    dispatch(deleteExperience());
    dispatch(deleteGender());
    dispatch(deleteLanguage());
    dispatch(deleteAge());
    dispatch(deletTimeNarration());
    dispatch(removeTempData());
    dispatch(deleteNarrationTheme());
    dispatch(deleteDefault());
    dispatch(deleteGoalTimeList());
    dispatch(deleteNewDefaultList());
    dispatch(deleteDefaultList());
    history.push("/login");
  };

  // ----here i am listing the language data in useEffect----

  useEffect(() => {
    async function languageData() {
      const languageList = await SublyApi.accountDetails(token, currentUser.device_id, currentUser.device_timezone);
      if (
        languageList &&
        languageList.data.error_type === "USER_NOT_FOUND" &&
        languageList.data.status_code === 400
      ) {
        signOut();
      }
      else {
        if (languageList &&
          languageList.data.status_code === 400) {

        }
        if (languageList &&
          languageList.status_code === 200)
          if (languageList.data.language_data) {
            setDetails(languageList ? languageList.data.language_data : "");
          }
      }
    }
    languageData();

    async function defaultLang() {
      const defaultList = await SublyApi.userInformation(token, currentUser.device_id, currentUser.device_timezone);
      if (
        defaultList &&
        defaultList.data.error_type === "USER_NOT_FOUND" &&
        defaultList.data.status_code === 400
      ) {
        signOut();
      }
      else {
        if (defaultList &&
          defaultList.data.status_code === 400) {

        }
        if (defaultList &&
          defaultList.status_code === 200)
          if (defaultList.data.user_info) {
            setDetailslang(defaultList ? defaultList.data.user_info : "");
          }
      }
    }
    defaultLang();
  }, []);

  // -----function for sending the data to the API for set language-----

  async function handleSubmit() {
    const online = await getinternet();

    if (online === false) {
      Toast.fire({
        icon: "error",
        title: t("noiternet"),
      });
    }
    else {
      setLoad(true);
      let requestData = new FormData();
      requestData.append(
        "default_language",
        `${show ? show : detailslang.default_language}`
      );
      await SublyApi.updateLanguage(token, requestData, currentUser.device_id, currentUser.device_timezone)
        .then((responsejson) => {
          if (responsejson &&
            responsejson.data.error_type == "USER_NOT_FOUND" &&
            responsejson.data.status_code === 400) {
            signOut();
          }
          else {
            if (responsejson.data.status_code === 400) {
              Toast.fire({
                icon: "error",
                title: responsejson.data.message,
              });
              setLoad(false);
            }
            if (responsejson.status_code === 200) {
              dispatch(
                getUser({
                  default_language: responsejson.data.updated_data.default_language,
                })
              );
              Toast.fire({
                icon: "success",
                title: responsejson.message
                  ? responsejson.message
                  : t("exerciseclear"),
              });
              setLoad(false);
              if (route == 1) {
                history.push("/home")
              }
              else {
                history.push("/Setting");
              }
            }
          }
        })
        .catch(() => { });
    }
  }

  // ----function for getting id of radio----

  async function RadioICon(e) {
    const valuesRadio = e.currentTarget.id;
    setShow(valuesRadio);
  }

  // ----Icons for Radio Button----

  const iconOne = (
    <Icon
      icon="fluent:radio-button-20-regular"
      color="#707070"
      width="30"
      height="30"
    />
  );
  const iconTwo = (
    <Icon
      icon="fluent:radio-button-20-filled"
      color="#29aae3"
      width="30"
      height="30"
    />
  );

  return (
    <>
      <section>
        <Nav></Nav>
        <div className="language_Container">
          <div className="langauge_Body">
            <div className="language_Header">
              <div className="header_Icon">
                <Icon
                  icon="eva:arrow-ios-back-fill"
                  color="white"
                  width="24"
                  height="24"
                  cursor="pointer"
                  onClick={() => {
                    history.push("/setting");
                  }}
                />
              </div>
              <div className="header_Title">
                <h5>{t("LANGUAGE")}</h5>
              </div>
            </div>
            {detailslang ? (
              <form className="language_Field">
                <div className="lang_Field_1">
                  {details
                    ? details.map((item, index) => (
                      <label for="language1" key={index}>
                        {item.value}
                        <input
                          type="radio"
                          checked={
                            detailslang.default_language
                              ? item.id == detailslang.default_language
                              : 1
                          }
                          id={item.id}
                          name="language"
                          onClick={RadioICon}
                        />
                        {(
                          show
                            ? show == item.id
                            : item.id == detailslang.default_language
                        )
                          ? iconTwo
                          : iconOne}
                      </label>
                    ))
                    : ""}
                </div>
                <div className={`${load ? "loaderImg" : "removeSpace"}`}>
                  {load ? <img src={loaders} alt="loader" /> : null}
                </div>
                <div className="Save_Button">
                  <button style={{ color: "black" }} type="button" onClick={handleSubmit}>
                    {t("SAVE")}
                  </button>
                </div>
              </form>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default LanguageSetting;
