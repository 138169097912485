import { FETCH_DEFAULT_LIST, REMOVE_DEFAULT_LIST } from '../actions/types';

export default function rootReducer(state = {}, action) {
	switch (action.type) {


		case FETCH_DEFAULT_LIST:
			return { ...state, ...action.defaultData };
		case REMOVE_DEFAULT_LIST:
			return {};
		default:
			return state;
	}
}
