import { FETCH_CURRENT, REMOVE_CURRENT } from '../actions/types';

export default function rootReducer(state = {}, action) {

	switch (action.type) {
		case FETCH_CURRENT:
			return { ...state, ...action.user };

		case REMOVE_CURRENT:
			return {};
		default:
			return state;
	}
}
