import { showErr, clearErr } from "./errors";
import {
    LANGUAGE_LIST, REMOVE_LANGUAGE_LIST
} from "./types";


// save language survey value
export function getLanguageValue(languagevalue) {
    return async function (dispatch) {
        try {
            dispatch(clearErr());
            dispatch(getLanguage(languagevalue));
        } catch (err) {
            dispatch(showErr(err));
        }
    };
};







function getLanguage(defaultData) {
    return {
        type: LANGUAGE_LIST,
        defaultData,
    };
}




export function deleteLanguage(defaultData) {
    return {
        type: REMOVE_LANGUAGE_LIST,
        defaultData,
    };
}