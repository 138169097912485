import {STORE_TEMP_DATA, REMOVE_TEMP_DATA} from "./types";
import { showErr, clearErr } from "./errors";



// save temporary data 
export function temporaryData(data) {
    return async function (dispatch) {
        try {
            dispatch(clearErr());
            dispatch(getTempData(data));
        } catch (err) {
            dispatch(showErr(err));
        }
    };
};



function getTempData(tempData) {
    return {
        type: STORE_TEMP_DATA,
        tempData,
    };
}


export function removeTempData(tempData) {
    return {
        type: REMOVE_TEMP_DATA,
        tempData,
    };
}