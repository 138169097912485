import "./BasicCalibration.css";
import SublyApi from "../../helpers/Api";
import { useState, useEffect, useRef } from "react";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";
import { Getlanguage } from "../../commonfunction";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import headerbrithlogo from "../../Assets/Images/Logo1.svg";
import { Toast } from "../../commonFile";
import calibrarionVideoSpeeds from "../calibrarionVideoSpeeds";

const videos = calibrarionVideoSpeeds;

const IntroVdo = () => {
  const videoRef = useRef(null);
  const handleContextMenu = (e) => {
    e.preventDefault(); // Prevent the default right-click context menu
  };
  const history = useHistory();
  const token = useSelector((st) => st.token);
  const currentUser = useSelector((st) => st.currentUser);
  const { i18n } = useTranslation();
  const [getAllVideo] = useState(videos);
  const [speed, setSpeed] = useState();
  const { t } = useTranslation();

  function redirecthome() {
    history.push("/home");
  }

  useEffect(() => {
    async function getSpeed() {
      const speed = await SublyApi.GetUserSpeed(token, currentUser.userID);
      setSpeed(speed.data.speed);
    }
    getSpeed();
  }, []);

  useEffect(() => {
    async function setlanguage() {
      const geti18value = await Getlanguage(
        parseInt(currentUser.default_language ? currentUser.default_language : 1)
      );
      i18n.changeLanguage(geti18value);
    }
    setlanguage();
  }, []);

  const handleResponse = (response) => {
    setUserResponses((prevResponses) => [
      ...prevResponses,
      { videoId: videos[videoIndex].id, response },
    ]);
  };

  const saveResponse = async () => {
    const currentVideoTitle = getAllVideo[videoIndex].title;
    let requestData = new FormData();
    requestData.append("userID", currentUser.userID);
    requestData.append("recommended_speed", currentVideoTitle.toString());
    await SublyApi.SetUserSpeed(token, requestData, currentUser.device_id, currentUser.device_timezone)
    .then(async (responsejson) => {
      if (responsejson.status_code !== 200) {
        const failTitle = t("failSetUserSpeed");
        Toast.fire({
          icon: "error",
          title: failTitle
        });
      } else {
          Toast.fire({
            icon: "success",
            title: responsejson.message
          });
      }
    }
    )
    history.push("/Home");
  };

  const skipToEnd = () => {
    if (videoRef.current) {
      videoRef.current.seekTo(videoRef.current.getDuration(), "seconds");
    }
  };

  const playNextVideo = () => {
    if (videoRef.current) {
      videoRef.current.seekTo(0, "seconds"); // Set the current time to 0 to replay from the beginning
    }
    if (userResponses.length === 0) {
      // Replay video if no button is selected
      return;
    }

    // Clear the previous user response
    setUserResponses([]);

    const selectedResponse = userResponses[userResponses.length - 1].response;
    if (selectedResponse === "too fast" && videoIndex > 0) {
      setVideoIndex((prevIndex) => prevIndex - 1); // Play the next slowest video
    } else if (selectedResponse === "too slow" && videoIndex < videos.length - 1) {
      setVideoIndex((prevIndex) => prevIndex + 1); // Play the next fastest video
    } else {
      saveResponse();
    }

    setDisplayButtons(false);
    setTimeout(() => {
      setDisplayButtons(true);
    }, 10000);
  };

  const [videoIndex, setVideoIndex] = useState(0);
  const [userResponses, setUserResponses] = useState([]);
  const [displayButtons, setDisplayButtons] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDisplayButtons(true);
    }, 10000); // Delay the display of buttons for 30 seconds

    return () => clearTimeout(timer);
  }, [videoIndex]);

  useEffect(() => {
    // Find the index of the video with the matching speed
    const matchingVideoIndex = videos.findIndex((video) => video.title === speed);

    // Set the initial video index to the matching index or 0 if no match is found
    setVideoIndex(matchingVideoIndex >= 0 ? matchingVideoIndex : 0);
  }, [speed]);

  return (
    <section className="SessionBody">
      <section className="fullsection" style={{ zIndex: "999", position: "sticky" }}>
        <div className="headercontainer ">
          <div className="headernewimage" onClick={() => { redirecthome() }}>
            <img style={{width: "294px"}} src={headerbrithlogo} alt="breathhealogo" />
          </div>
        </div>
      </section>
      <div className="historyflexbody">
        <div className="calibrationContainer">
          <div className="calibrationNewIntroBox">
            <br />
            <h3 style={{ color: "#FFFFFF" }}>{t("calibrationTitle")}</h3>
            <h6 style={{ color: "#a0a0a0" }}>{t("calibrationSubTitle")}</h6>
            <br />
            {videos.length > 0 && (
      <div onContextMenu={handleContextMenu}>
        <ReactPlayer
          ref={videoRef}
          className="calibrationVideoPlayer"
          url={videos[videoIndex].videoUrl}
          controls
          playing
          autoPlay={true}
          onEnded={playNextVideo}
        />
      </div>
    )}
            <button
              className={`calibrationSkipButton ${displayButtons && (userResponses.length >= 1 && userResponses[userResponses.length - 1].response !== 'just right') ? '' : 'invisible'}`}
              onClick={skipToEnd}
              disabled={!displayButtons || (userResponses.length > 0 && userResponses[userResponses.length - 1].response !== 'too fast' && userResponses[userResponses.length - 1].response !== 'too slow')}
            >
              {t("skipPace")}
            </button>
            <h5
              style={{
                color: "#FFFFFF",
                opacity: displayButtons ? 1 : 0,
                transition: "opacity 0.5s ease",
              }}
            >
              {t("calibrationPrompt")}
            </h5>
            <div
              className="calibrationIntroEndSessionBut"
              style={{
                opacity: displayButtons ? 1 : 0,
                transition: "opacity 0.5s ease",
              }}
            >
              <button
                onClick={() => handleResponse("too fast")}
                disabled={!displayButtons || videoIndex <= 0}
                className={videoIndex <= 0 ? "disabledButton" : ""}
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  color: "#FFFFFF",
                  outline:
                    userResponses.length > 0 &&
                    userResponses[userResponses.length - 1].response === "too fast"
                      ? "2px solid #29aae3"
                      : "none",
                }}
              >
                {t("tooFast")}
              </button>
              <button
                onClick={() => handleResponse("just right")}
                disabled={!displayButtons}
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  color: "#FFFFFF",
                  outline:
                    userResponses.length === 0 ||
                    userResponses[userResponses.length - 1].response === "just right"
                      ? "2px solid #29aae3"
                      : "none",
                }}
              >
                {t("justRight")}
              </button>
              <button
                onClick={() => handleResponse("too slow")}
                disabled={!displayButtons || videoIndex >= videos.length - 1}
                className={videoIndex >= videos.length - 1 ? "disabledButton" : ""}
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  color: "#FFFFFF",
                  outline:
                    userResponses.length > 0 &&
                    userResponses[userResponses.length - 1].response === "too slow"
                      ? "2px solid #29aae3"
                      : "none",
                }}
              >
                {t("tooSlow")}
              </button>
            </div>
            <div className="SessionBtn">
              <button
                className={`btn btn-primary`}
                disabled={!displayButtons || (userResponses.length > 0 && userResponses[userResponses.length - 1].response !== "just right")}
                onClick={saveResponse}
              >
                {t("completeCalibration")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IntroVdo;