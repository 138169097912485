import { GOAL_TIME_LIST,REMOVE_GOAL_TIME_LIST } from '../actions/types';
export default function rootReducer(state = {}, action) {
 	switch (action.type) {
		
		case GOAL_TIME_LIST:
			return {...state,...action.defaultData};
		case REMOVE_GOAL_TIME_LIST:
			return {};
	    
		default:
			return state;
	}
}
 