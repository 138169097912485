import { TIME_NARRATION_LIST,REMOVE_TIME_NARRATION_LIST } from '../actions/types';
export default function rootReducer(state = {}, action) {
 	switch (action.type) {
		
		case TIME_NARRATION_LIST:
			return {...state,...action.defaultData};
		case REMOVE_TIME_NARRATION_LIST:
			return {};
	    
		default:
			return state;
	}
}
 