import { FETCH_DEFAULT_DATA,REMOVE_DEFAULT_DATA } from '../actions/types';
export default function rootReducer(state = {}, action) {
 	switch (action.type) {
		
		case FETCH_DEFAULT_DATA:
			return {...state,...action.defaultData};
		 case REMOVE_DEFAULT_DATA:
		 	return {};
	    
		default:
			return state;
	}
}
 