import { EXCERCISE_LIST, REMOVE_EXCERCISE_LIST } from '../actions/types';
export default function rootReducer(state = {}, action) {
	switch (action.type) {

		case EXCERCISE_LIST:
			return { ...state, ...action.defaultData };
		case REMOVE_EXCERCISE_LIST:
			return {};

		default:
			return state;
	}
}
