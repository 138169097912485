import React from "react";
import "./NotFound.css";
import errorImg from "../../Assets/Images/error-img.png";

function NotFound() {
  return (
    <section className="container notfound_Body">
      <div className="contain_Area">
        <div className="Contain-text">
          <h1>404</h1>
          <h3>oops...</h3>
          <h2>Page Not Found</h2>
        </div>
        <div className="img_Conatin">
          <span>
            <img src={errorImg} alt="error_sign" />
          </span>
        </div>
      </div>
    </section>
  );
}

export default NotFound;