import React, { useState, useEffect } from "react";
import "./ChangePassword.css";
import Nav from "../../Header/header.js";
import { Icon } from "@iconify/react";
import { useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import SublyApi from "../../../helpers/Api";
import { Toast } from "../../../commonFile";
import { SmallLoader } from "../../../commonFile";
import * as Yup from "yup";
import { Getlanguage } from "../../../commonfunction";
import { useTranslation } from "react-i18next";
import { getinternet } from "../../../commonfunction";
import { deleteToken } from "../../../actions/currentUser";
import { logout } from "../../../actions/currentUser";
import { deleteDefaultList } from "../../../actions/defaultList";
import { clearErr } from "../../../actions/errors";
import { deleteExcerciseList } from "../../../actions/getExcercise";
import { deleteExperience } from "../../../actions/getexperiense";
import { deleteGender } from "../../../actions/getgender";
import { deleteLanguage } from "../../../actions/getlanguage";
import { deleteAge } from "../../../actions/getsurvey";
import { deletTimeNarration } from "../../../actions/timeNarrationList";
import { removeTempData } from "../../../actions/tempData";
import { deleteNarrationTheme } from "../../../actions/narrationTheme";
import { deleteDefault } from "../../../actions/homeSession";
import { deleteGoalTimeList } from "../../../actions/goalTimeList";
import { deleteNewDefaultList } from "../../../actions/defaultList";


function ChangePassword() {
  const [load, setLoad] = useState();
  const history = useHistory();
  const token = useSelector((st) => st.token);
  const dispatch = useDispatch();
  const currentUser = useSelector((st) => st.currentUser);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    async function setlanguage() {
      const geti18value = await Getlanguage(parseInt(currentUser.default_language ? currentUser.default_language : 1));
      i18n.changeLanguage(geti18value);
    }
    setlanguage();
  }, []);

  // ----useform property----

  const formOptions = {
    resolver: yupResolver(Yup.object().shape({
      new_password: Yup.string()
        .required(t("passwordrew"))
        .matches(
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])/,
          t("signinpwderror")
        ),

      confirm_password: Yup.string()
        .required(t("confirmpassreq"))
        .oneOf([Yup.ref("new_password")], t("passwordnotmatch")),
    }))
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions);

  // ----logOut while token expired----

  const signOut = () => {
    dispatch(logout());
    dispatch(deleteToken());
    dispatch(deleteDefaultList());
    dispatch(clearErr());
    dispatch(deleteExcerciseList());
    dispatch(deleteExperience());
    dispatch(deleteGender());
    dispatch(deleteLanguage());
    dispatch(deleteAge());
    dispatch(deletTimeNarration());
    dispatch(removeTempData());
    dispatch(deleteNarrationTheme());
    dispatch(deleteDefault());
    dispatch(deleteGoalTimeList());
    dispatch(deleteNewDefaultList());
    dispatch(deleteDefaultList());
    history.push("/login");
  };

  // ----here sending the required data for reset password----

  async function onSubmit(form) {
    const online = await getinternet();
    if (online === false) {
      Toast.fire({
        icon: "error",
        title: t("noiternet"),
      });
    }
    else {
      setLoad(true);
      let requestData = new FormData();
      requestData.append("old_password", `${form.old_password}`);
      requestData.append("new_password", `${form.new_password}`);
      requestData.append("confirm_password", `${form.confirm_password}`);
      await SublyApi.passwordChange(token, requestData, currentUser.device_id, currentUser.device_timezone)
        .then((responsejson) => {
          if (responsejson &&
            responsejson.data.error_type == "USER_NOT_FOUND" &&
            responsejson.data.status_code === 400) {
            // Toast.fire({
            //   icon: "error",
            //   title: responsejson.data.message,
            // });
            signOut();
          }
          else {
            if (responsejson.data.status_code === 400) {
              Toast.fire({
                icon: "error",
                title: responsejson.data.message,
              });
              setLoad(false);
            } else {
              Toast.fire({
                icon: "success",
                title: responsejson.message,
              });
              setLoad(false);
              history.push("/Setting");
            }
          }
        })
        .catch(() => { });
    }
  }

  // for show hide  eye icon
  const [showIcon, setShowIcon] = useState(false);
  const [showIcon1, setShowIcon1] = useState(false);
  const eyeIconOff = (
    <Icon
      icon="eva:eye-off-outline"
      color="#a8a4a4"
      width="25"
      height="25"
      opacity="0.8"
      cursor="pointer"
    />
  );

  const eyeIconOn = (
    <Icon
      icon="eva:eye-outline"
      color="#a8a4a4"
      width="25"
      height="25"
      opacity="0.8"
      cursor="pointer"
    />
  );

  return (
    <>
      <section>
        <Nav></Nav>
        <div className="password_Container">
          <div className="changePass_Body">
            <div className="changePass_Header">
              <div className="headerBack_Icon">
                <Icon
                  icon="eva:arrow-ios-back-fill"
                  color="white"
                  width="23"
                  cursor="pointer"
                  height="23"
                  onClick={() => {
                    history.push("/setting");
                  }}
                />
              </div>
              <div className="changePass_Title">
                <h5>{t("CHANGEPASSWORD")}</h5>
              </div>
            </div>
            <div className="changePass_Field">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="pass_input_Field old_Pass_Body">
                  <label className="oldPass">{t("OLDPASSWORD")}</label>
                  <input
                    className="inputfieldauto"
                    type="password"
                    placeholder={t("oldpass")}
                    name="old_password"
                    {...register("old_password")}
                  />
                </div>
                <div className="pass_input_Field">
                  <label className="oldPass">{t("NEWPASSWORD")}</label>
                  <div className="password_Input2">
                    <input
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      onCopy={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      className="inputfieldauto"
                      type={`${showIcon ? "text" : "password"}`}
                      placeholder={t("newpass")}
                      name="new_password"
                      {...register("new_password")}
                    />
                    <span
                      className="password_Eye"
                      onClick={() => {
                        showIcon === false
                          ? setShowIcon(true)
                          : setShowIcon(false);
                      }}
                    >
                      {showIcon ? eyeIconOn : eyeIconOff}
                    </span>
                  </div>
                  <p className="forError"> {errors.new_password?.message}</p>
                </div>
                <div className="pass_input_Field">
                  <label className="oldPass">{t("CONFIRMPASSWORD")}</label>
                  <div className="password_Input2">
                    <input
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      onCopy={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      className="inputfieldauto"
                      type={`${showIcon1 ? "text" : "password"}`}
                      placeholder={t("confirm")}
                      name="confirm_password"
                      {...register("confirm_password")}
                    />
                    <span
                      className="password_Eye"
                      onClick={() => {
                        showIcon1 === false
                          ? setShowIcon1(true)
                          : setShowIcon1(false);
                      }}
                    >
                      {showIcon1 ? eyeIconOn : eyeIconOff}
                    </span>
                  </div>
                  <p className="forError">{errors.confirm_password?.message}</p>
                </div>
                <div className="changePasswordError">
                  {load ? <SmallLoader /> : null}
                  <div className="Update_button">
                    <button style={{ color: "black" }} type="submit">{t("UPDATE")}</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ChangePassword;
