import "./HomeVideo.css";
import React from "react";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";
import close from "../../../../Assets/Images/closeIcon.svg";

const HomeVideo = () => {
  return (
    <div className="vdoWrapper">
      <div className="vdoBox">
        <NavLink to="/">
          <img src={close} alt="close" />
        </NavLink>
        <ReactPlayer
          playsinline
          controls
          width="845px"
          height="410px"
          url="https://www.youtube.com/embed/l0iWrFLPb3A?autoplay=1"
        />
      </div>
    </div>
  );
};
export default HomeVideo;
