import { REMOVE_HOMESCREEN, FETCH_HOMESESSION } from '../actions/types';

export default function rootReducer(state = {}, action) {
 	switch (action.type) {
		case FETCH_HOMESESSION:
			return { ...state, ...action.defaultData };
		case REMOVE_HOMESCREEN:
			return {};
		 
		default:
			return state;
	}
}
 