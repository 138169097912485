import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { TRANSLATIONS_ARAB } from "../translations/arab/translation";
import { TRANSLATIONS_EN } from "../translations/en/translation";
import { TRANSLATIONS_FR } from "../translations/fr/translation";
import { TRANSLATIONS_GE } from "../translations/ge/translation";
import { TRANSLATIONS_UK } from "../translations/uk/translation";
import { TRANSLATIONS_ES } from "../translations/es/translation";

export default i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: TRANSLATIONS_EN
            },
            es: {
                translation: TRANSLATIONS_ES
            },
            arab: {
                translation: TRANSLATIONS_ARAB
            },
            fr: {
                translation: TRANSLATIONS_FR
            },
            ge: {
                translation: TRANSLATIONS_GE
            },
            uk: {
                translation: TRANSLATIONS_UK
            }
        }
    });

i18n.changeLanguage(["en", "es", "arab", "fr", "ge", "uk"]);


