import React, { useEffect, useState } from "react";
import {  useHistory } from "react-router-dom";
import "../../RatingSection/RatingSection.css";
import { useDispatch, useSelector } from "react-redux";
import GoalIcon from "../../../Assets/Images/Goal.svg";
import breathimage from "../../../Assets/Images/breathimage.png";
import TimeIcon from "../../../Assets/Images/Time.svg";
import { temporaryData } from "../../../actions/tempData";
import { Icon } from "@iconify/react";
import ReactRating from "react-rating";
import {
  BsFillEmojiNeutralFill,
  BsFillEmojiFrownFill,
  BsFillEmojiSmileFill,
  BsFillEmojiLaughingFill,
  BsFillEmojiHeartEyesFill,
} from "react-icons/bs";
import {
  BsEmojiNeutral,
  BsEmojiFrown,
  BsEmojiSmile,
  BsEmojiLaughing,
  BsEmojiHeartEyes,
} from "react-icons/bs";
import { Getlanguage } from "../../../commonfunction";
import { useTranslation } from "react-i18next";
function FeedBackRatting(props) {
  const getExcercise = useSelector((st) => st.getExcercise);
  const [ratingValue, setRatingValue] = useState(0);
  const [textArea, setTextArea] = useState("");
  const [newpopup, setnewpopup] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const currentUser = useSelector((st) => st.currentUser);
  // -----icons for rating-----
  const { t, i18n } = useTranslation();
  useEffect(() => {
    async function setlanguage() {
      const geti18value = await Getlanguage(
        parseInt(
          currentUser.default_language ? currentUser.default_language : 1
        )
      );
      i18n.changeLanguage(geti18value);
    }
    setlanguage();
  }, []);
  const customIcons = [
    <BsEmojiFrown className="ratingicons" size={34} />,
    <BsEmojiNeutral className="ratingicons" size={34} />,
    <BsEmojiSmile className="ratingicons" size={34} />,
    <BsEmojiLaughing className="ratingicons" size={34} />,
    <BsEmojiHeartEyes className="ratingicons " size={34} />,
  ];

  const fillIcons = [
    <span className="fillBackground">
      <small></small>
      <BsFillEmojiFrownFill className="fillColour" size={34} />
    </span>,
    <span className="fillBackground">
      <small></small>
      <BsFillEmojiNeutralFill className="fillColour" size={34} />
    </span>,
    <span className="fillBackground">
      <small></small>
      <BsFillEmojiSmileFill className="fillColour" size={34} />
    </span>,
    <span className="fillBackground">
      <small></small>
      <BsFillEmojiLaughingFill className="fillColour" size={34} />
    </span>,
    <span className="fillBackground">
      <small></small>
      <BsFillEmojiHeartEyesFill className="fillColour " size={34} />
    </span>,
  ];


  const handleRating = (rate) => {
    setRatingValue(rate);
  };

  // ---storing tamporary data---

  function storingData() {
    const ratingData = ratingValue;
    const feedbackData = textArea;
    // ----storing the data in redux----
    dispatch(
      temporaryData({
        ratingValue: ratingData,
        feedbackValue: feedbackData,
      })
    );
    props.st2(false);
  }

  return (
    <>
      <section>
        <div className="Ratingfullbody">
          <div className="Ratingupperbody">
            <Icon
              icon="eva:arrow-ios-back-fill"
              color="white"
              width="30"
              height="30"
              style={{
                marginLeft: "-18px",
                position: "absolute",
                marginTop: "18px",
                cursor: "pointer",
              }}
              onClick={() => {
                props.st2(false);
              }}
            />
            <div className="Ratingtitle">
              <h3>{getExcercise[0].after_questions}</h3>
            </div>

            <div className="RatingItems" style={{ marginTop: "54px" }}>
              <div className="RatingIcon_Text">
                <div className="dividerrule">
                  <ReactRating
                    initialRating={ratingValue}
                    emptySymbol={customIcons}
                    fullSymbol={fillIcons}
                    onClick={handleRating}
                  />
                </div>
                <div
                  className="ratingText1 ratingText"
                  style={{ width: "100%" }}
                >
                  <p>{getExcercise[0].goal_rating.low_rating}</p>
                </div>
                <div
                  className="ratingText2 ratingText"
                  style={{ width: "100%" }}
                >
                  <p>{getExcercise[0].goal_rating.high_rating}</p>
                </div>
              </div>
            </div>
            <div className="rateleavefeedback">
              <p> {t("LEAVEFEEDBACK")}</p>
            </div>

            <div className="leavefeedbackbox">
              <textarea
                id="textArea"
                name="textArea"
                onChange={(e) => {
                  setTextArea(e.target.value);
                }}
              ></textarea>
            </div>

            <div className="ratingitemdetail">
              <div className="itemdetail">
                <div >
                  <div className="ratingitemflexbody">
                    <div className="ratingimageflex">
                      <img
                        src={GoalIcon}
                        alt="goalrating"
                        style={{ verticalAlign: "middle" }}
                      />
                    </div>
                    <div>
                      <p className="rate_relax">
                        {getExcercise[0].goal_rating.goaltitle}
                      </p>
                      <p className="rate_relax2">
                        {t("GOAL_T")}
                        <Icon
                          icon="ant-design:info-circle-filled"
                          color="#29aae3"
                          width="20"
                          height="20"
                          style={{ marginLeft: "5px", cursor: "pointer" }}
                          onClick={() => setnewpopup(true)}
                        />
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="ratingitemflexbody">
                    <div className="ratingimageflex">
                      <img
                        src={TimeIcon}
                        alt="timerating"
                        style={{ verticalAlign: "middle" }}
                      />
                    </div>
                    <div>
                      <p className="rate_relax">
                        {getExcercise[0].duration_minutes == -1 ? <Icon icon="typcn:infinity" color="grey" width="30" height="30" /> : getExcercise[0].duration_minutes}
                      </p>
                      <p className="rate_relax2"> {t("TIMEMIN_T")}</p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="ratingitemflexbody">
                    <div>
                      <img
                        src={breathimage}
                        alt="timerating"
                        style={{ verticalAlign: "middle" }}
                      />
                    </div>
                    <div>
                      <p className="rate_relax">
                        {getExcercise[0].average_speed
                          ? getExcercise[0].average_speed
                          : "--"}
                      </p>
                      <p className="rate_relax2" style={{ maxWidth: "96px" }}>
                        {t("BREATHPASE_T")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="Ratingbuttonupper">
              <button
                className="Ratingsubmitbutton"
                onClick={() => {
                  storingData();
                }}
              >
                {t("SUBMITBUTTON")}
              </button>
            </div>
          </div>
        </div>
      </section>
      <div className={newpopup == false ? "hide_player" : "backgrounone"}>
        <div className="Featurdgoalbody">
          <div className="Featureclaainnerbody changeInHeight">
            <div className="Featuretitlbody">
              <div className="title">
                <h2>{getExcercise[0].goal_rating.goaltitle}</h2>
              </div>
              <div className="crossimage" style={{ alignSelf: "center" }}>
                <Icon
                  icon="bytesize:close"
                  color="white"
                  width="20"
                  height="20"
                  cursor="pointer"
                  onClick={() => setnewpopup(false)}
                />
              </div>
            </div>
            <div style={{ marginTop: "5px" }}>
              <div>
                <p className="goalinformation">
                  {getExcercise[0].goal_rating.goal_description}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FeedBackRatting;
