import { EXPERIENCE_LIST, REMOVE_EXPERIENCE_LIST } from '../actions/types';
export default function rootReducer(state = {}, action) {
    switch (action.type) {

        case EXPERIENCE_LIST:
            return { ...state, ...action };
        case REMOVE_EXPERIENCE_LIST:
            return {};

        default:
            return state;
    }
}
