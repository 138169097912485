import React from 'react';
import Routes from './routes';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
function App() {
  return (
    <>
      <Routes></Routes>
    </>
  );
}

export default App;

