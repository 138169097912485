import React from "react";
import './Reminder.css'
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import smalllogosignup from "../../../Assets/Images/Logo1.svg";
import { useTranslation } from "react-i18next";

import { Getlanguage } from "../../../commonfunction";
import { useEffect } from "react";
function Reminder() {
    const { t, i18n } = useTranslation();
    useEffect(() => {
        async function setlanguage() {
            const geti18value = await Getlanguage(parseInt(currentUser.default_language ? currentUser.default_language : 1));
            i18n.changeLanguage(geti18value);
        }
        setlanguage();
    }, []);
    const history = useHistory();
    const currentUser = useSelector((st) => st.currentUser);

    return (
        <section>
            <div className="logofirstsignup">
                <button>
                    <img style={{width: "294px"}} src={smalllogosignup} alt="remilogo" />
                </button>
            </div>
            <div className="confirmationWrapper">
                <div className="confirmAlert">
                    <div className="confirmAlertBox">
                        <h5><b>{t("HI")} </b> {currentUser.email}</h5>
                        <p>{t("DALIYREMINDER_T")} </p>
                        <div className="confirmAlertBtn">
                            <button className="yesBtn" onClick={() => history.push('/Home')}>{t("YES")}</button>
                            <button className="noBtn" onClick={() => history.push('/Home')}>{t("NO")}</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Reminder;