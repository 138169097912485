import { responsivePropType } from "react-bootstrap/esm/createUtilityClasses";
import SublyApi from "../helpers/Api";
import { showErr, clearErr } from "./errors";
import {GOAL_TIME_LIST,REMOVE_GOAL_TIME_LIST
} from "./types";

export function searchGoalTime(goalid,token) {
    
  return async function (dispatch) {
      try {
        dispatch(clearErr());
        const defaultData = await SublyApi.searchGoalTime(goalid,token);
         dispatch(getgoalTimetList(defaultData.data));
         
       } catch (err) {
        dispatch(showErr(err));
      }
    };
  }


function getgoalTimetList(defaultData) {
  return {
    type: GOAL_TIME_LIST,
    defaultData,
  };
}
 
 
export function deleteGoalTimeList(defaultData) {
  return {
    type: REMOVE_GOAL_TIME_LIST,
    defaultData,
  };
}

 