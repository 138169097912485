import React, { useState, useEffect } from "react";
import { NavLink, useParams, useHistory } from "react-router-dom";
import Nav from "../Header/header";
import { getExcercise } from "../../actions/getExcercise";
import SublyApi from "../../helpers/Api";
import { useSelector, useDispatch } from "react-redux";
import { Carousel } from "react-bootstrap";
import ReactPlayer from "react-player";
import { ImCross } from "react-icons/im";
import Modal from "react-bootstrap/Modal";
import {
  getDefaultData,
  setDefaultData,
  getDefaultList,
} from "../../actions/defaultList";
import {
  deleteDefaultList,
  deleteNewDefaultList,
} from "../../actions/defaultList";
import "./SessionSection.css";
import { Toast, Loader } from "../../commonFile";
import i18n from "../i18n";
import { Getlanguage } from "../../commonfunction";
import { useTranslation } from "react-i18next";
import { getinternet } from "../../commonfunction";
import { deleteToken } from "../../actions/currentUser";
import { logout } from "../../actions/currentUser";
import { clearErr } from "../../actions/errors";
import { deleteExcerciseList } from "../../actions/getExcercise";
import { deleteExperience } from "../../actions/getexperiense";
import { deleteGender } from "../../actions/getgender";
import { deleteLanguage } from "../../actions/getlanguage";
import { deleteAge } from "../../actions/getsurvey";
import { deletTimeNarration } from "../../actions/timeNarrationList";
import { removeTempData } from "../../actions/tempData";
import { deleteNarrationTheme } from "../../actions/narrationTheme";
import { deleteDefault } from "../../actions/homeSession";
import { deleteGoalTimeList } from "../../actions/goalTimeList";
import { getUser } from "../../actions/currentUser";
import { useRef } from "react";
import Footer from "../Footer/Footer";

function SessionSection() {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    dispatch(getUser({ is_home: "1" }));
    async function setlanguage() {
      const geti18value = await Getlanguage(
        parseInt(
          currentUser.default_language ? currentUser.default_language : 1
        )
      );
      i18n.changeLanguage(geti18value);
    }
    setlanguage();
  }, []);
  const dispatch = useDispatch();
  const token = useSelector((st) => st.token);
  const [serverErrorMessage, setServerErrorMessage] = useState("");
  const [isChecked, setIsChecked] = useState(true);
  const { expId } = useParams(); // exercise id is used for show the new duplicate exercise
  //current this state not use ,it has been made for future purpose
  const [isDefaultSetting, setIsDefaultSetting] = useState("");
  const [tutorialList, setTutorialList] = useState("");
  const [loading, setLoading] = useState(false);
  const [goalData, setGoalData] = useState("");
  const [getTimeData, setTimeData] = useState("");
  const [getNarrationData, setNarrationData] = useState(false);
  const [getThemeData, setThemeData] = useState(false);
  const [goalId, setGoalId] = useState("");
  const [timeId, setTimeId] = useState("");
  const [narrationId, setNarrationId] = useState("");
  const [themeId, setThemeId] = useState("");
  const [load, setLoad] = useState(false);
  const [show, setshow] = useState(false);
  const [getShowHideNarrationData, setShowHideNarrationData] = useState("");
  const [getShowHideThemeData, setShowHideThemeData] = useState("");
  const [buttondisable, setbuttondisable] = useState(true);
  const [isPlaying, setIsPlaying] = useState(null);
  const [defaultLists, setDefaultLists] = useState("");
  const [minuteValue, setMinuteValue] = useState("");
  const [emptyErr, setEmptyErr] = useState("");
  const currentUser = useSelector((st) => st.currentUser);
  const defaultListStore = useSelector((st) => st.defaultList);
  const newDuplicateList = useSelector((st) => st.newDefaultList);
  const videoRef = useRef();
  const [indexes, setIndex] = useState(0);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  // logout remove redux store data of user default and new default list data
  const signOut = () => {
    dispatch(logout());
    dispatch(deleteToken());
    dispatch(clearErr());
    dispatch(deleteExcerciseList());
    dispatch(deleteExperience());
    dispatch(deleteGender());
    dispatch(deleteLanguage());
    dispatch(deleteAge());
    dispatch(deletTimeNarration());
    dispatch(removeTempData());
    dispatch(deleteNarrationTheme());
    dispatch(deleteDefault());
    dispatch(deleteGoalTimeList());
    dispatch(deleteNewDefaultList());
    dispatch(deleteDefaultList());
    history.push("/login");
  };

  useEffect(async () => {
    setServerErrorMessage("");
  }, []);
  const history = useHistory();
  useEffect(async () => {
    await setGoalData("");
    await setTimeData("");
    await setNarrationData("");
    await setThemeData("");
    async function fetchSessionAndDefaultData() {
      setDefaultLists("");
      let sessionData = {};
      let { data } = await SublyApi.setExcersiseDefault(
        token,
        currentUser.device_id,
        currentUser.device_timezone
      );
      // check the api response called the signout user session expire
      if (
        data &&
        data.error_type === "USER_NOT_FOUND" &&
        data.status_code === 400
      ) {
        signOut();
      } else {
        sessionData = await data;
      }
      if (Object.keys(defaultListStore).length <= 0) {
        if (
          currentUser &&
          currentUser.userID &&
          newDuplicateList &&
          newDuplicateList[0] &&
          expId == newDuplicateList[0].default.experience_meta_id
        ) {
          setDefaultLists(newDuplicateList[0].default);
          setThemeId(1); //as per the client discussion theme set to dark for all
          if (
            sessionData &&
            sessionData.time &&
            sessionData.time.length > 0 &&
            sessionData.time.some((el) => el.minute == -1)
          ) {
            if (newDuplicateList[0].default.minute == -1) {
              setMinuteValue(-1);
              setNarrationId(true)
            }
          }
          GoalHandler(
            newDuplicateList[0].default.goal_id,
            true,
            newDuplicateList[0].default.minute
          );
        } else {
          if (sessionData &&
            sessionData.time &&
            sessionData.time.length > 0) {
            setDefaultLists(sessionData.default);
            setThemeId(1); //as per the client discussion theme set to dark for all
            if (
              sessionData.goal &&
              sessionData.goal.length > 0 &&
              sessionData.goal.some(
                (el) => el.goal_id == sessionData.default.goal_id
              )
            ) {
              setGoalId(sessionData.default.goal_id);
              setTimeId(sessionData.default.duration_minute_id);
              setNarrationId(sessionData.default.narration_id);
              setThemeId(sessionData.default.theme_id);
            }
            if (
              sessionData.goal &&
              sessionData.time.length > 0 &&
              sessionData.time.some(
                (el) => el.time_id == sessionData.default.duration_minute_id
              )
            ) {
              if (sessionData.time.duration_minute_id == 1) {
                setNarrationId(true)
              }
              setTimeId(sessionData.default.duration_minute_id);
            }
            if (sessionData.time.duration_minute_id == 1) {
              setNarrationId(true)
            } else {
              if (
                sessionData.goal &&
                sessionData.narration.length > 0 &&
                sessionData.narration.some(
                  (el) => el.narration_id == sessionData.default.narration_id
                )
              ) {
                setNarrationId(sessionData.default.narration_id);
              }
            }

            setThemeId(1);
          }
        }
      } else {
        if (
          currentUser &&
          currentUser.userID &&
          newDuplicateList &&
          newDuplicateList[0] &&
          expId == newDuplicateList[0].default.experience_meta_id
        ) {
          setThemeId(1); //as per the client discussion theme set to dark for all
          setDefaultLists(newDuplicateList[0].default);
          if (
            sessionData &&
            sessionData &&
            sessionData.time.length > 0 &&
            sessionData.time.some((el) => el.minute == -1)
          ) {
            if (newDuplicateList[0].default.minute == -1) {
              setMinuteValue(-1);
              setNarrationId(true)
            }
          }
          GoalHandler(
            newDuplicateList[0].default.goal_id,
            true,
            newDuplicateList[0].default.minute
          );
        } else {
          await setThemeId(1); //as per the client discussion theme set to dark for all
          setDefaultLists(defaultListStore.default);
          if (
            sessionData &&
            sessionData.time &&
            sessionData.time.length > 0 &&
            sessionData.time.some((el) => el.minute == -1)
          ) {
            if (defaultListStore && defaultListStore.default.minute == -1) {
              setMinuteValue(-1);
              setNarrationId(true)
            }
          }
          defaultListStore &&
            GoalHandler(
              defaultListStore.default.goal_id,
              true,
              defaultListStore.default.minute
            );
        }
      }
      await setGoalData(sessionData.goal);
      await setTimeData(sessionData.time);
      await setNarrationData(sessionData.narration);
      await setThemeData(sessionData.theme);
      await setThemeId(1); //as per the client discussion theme set to dark for all
    }
    fetchSessionAndDefaultData();
  }, []);
  //-----function for handling goal and getting time data-----

  async function GoalHandler(goal_id, isDefault = false, timeValue) {
    await setGoalId(goal_id);
    if (isDefault == true) {
      await setIsChecked(true);
    } else {
      await setIsChecked(false);
    }
    const time = await SublyApi.searchGoalTime(
      goal_id,
      isDefaultSetting,
      token,
      currentUser.device_id,
      currentUser.device_timezone
    );
    if (
      time &&
      time.data.error_type === "USER_NOT_FOUND" &&
      time.data.status_code === 400
    ) {
      signOut();
    }
    await setTimeData("");
    await setNarrationData("");
    await setThemeData("");
    await setShowHideNarrationData("hideDiv");
    await setShowHideThemeData("hideDiv");
    await setTimeData(time ? time.data.duration_list : "");
    if (isDefault) {
      if (
        currentUser &&
        currentUser.userID &&
        newDuplicateList &&
        newDuplicateList[0] &&
        expId == newDuplicateList[0].default.experience_meta_id
      ) {
        if (
          time &&
          time.data &&
          time.data.duration_list.length > 0 &&
          time.data.duration_list.some((el) => el.duration_minute_id
            == newDuplicateList[0].default.duration_minute_id)
        ) {
          await TimeHandler(
            newDuplicateList[0].default.duration_minute_id,
            true,
            goal_id,
            timeValue,
            isDefault
          );
        } else {
          TimeHandler()
        }

      } else if (Object.keys(defaultListStore).length > 0) {
        if (
          time &&
          time.data &&
          time.data.duration_list.length > 0 &&
          time.data.duration_list.some((el) => el.duration_minute_id
            == defaultListStore.default.duration_minute_id)
        ) {
          await TimeHandler(
            defaultListStore.default.duration_minute_id,
            true,
            goal_id,
            timeValue,
            isDefault
          );
        } else {
          TimeHandler()
        }

      } else if (
        time &&
        time.data.duration_list &&
        time.data.duration_list.length == 1
      ) {
        TimeHandler(
          time.data.duration_list[0].duration_minute_id,
          true,
          goal_id,
          time.data.duration_list[0].minute,
          isDefault
        );
      }
    } else if (
      time &&
      time.data.duration_list &&
      time.data.duration_list.length == 1
    ) {
      TimeHandler(
        time.data.duration_list[0].duration_minute_id,
        true,
        goal_id,
        time.data.duration_list[0].minute,
        isDefault
      );
    }
  }

  async function TimeHandler(
    time_id,
    isTimeDefault = false,
    goal_id,
    timeValue,
    isGoalDefault
  ) {
    if (isTimeDefault == true) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
    setTimeId("");
    setMinuteValue(timeValue);
    await setTimeId(time_id);
    await setNarrationData("");
    await setThemeData("");
    await setShowHideNarrationData("");
    await setShowHideThemeData("hideDiv");
    let timeNarration = {};
    if (isTimeDefault) {
      timeNarration = await SublyApi.searchTimeNarration(
        time_id,
        goal_id,
        isDefaultSetting,
        token,
        timeValue,
        currentUser.device_id,
        currentUser.device_timezone
      );
    } else {
      timeNarration = await SublyApi.searchTimeNarration(
        time_id,
        goalId,
        isDefaultSetting,
        token,
        timeValue,
        currentUser.device_id,
        currentUser.device_timezone
      );
    }
    if (isTimeDefault == false) {
      goal_id = goalId;
    }
    if (
      timeNarration &&
      timeNarration.data.error_type === "USER_NOT_FOUND" &&
      timeNarration.data.status_code === 400
    ) {
      signOut();
    } else {
      await setNarrationData(
        timeNarration ? timeNarration.data.narration_list : ""
      );
    }
    if (isTimeDefault == true && isGoalDefault == true) {
      if (
        currentUser &&
        currentUser.userID &&
        newDuplicateList &&
        newDuplicateList[0] &&
        expId == newDuplicateList[0].default.experience_meta_id
      ) {
        if (
          timeNarration &&
          timeNarration.data &&
          timeNarration.data.narration_list.length > 0 &&
          timeNarration.data.narration_list.some((el) => el.narrationID
            == newDuplicateList[0].default.narration_id)
        ) {
          await NarrationHandler(
            newDuplicateList[0].default.narration_id,
            true,
            goal_id,
            time_id,
            isTimeDefault,
            timeValue
          );
        } else if (
          timeNarration &&
          timeNarration.data &&
          timeNarration.data.narration_list.length == 0 &&
          timeValue == -1
        ) {
          setNarrationId(true);
        }

      } else if (Object.keys(defaultListStore).length > 0) {
        if (
          timeNarration &&
          timeNarration.data &&
          timeNarration.data.narration_list.length > 0 &&
          timeNarration.data.narration_list.some((el) => el.narrationID
            == defaultListStore.default.narration_id)
        ) {
          await NarrationHandler(
            defaultListStore.default.narration_id,
            true,
            goal_id,
            time_id,
            isTimeDefault,
            timeValue
          );
        } else if (
          timeNarration &&
          timeNarration.data &&
          timeNarration.data.narration_list.length == 0 &&
          timeValue == -1
        ) {
          setNarrationId(true);
        }

      } else if (
        timeNarration &&
        timeNarration.data.narration_list &&
        timeNarration.data.narration_list.length == 1
      ) {
        NarrationHandler(
          timeNarration.data.narration_list[0].narrationID,
          true,
          goal_id,
          time_id,
          true,
          timeValue
        );
      } else if (
        timeNarration &&
        timeNarration.data.narration_list &&
        (timeNarration.data.narration_list.length < 1 ||
          timeNarration.data.narration_list.length == 0)
      ) {
        NarrationHandler(
          null,
          true,
          goal_id,
          time_id,
          isTimeDefault,
          timeValue
        );
      } else if (
        timeNarration &&
        timeNarration.data.narration_list &&
        timeNarration.data.narration_list.length > 1
      ) {
      }
    } else if (
      timeNarration &&
      timeNarration.data.narration_list &&
      timeNarration.data.narration_list.length == 1
    ) {
      NarrationHandler(
        timeNarration.data.narration_list[0].narrationID,
        true,
        goal_id,
        time_id,
        isTimeDefault,
        timeValue
      );
    } else if (
      timeNarration &&
      timeNarration.data.narration_list &&
      (timeNarration.data.narration_list.length < 1 ||
        timeNarration.data.narration_list.length == 0)
    ) {
      NarrationHandler(null, true, goal_id, time_id, isTimeDefault, timeValue);
    } else if (
      timeNarration &&
      timeNarration.data.narration_list &&
      timeNarration.data.narration_list.length > 1
    ) {
    }
  }

  async function NarrationHandler(
    narration_id,
    isNarrDefault,
    goal_id,
    time_id,
    isTimeDefault,
    timeValue
  ) {
    if (minuteValue == -1 || timeValue == -1) {
      setNarrationId(true);
    } else {
      setNarrationId(narration_id);
    }
    // setThemeData("");
    setShowHideThemeData("");
    if (isTimeDefault == true && isNarrDefault === true) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
    let narrationTheme = {};
  }


  function getValue(inputData) {
    let input_id = "";
    for (var i = 0, length = inputData.length; i < length; i++) {
      if (inputData[i].checked) {
        // do whatever you want with the checked radio
        input_id = inputData[i].value;
        return input_id;
        // only one radio can be logically checked, don't check the rest
        break;
      }
    }
  }
  const getExcercisess = async (e) => {
    const online = await getinternet();
    if (online === false) {
      Toast.fire({
        icon: "error",
        title: t("noiternet"),
      });
    } else {
      setbuttondisable(false);
      var goal = document.getElementsByName("goal");
      var time = document.getElementsByName("time");
      var narration = document.getElementsByName("narration");
      var theme = document.getElementsByName("theme");
      let goal_id = getValue(goal);
      let time_id = getValue(time);
      let narration_id = getValue(narration);
      let theme_id = getValue(theme);
      //current this variable not use ,it has been made for future purpose
      let defaultsettingvalue = await isDefaultSetting;
      SublyApi.getExcercise(
        "",
        goal_id,
        time_id,
        narration_id,
        isDefaultSetting,
        token,
        currentUser.device_id,
        currentUser.device_timezone
      )
        .then(async (responsejson) => {
          if (
            responsejson &&
            responsejson.data.error_type == "USER_NOT_FOUND" &&
            responsejson.data.status_code === 400
          ) {
            setbuttondisable(true);
            signOut();
          } else {
            if (responsejson.data.status_code === 400) {
              setbuttondisable(true);
              setServerErrorMessage(responsejson.data.message);
            } else {
              if (responsejson.status_code === 200) {
                dispatch(deleteExcerciseList());
                setbuttondisable(true);
                setServerErrorMessage(responsejson.message);
                dispatch(getExcercise(responsejson.data.exercise));
                if (responsejson.data.exercise && responsejson.data.exercise.length > 0) {
                  defaultListStore.default = await {
                    id: "1",
                    goal_id: goal_id,
                    age_group_id: "1",
                    gender_id: "1",
                    is_default: "1",
                    duration_minute_id: time_id,
                    narration_id: narration_id,
                    theme_id: theme_id,
                    minute: minuteValue,
                  };
                  await dispatch(setDefaultData(defaultListStore));
                  setGoalId("");
                  setTimeId("");
                  setNarrationId("");
                  //as per the client discussion theme set to dark for all
                }
                responsejson.data.exercise && responsejson.data.exercise.length > 0 ?
                  history.push("/Home/before-rating-section")
                  : history.push("/Home");
              }
            }
          }
        })
        .catch((error) => { });
    }
  };

  useEffect(() => {
    async function gettutorial() {
      setLoading(true);
      const infoItem = await SublyApi.settingTutorial(
        token,
        currentUser,
        currentUser.device_id,
        currentUser.device_timezone
      );
      if (
        infoItem &&
        infoItem.data &&
        infoItem.data.error_type == "USER_NOT_FOUND" &&
        infoItem.data.status_code === 400
      ) {
        setLoading(false);
        signOut();
      } else {
        if (
          infoItem &&
          infoItem.data &&
          infoItem.data.status_code === 400) {
          setLoading(false);
        } else {
          if (infoItem.status_code === 200) {
            setLoading(false);
            setTutorialList(infoItem.data);
          }
        }
      }

    }
    gettutorial();
  }, []);

  return (
    <>
      <section className="SessionBody">
        <Nav />
        <div className="historyflexbody">
          <div className="historypopup">
            <div className="SessionContainer " style={{ paddingTop: "20px" }}>
              <button
                className={
                  show == true ? "session_tutorial" : "session_tutorials"
                }
                onClick={() => setshow(true)}
              >
                {t("tutorail")}
              </button>
              {load ? (
                <Loader />
              ) : (
                <div className="SessionContain">
                  <div className="SessionTitle">
                    <h3>{t("SESSION_TITLE")}</h3>
                    <p>{t("SESSION_DETAILS_T")}</p>
                  </div>
                  <div className="SessionType">
                    <form>
                      <div className="GoalSession">
                        <h4>{t("SELECTGOAL_T")}</h4>

                        <div className="GoalOptions">
                          {goalData && goalData.length !== 0 ? (
                            goalData.map((item, index) => (
                              <label key={index} htmlFor={"goal" + index}>
                                <input
                                  id={"goal" + index}
                                  defaultChecked={
                                    isChecked == true
                                      ? goalData.length === 1
                                        ? item.goal_id === defaultLists.goal_id
                                          ? true
                                          : false
                                        : item.goal_id === defaultLists.goal_id
                                          ? true
                                          : false
                                      : goalData.length === 1
                                        ? true
                                        : false
                                  }
                                  type="radio"
                                  onChange={() => {
                                    GoalHandler(item.goal_id);
                                    setServerErrorMessage("");
                                    setEmptyErr("");
                                    setGoalId(item.goal_id);
                                    setTimeId("");
                                    setNarrationId("");
                                    setTimeData("");
                                    setNarrationData("");
                                    //as per the client discussion theme set to dark for all
                                  }}
                                  name="goal"
                                  value={item.goal_id}
                                />
                                <span>{item.title}</span>
                              </label>
                            ))
                          ) : (
                            <p className="defaultdata">{t("na")}</p>
                          )}
                        </div>
                      </div>
                      <div className="TimeSession">
                        <h4>{t("SELECTTIME_T")}</h4>
                        <div className="TimeOptions">
                          {getTimeData && getTimeData.length !== 0 ? (
                            getTimeData.map((item, index) => (
                              <label key={index} htmlFor={"time" + index}>
                                <input
                                  id={"time" + index}
                                  idlength={getTimeData.length}
                                  defaultChecked={
                                    isChecked == true
                                      ? getTimeData.length === 1
                                        ? item.duration_minute_id ===
                                          defaultLists.duration_minute_id
                                          ? true
                                          : false
                                        : item.duration_minute_id ===
                                          defaultLists.duration_minute_id
                                          ? true
                                          : false
                                      : getTimeData.length === 1
                                        ? true
                                        : false
                                  }
                                  type="radio"
                                  onChange={() => {
                                    TimeHandler(
                                      item.duration_minute_id,
                                      false,
                                      goalId && goalId,
                                      item.minute,
                                      true
                                    );
                                    setTimeId(item.duration_minute_id);
                                    setMinuteValue(item.minute);
                                    setServerErrorMessage("");
                                    setEmptyErr("");
                                    setNarrationId("");
                                    setNarrationData("");
                                    //as per the client discussion theme set to dark for all
                                  }}
                                  name="time"
                                  value={item.duration_minute_id}
                                />
                                <span>
                                  {item.minute == -1 ? "infinity" : item.minute}
                                </span>
                              </label>
                            ))
                          ) : (
                            <p className="defaultdata">{t("na")}</p>
                          )}
                        </div>
                      </div>
                      <div
                        className={`NarrationSession ${getShowHideNarrationData}`}
                      >
                        <h4>{t("SELECTNARRATION_T")}</h4>
                        <div className="NarrationOptions">
                          {getNarrationData && getNarrationData.length !== 0 ? (
                            getNarrationData.map((item, index) => (
                              <label key={index} htmlFor={"narr" + index} >
                                <input
                                  id={"narr" + index}
                                  id_n_length={getNarrationData.length}
                                  defaultChecked={
                                    isChecked == true
                                      ? getNarrationData.length === 1
                                        ? true
                                        : item.narrationID ===
                                          defaultLists.narration_id
                                          ? true
                                          : false
                                      : getNarrationData.length === 1
                                        ? true
                                        : false
                                  }
                                  type="radio"
                                  onChange={() => {
                                    NarrationHandler(item.narrationID, false);
                                    setNarrationId(item.narrationID);
                                    setServerErrorMessage("");
                                    setEmptyErr("");
                                    //as per the client discussion theme set to dark for all
                                  }}
                                  value={
                                    item.narrationID ? item.narrationID : null
                                  }
                                  name="narration"
                                />
                                <span>
                                  {item.title ? item.title : "No narration"}
                                </span>
                              </label>
                            ))
                            ) : (
                              <React.Fragment>
                                {minuteValue === -1 ? (
                                  <label>
                                    <input
                                      type="radio"
                                      defaultChecked={true}
                                      value={null}
                                      name="narration"
                                    />
                                    <span>No narration</span>
                                  </label>
                                ) : (
                                  <p className="defaultdata">{t("na")}</p>
                                )}
                              </React.Fragment>
                            )}
                        </div>
                      </div>
                      <p className="errorMessageColor">
                        {emptyErr ? emptyErr : serverErrorMessage}
                      </p>
                      <div
                        className="SessionBtn"
                        style={
                          buttondisable == false
                            ? { pointerEvents: "none" }
                            : {}
                        }
                      >
                        <button
                          type="button"
                          onClick={() => {
                            if (buttondisable == true) {
                              if (narrationId && timeId) {
                                getExcercisess();
                              } else {
                                setEmptyErr(t("empty_err"));
                              }
                            }
                          }}
                          className="btn btn-primary"
                        >
                          {t("START")}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </section>
      <Modal
        show={show}
        onHide={() => setshow(false)}
        className="sessiontutorials"
      >
        <Modal.Header>
          <div></div>
          <div>
            <ImCross color="white" onClick={() => setshow(false)} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <section>
            <div className="new_turo_wrappers">
              <div className="new_tur_innerbodys">
                <Carousel
                  activeIndex={indexes}
                  onSelect={handleSelect}
                  showArrows={false}
                  interval={null}
                  className="carousel_tutorial"
                  keyboard={false}
                  controls={false}
                >
                  {tutorialList
                    ? tutorialList.tutorial_video_list.map((item, index) => {
                      return (
                        <Carousel.Item
                          style={{ margiTop: "51px" }}
                          data-keyboard="false"
                          key={item.tutorialVideoID}
                        >
                          <ReactPlayer
                            playsinline
                            key={indexes}
                            config={{
                              file: {
                                attributes: { controlsList: "nodownload" },
                              },
                            }}
                            url={item.video_url}
                            width="100%"
                            className="reactvideo "
                            borderRadius="12px"
                            height="259px"
                            muted={false}
                            loop={false}
                            stopOnUnmount={true}
                            controls={true}
                            playing={index === indexes ? true : false}
                          />
                        </Carousel.Item>
                      );
                    })
                    : ""}
                </Carousel>
              </div>
            </div>
          </section>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SessionSection;