import { showErr, clearErr } from "./errors";
import {
    EXPERIENCE_LIST, REMOVE_EXPERIENCE_LIST
} from "./types";


// save meditation value in redux
export function getExperienceValue(experiencevalue) {
    return async function (dispatch) {
        try {
            dispatch(clearErr());
            dispatch(getExperience(experiencevalue));
        } catch (err) {
            dispatch(showErr(err));
        }
    };
};

function getExperience(defaultData) {
    return {
        type: EXPERIENCE_LIST,
        defaultData,
    };
}




export function deleteExperience(defaultData) {
    return {
        type: REMOVE_EXPERIENCE_LIST,
        defaultData,
    };
}