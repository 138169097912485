import { responsivePropType } from "react-bootstrap/esm/createUtilityClasses";
import SublyApi from "../helpers/Api";
import { showErr, clearErr } from "./errors";
import {NARRATION_THEME_LIST,REMOVE_NARRATION_THEME_LIST
} from "./types";

export function searchNarrationTheme(narrationId,goalid,timeID,token) {
  return async function (dispatch) {
      try {
        dispatch(clearErr());
        const defaultData = await SublyApi.searchNarrationTheme(narrationId,goalid,timeID,token);
         dispatch(getNarrationThemeList(defaultData.data));
         
       } catch (err) {
        dispatch(showErr(err));
      }
    };
  }


function getNarrationThemeList(defaultData) {
  return {
    type: NARRATION_THEME_LIST,
    defaultData,
  };
}
 
 
export function deleteNarrationTheme(defaultData) {
  return {
    type: REMOVE_NARRATION_THEME_LIST,
    defaultData,
  };
}

 