import React, { useEffect, useState } from "react";
import "./MyData.css";
import Nav from "../../Header/header";
import { Icon } from "@iconify/react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Toast } from "../../../commonFile";
import { useForm } from "react-hook-form";
import { confirm } from "react-confirm-box";
import SublyApi from "../../../.../../helpers/Api";
import { getinternet } from "../../../commonfunction";
import { CSVLink } from "react-csv";
import { Getlanguage } from "../../../commonfunction";
import { useTranslation } from "react-i18next";
import { deleteToken } from "../../../actions/currentUser";
import { logout } from "../../../actions/currentUser";
import { deleteDefaultList, deleteNewDefaultList } from "../../../actions/defaultList";
import { clearErr } from "../../../actions/errors";
import { deleteExcerciseList } from "../../../actions/getExcercise";
import { deleteExperience } from "../../../actions/getexperiense";
import { deleteGender } from "../../../actions/getgender";
import { deleteLanguage } from "../../../actions/getlanguage";
import { deleteAge } from "../../../actions/getsurvey";
import { deletTimeNarration } from "../../../actions/timeNarrationList";
import { removeTempData } from "../../../actions/tempData";
import { deleteNarrationTheme } from "../../../actions/narrationTheme";
import { deleteDefault } from "../../../actions/homeSession";
import { deleteGoalTimeList } from "../../../actions/goalTimeList";
function MyData() {
  const [historyDataList, setFormData] = useState(null);
  const [deleteBox, setDeleteBox] = useState(false);
  const [getdata, setdatavalue] = useState();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const token = useSelector((st) => st.token);
  const currentUser = useSelector((st) => st.currentUser);
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    async function setlanguage() {
      const geti18value = await Getlanguage(parseInt(currentUser.default_language ? currentUser.default_language : 1));
      i18n.changeLanguage(geti18value);
    }
    setlanguage();
  }, []);

  // for Delete Confirmation Box
  const handle = () => {
    deleteBox == false ? setDeleteBox(true) : setDeleteBox(false);
  };

  function logouts() {
    dispatch(logout());
    dispatch(deleteToken());
    dispatch(deleteDefaultList());
    dispatch(deleteNewDefaultList())
    dispatch(clearErr());
    dispatch(deleteExcerciseList());
    dispatch(deleteExperience());
    dispatch(deleteGender());
    dispatch(deleteLanguage());
    dispatch(deleteAge());
    dispatch(deletTimeNarration());
    dispatch(removeTempData());
    dispatch(deleteNarrationTheme());
    dispatch(deleteDefault());
    dispatch(deleteGoalTimeList());
    history.push("/login");
  };
  //delete account api
  async function OnSubmit(formData) {
    var formdata = new FormData();
    formdata.append("password", formData.confirmPassword);
    await SublyApi.accountDelete(token, formdata, currentUser.device_id, currentUser.device_timezone)
      .then((responsejson) => {
        if (responsejson &&
          responsejson.data.error_type == "USER_NOT_FOUND" &&
          responsejson.data.status_code === 400) {
          logouts();
        }
        else {
          if (responsejson.data.status_code === 400) {
            Toast.fire({
              icon: "error",
              title: responsejson.data.message,
            });
          } else {
            if (responsejson.status_code === 200){
              Toast.fire({
                icon: "success",
                title: t("accountdelsuc"),
              });
              logouts();
            }
          }
        }
      })
      .catch(() => { });
  }

  // ------API for clear history------

  const clearHistory = async () => {
    const online = await getinternet();
    if (online === false) {
      Toast.fire({
        icon: "error",
        title: t("noiternet"),
      });
    }
    else {
      const result = await confirm(t("removeal"));
      if (result) {
        await SublyApi.removeAllHistory(token, currentUser.device_id, currentUser.device_timezone)
          .then((responsejson) => {
            if (responsejson &&
              responsejson.data.error_type == "USER_NOT_FOUND" &&
              responsejson.data.status_code === 400) {
              logouts();
            }
            else {
              if (responsejson.data.status_code === 400) {
                Toast.fire({
                  icon: "error",
                  title: responsejson.data.message,
                });
              } else {
                if (responsejson.status_code === 200) {
                  Toast.fire({
                    icon: "success",
                    title: responsejson.message
                      ? responsejson.message
                      : t("exerciseclear"),
                  });
                  history.push("/Setting");
                }

              }
            }
          })
          .catch(() => { });
      }
    }
  };

  // it's api work take history data by useEffect hook .
  useEffect(() => {
    async function getTodos() {
      const historyData = await SublyApi.exerciseHistory(token, currentUser.device_id, currentUser.device_timezone);
      if (
        historyData &&
        historyData.data.error_type === "USER_NOT_FOUND" &&
        historyData.data.status_code === 400
      ) {
        logouts();
      }
      else {
        if (
          historyData &&
          historyData.data.status_code === 400
        ) {
        }
        else{
          if (historyData.status_code === 200) {
            setFormData(historyData ? historyData.data.data_found : "");
          }
        }
      }
    }

    getTodos(historyDataList);
  }, []);

  async function downloadfile() {
    const online = await getinternet();
    if (online === false) {
      Toast.fire({
        icon: "error",
        title: t("noiternet"),
      });
    }
    else {
      const datadownload = await SublyApi.downloaddata(token, currentUser.device_id, currentUser.device_timezone);
      if (
        datadownload &&
        datadownload.data.error_type === "USER_NOT_FOUND" &&
        datadownload.data.status_code === 400
      ) {
        logouts();
      }
      else {
        if (datadownload.status_code === 200) {
          setdatavalue(datadownload.data.exportData ? datadownload.data.exportData : "")
        }
      }
    }
  }



  return (
    <>
      <section>
        <Nav></Nav>
        <div className="myData_Container">
          <div className="myData_Body">
            <div className="MyData_Header">
              <div className="MyDataBack_Icon">
                <Icon
                  icon="eva:arrow-ios-back-fill"
                  color="white"
                  width="24"
                  cursor="pointer"
                  height="24"
                  onClick={() => {
                    history.push("/setting");
                  }}
                />
              </div>
              <div className="MyData_Title">
                <h5>{t("MYDATA")}</h5>
              </div>
            </div>
            <div className="MyData_Manage">
              <p className="DataClear">{t("CLEARDATA")}</p>
              <p className="clearAlert">
                {t("MYDATACLEAR")}
              </p>
              {historyDataList ? (
                <div
                  className={`${"DataClear_Btn"}`}
                  onClick={historyDataList && clearHistory}
                >
                  <button style={{ color: "black" }}>{t("CLEARNOW")}</button>
                </div>
              ) : <div
                className={`${"DataClear_Btn makeDisable"}`}
                onClick={historyDataList && clearHistory}
              >
                <button style={{ color: "black" }}>{t("CLEARNOW")}</button>
              </div>}
              <div className="bottum_border"></div>
              <p className="DataClear">{t("DELETEYOURACC")}</p>
              <p className="clearAlert">
                {t("PARMANENTDELETE")}
              </p>
              <div className="DeleteAccount_Btn" onClick={handle}>
                <button>{t("DELETE")}</button>
              </div>
              <div className="bottum_border"></div>
              <p className="download_Data" onClick={downloadfile}>
                <CSVLink data={getdata ? getdata : ""} ><span>{t("DOWNLOADDATA")} </span> </CSVLink>{t("OFYOURAPP")}
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* Delete Confirmation Box Area */}

      <div className={deleteBox ? "care2" : "care"}>
        <div className="ConfirmationBody">
          <div className="ConfirmContainer">
            <div className="innerContent">
              <h4> {t("deletaccount")}</h4>
              <form autoComplete="off" onSubmit={handleSubmit(OnSubmit)}>
                {currentUser.social_accounts.socialAccountID ? (
                  ""
                ) : (
                  <div className="inputArea">
                    <label>    {t("enterpassword")}</label>
                    <div className="inputField">
                      <input
                        className="inputfieldauto"
                        type="password"
                        placeholder="***"
                        id="confirmPassword"
                        name="confirmPassword"
                        {...register("confirmPassword", {
                          required: t("passwordrew"),
                        })}
                      />
                    </div>
                    {errors.confirmPassword && (
                      <span className="errorMessageColor">
                        {errors.confirmPassword.message}
                      </span>
                    )}
                  </div>
                )}
                <div className="DelConfirmBtn">
                  <button
                    className="btn btn-primary cancel"
                    onClick={() => {
                      handle();
                      setValue("confirmPassword", "");
                      (errors.confirmPassword = "");
                    }}
                    type="button"
                  >
                    {t("CANCEL")}
                  </button>
                  <button className="btn btn-primary done" type="submit">
                    {t("Done")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MyData;