import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import "./Setting.css";
import Nav from "../Header/header";
import { Icon } from "@iconify/react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import React from "react";
import Form from "react-bootstrap/Form";
import SublyApi from "../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { set, useForm } from "react-hook-form";
import { Toast } from "../../commonFile";
import loaders from "../../Assets/Images/loader.gif";
import { Loader } from "../../commonFile";
import { Getlanguage } from "../../commonfunction";
import { useTranslation } from "react-i18next";
import { getinternet } from "../../commonfunction";
import { Removeall } from "../../commonfunction";
import { deleteToken } from "../../actions/currentUser";
import { logout } from "../../actions/currentUser";
import { deleteDefaultList, deleteNewDefaultList, setDefaultData } from "../../actions/defaultList";
import { clearErr } from "../../actions/errors";
import { deleteExcerciseList } from "../../actions/getExcercise";
import { deleteExperience } from "../../actions/getexperiense";
import { deleteGender } from "../../actions/getgender";
import { deleteLanguage } from "../../actions/getlanguage";
import { deleteAge } from "../../actions/getsurvey";
import { deletTimeNarration } from "../../actions/timeNarrationList";
import { removeTempData } from "../../actions/tempData";
import { deleteNarrationTheme } from "../../actions/narrationTheme";
import { deleteDefault } from "../../actions/homeSession";
import { deleteGoalTimeList } from "../../actions/goalTimeList";
import "../BasicCalibration/BasicCalibration.css";
import { useRef } from "react";
import ReactPlayer from "react-player";
import headerbrithlogo from "../../Assets/Images/Logo1.svg";
import calibrarionVideoSpeeds from "../calibrarionVideoSpeeds";

const videos = calibrarionVideoSpeeds;

const EditAccountSetting = () => {
  const [value, setValues] = useState();
  const [userInfoList, setUserInfoList] = useState();
  const [fullPhone, setFullPhone] = useState("");
  const [recommendedSpeed, setRecommendedSpeed] = useState()
  const [recommendedOption, setRecommendedOption] = useState()
  const [ageValue, setAgeValue] = useState()
  const [phoneNo, setPhoneNo] = useState("");
  const [dialCode, setDialCode] = useState("");
  const [load, setLoad] = useState(false);
  const currentUser = useSelector((st) => st.currentUser);
  const { t, i18n } = useTranslation();
  const token = useSelector((st) => st.token);
  const dispatch = useDispatch();
  const [newpopup, setnewpopup] = useState(false);
  const [selectedSpeed, setSelectedSpeed] = useState("");
  const videoRef = useRef(null);
  const handleContextMenu = (e) => {
    e.preventDefault(); // Prevent the default right-click context menu
  };
  const history = useHistory();
  const [getAllVideo] = useState(videos);
  const [speed, setSpeed] = useState();
  const [calibratedSpeed, setcalibratedSpeed] = useState();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    async function setlanguage() {
      const geti18value = await Getlanguage(parseInt(currentUser.default_language ? currentUser.default_language : 1));
      i18n.changeLanguage(geti18value);
    }
    setlanguage();
  }, []);
  
  // ----logOut while token expired----

  const signOut = () => {
    dispatch(logout());
    dispatch(deleteToken());
    dispatch(deleteDefaultList());
    dispatch(clearErr());
    dispatch(deleteExcerciseList());
    dispatch(deleteExperience());
    dispatch(deleteGender());
    dispatch(deleteLanguage());
    dispatch(deleteAge());
    dispatch(deletTimeNarration());
    dispatch(removeTempData());
    dispatch(deleteNarrationTheme());
    dispatch(deleteDefault());
    dispatch(deleteGoalTimeList());
    dispatch(deleteNewDefaultList());
    dispatch(deleteDefaultList())
    history.push("/login");
  };

  useEffect(() => {
    // ----this one is for userInfo list like gender and age----

    async function getUserInfoList() {
      const usersInfoList = await SublyApi.accountDetails(token, currentUser.device_timezone);
      if (
        usersInfoList &&
        usersInfoList.data.error_type === "USER_NOT_FOUND" &&
        usersInfoList.data.status_code === 400
      ) {
        signOut();
      }
      else {
        if (
          usersInfoList.data.status_code == 400
        ) {

        }
        else {
          if (usersInfoList &&
            usersInfoList.status_code === 200) {
            setUserInfoList(usersInfoList.data);
            const forChildData = usersInfoList.data.average_speed_data.filter(item => item.group_type == 1);
            const forOtherData = usersInfoList.data.average_speed_data.filter(item => item.group_type == 2);
            const forNothingData = usersInfoList.data.average_speed_data.filter(item => item.group_type == 0);
            setRecommendedSpeed([{ forChild: forChildData, forOther: forOtherData, forNothing: forNothingData }])
            // ----this one is for showing default value----
            const usersInfo = await SublyApi.userInformation(token, currentUser.device_id, currentUser.device_timezone);
            if (
              usersInfo &&
              usersInfo.data.error_type === "USER_NOT_FOUND" &&
              usersInfo.data.status_code === 400
            ) {
              signOut();
            }
            else {
              if (
                usersInfo &&
                usersInfo.data.status_code === 400
              ) {
              }
              else {
                if (usersInfo &&
                  usersInfo.status_code === 200) {
                  const fullNumber = usersInfo.data.user_info.phone_dial_code
                    ? usersInfo.data.user_info.phone_dial_code.toString() +
                    usersInfo.data.user_info.phone_number.toString()
                    : "";
                  setFullPhone(fullNumber);
                  setValues(usersInfo.data.user_info);
                  setValue("email", usersInfo.data.user_info.email);
                  setAgeValue(usersInfo.data.user_info.age_group)
                  // This work use for default set Recommended speed
                  if (usersInfo.data.user_info.age_group && usersInfo.data.user_info.age_group != 1) {
                    setRecommendedOption(forOtherData);
                    setValue('speed',(usersInfo.data.user_info.average_speed)?usersInfo.data.user_info.average_speed:5.0)
                  } else {
                      setRecommendedOption(forChildData);
                      setValue('speed',(usersInfo.data.user_info.average_speed)?usersInfo.data.user_info.average_speed:6.0)
                  }
                }
              }
            }
          }
        }
      }
    }
    getUserInfoList();
  }, []);


  // ----this one is for sending the data in API for updating user Information----

  const onSubmit = async (form) => {
    const online = await getinternet();
    if (online === false) {
      Toast.fire({
        icon: "error",
        title: t("noiternet"),
      });
    }
    else {
      setLoad(true);
      let requestData = new FormData();
      requestData.append("email", form.email);
      requestData.append("age_id", form.age_group ? form.age_group : userInfoList.age_data[0].id);
      requestData.append("gender_id", form.gender_id ? form.gender_id : userInfoList.gender_data[0].id);
      requestData.append(
        "experience_id",
        form.meditation ? form.meditation : userInfoList.experience_data[0].id
      );
      requestData.append(
        "recommended_speed", selectedSpeed.toString()
      );
      requestData.append(
        "phone_number",
        fullPhone.length < 1
          ? ""
          : phoneNo
            ? phoneNo
            : value.phone_number
              ? value.phone_number
              : ""
      );
      requestData.append(
        "phone_dial_code",
        dialCode ? dialCode : value.phone_dial_code ? value.phone_dial_code : ""
      );
      await SublyApi.updateUserInfo(token, requestData, currentUser.device_id, currentUser.device_timezone)
        .then(async (responsejson) => {
          if (responsejson &&
            responsejson.data.error_type == "USER_NOT_FOUND" &&
            responsejson.data.status_code === 400) {
            signOut();
          }
          if (responsejson.data.status_code === 400) {
            Toast.fire({
              icon: "error",
              title: responsejson.data.message,
            });
            setLoad(false);
          } else {
            if (responsejson.status_code === 200) {
              history.push("/Setting/account-setting");
              Toast.fire({
                icon: "success",
                title: responsejson.message
                  ? responsejson.message
                  : t("accountupdate"),
              });
            }
          }
        }
        )
        .catch((error) => { });
    }
  };
  //this function work on onchange time(setagevalue && default setrecommended speed).
  async function updatAgeValue(e) {
      if (e.target.value == 1) {
        await setRecommendedOption(recommendedSpeed[0].forChild);
        let getDefaultValue = recommendedSpeed[0].forChild.filter(item=> item.is_default==1);
        await setValue('speed',getDefaultValue[0].value)
      } else {
        if(ageValue==1){
          await  setRecommendedOption(recommendedSpeed[0].forOther);
          
        }
        let getDefaultValue = recommendedSpeed[0].forOther.filter(item=> item.is_default==1);
        await setValue('speed',getDefaultValue[0].value)
      }
      await setAgeValue(e.target.value)
  }

  useEffect(() => {
    if (calibratedSpeed) {
      setSelectedSpeed(calibratedSpeed);
    } else if (value && value.average_speed) {
      setSelectedSpeed(value.average_speed);
    }
  }, [calibratedSpeed, value]);

  function redirecthome() {
    history.push("/home");
  }

  useEffect(() => {
    async function getSpeed() {
      const speed = await SublyApi.GetUserSpeed(token, currentUser.userID);
      setSpeed(speed.data.speed);
    }
    getSpeed();
  }, []);

  useEffect(() => {
    async function setlanguage() {
      const geti18value = await Getlanguage(
        parseInt(currentUser.default_language ? currentUser.default_language : 1)
      );
      i18n.changeLanguage(geti18value);
    }
    setlanguage();
  }, []);

  const handleResponse = (response) => {
    setUserResponses((prevResponses) => [
      ...prevResponses,
      { videoId: videos[videoIndex].id, response },
    ]);
  };

  const saveResponse = async () => {
    const currentVideoTitle = getAllVideo[videoIndex].title;
    let requestData = new FormData();
    requestData.append("userID", currentUser.userID);
    requestData.append("recommended_speed", currentVideoTitle.toString());
    await SublyApi.SetUserSpeed(token, requestData, currentUser.device_id, currentUser.device_timezone)
    .then(async (responsejson) => {
      if (responsejson.status_code !== 200) {
        setValue('speed', currentVideoTitle.toString())
        const failTitle = t("failSetUserSpeed");
        Toast.fire({
          icon: "error",
          title: failTitle
        });
      } else {
          Toast.fire({
            icon: "success",
            title: responsejson.message
          });
      }
    }
    )
    const player = videoRef.current.getInternalPlayer();
    player.pause();
    setnewpopup(false)
    setcalibratedSpeed(currentVideoTitle)
  };

  const skipToEnd = () => {
    if (videoRef.current) {
      videoRef.current.seekTo(videoRef.current.getDuration(), "seconds");
    }
  };

  const playNextVideo = () => {
    if (videoRef.current) {
      videoRef.current.seekTo(0, "seconds"); // Set the current time to 0 to replay from the beginning
    }
    if (userResponses.length === 0) {
      // Replay video if no button is selected
      return;
    }

    // Clear the previous user response
    setUserResponses([]);

    const selectedResponse = userResponses[userResponses.length - 1].response;
    if (selectedResponse === "too fast" && videoIndex > 0) {
      setVideoIndex((prevIndex) => prevIndex - 1); // Play the next slowest video
    } else if (selectedResponse === "too slow" && videoIndex < videos.length - 1) {
      setVideoIndex((prevIndex) => prevIndex + 1); // Play the next fastest video
    } else {
      saveResponse();
    }

    setDisplayButtons(false);
    if(newpopup) {
      setTimeout(() => {
        setDisplayButtons(true);
      }, 10000);
    }
  };

  const [videoIndex, setVideoIndex] = useState(0);
  const [userResponses, setUserResponses] = useState([]);
  const [displayButtons, setDisplayButtons] = useState(false);

  useEffect(() => {
    if (newpopup) {
      const timer = setTimeout(() => {
        setDisplayButtons(true);
      }, 10000); // Delay the display of buttons for 30 seconds
      const player = videoRef.current.getInternalPlayer();
      player.play();
      return () => clearTimeout(timer);
    }
  }, [newpopup]);
  

  useEffect(() => {
    // Find the index of the video with the matching speed
    const matchingVideoIndex = videos.findIndex((video) => video.title === speed);

    // Set the initial video index to the matching index or 0 if no match is found
    setVideoIndex(matchingVideoIndex >= 0 ? matchingVideoIndex : 0);
  }, [speed]);

  return (
    <>
    <div className={newpopup ? "open_goal_about" : "open_goal_about_none"}>
      <section className="SessionBody">
      <section className="fullsection" style={{ zIndex: "999", position: "sticky" }}>
        <div className="headercontainer ">
          <div className="headernewimage" onClick={() => { redirecthome() }}>
            <img style={{width: "294px"}} src={headerbrithlogo} alt="breathhealogo" />
          </div>
        </div>
      </section>
      <div className="historyflexbody">
        <div className="calibrationContainer">
          <div className="calibrationNewIntroBox">
            <br />
            <h3 style={{ color: "#FFFFFF" }}>{t("calibrationTitle")}</h3>
            <h6 style={{ color: "#a0a0a0" }}>{t("calibrationSubTitle")}</h6>
            <br />
            {videos.length > 0 && (
      <div onContextMenu={handleContextMenu}>
        <ReactPlayer
          ref={videoRef}
          className="calibrationVideoPlayer"
          url={videos[videoIndex].videoUrl}
          controls
          playing={newpopup}
          autoPlay={false}
          onEnded={playNextVideo}
        />
      </div>
    )}
            <button
              className={`calibrationSkipButton ${displayButtons && (userResponses.length >= 1 && userResponses[userResponses.length - 1].response !== 'just right') ? '' : 'invisible'}`}
              onClick={skipToEnd}
              disabled={!displayButtons || (userResponses.length > 0 && userResponses[userResponses.length - 1].response !== 'too fast' && userResponses[userResponses.length - 1].response !== 'too slow')}
            >
              {t("skipPace")}
            </button>
            <h5
              style={{
                color: "#FFFFFF",
                opacity: displayButtons ? 1 : 0,
                transition: "opacity 0.5s ease",
              }}
            >
              {t("calibrationPrompt")}
            </h5>
            <div
              className="calibrationIntroEndSessionBut"
              style={{
                opacity: displayButtons ? 1 : 0,
                transition: "opacity 0.5s ease",
              }}
            >
              <button
                onClick={() => handleResponse("too fast")}
                disabled={!displayButtons || videoIndex <= 0}
                className={videoIndex <= 0 ? "disabledButton" : ""}
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  color: "#FFFFFF",
                  outline:
                    userResponses.length > 0 &&
                    userResponses[userResponses.length - 1].response === "too fast"
                      ? "2px solid #29aae3"
                      : "none",
                }}
              >
                {t("tooFast")}
              </button>
              <button
                onClick={() => handleResponse("just right")}
                disabled={!displayButtons}
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  color: "#FFFFFF",
                  outline:
                    userResponses.length === 0 ||
                    userResponses[userResponses.length - 1].response === "just right"
                      ? "2px solid #29aae3"
                      : "none",
                }}
              >
                {t("justRight")}
              </button>
              <button
                onClick={() => handleResponse("too slow")}
                disabled={!displayButtons || videoIndex >= videos.length - 1}
                className={videoIndex >= videos.length - 1 ? "disabledButton" : ""}
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  color: "#FFFFFF",
                  outline:
                    userResponses.length > 0 &&
                    userResponses[userResponses.length - 1].response === "too slow"
                      ? "2px solid #29aae3"
                      : "none",
                }}
              >
                {t("tooSlow")}
              </button>
            </div>
            <div className="SessionBtn">
              <button
                className={`btn btn-primary`}
                disabled={!displayButtons || (userResponses.length > 0 && userResponses[userResponses.length - 1].response !== "just right")}
                onClick={saveResponse}
              >
                {t("completeCalibration")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
    </div>
      <section>
        <Nav></Nav>
        <div className="EditAccountSettingbody">
          <div className="EditAcc_Set_inn_body ">
            <div className="Edit_acc_title">
              <Icon
                icon="eva:arrow-ios-back-fill"
                color="white"
                width="24"
                height="24"
                onClick={() => {
                  history.push("/Setting/account-setting");
                }}
                cursor="pointer"
              />
              <h5> {t("ACCOUNTDETAILS")}</h5>
            </div>
            <div className="edit_acc_form">
              {value ? (
                <form
                  className="edit_acc_form"
                  onSubmit={load == false && handleSubmit(onSubmit)}
                >
                  <label className="edit-acc-label">
                    {t("EMAILADDRESS")}
                    <input
                      type="text"
                      name="email"
                      disabled
                      autoComplete="off"
                      {...register("email", {
                        pattern: {
                          value:
                            /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/,
                          message: t("pentvalemail"),
                        },
                      })}
                    ></input>
                  </label>

                  {errors.email && (
                    <p className="forError">{errors.email?.message}</p>
                  )}
                  <label className="edit-acc-label phonedirectory">
                    {t("EDITPHONE")}
                    <PhoneInput
                      placeholder={t("enterphone")}
                      country="us"
                      value={fullPhone.length >= 1 ? fullPhone : ""}
                      onChange={(value, country) => {
                        let dialCode = country.dialCode;
                        let countryCode = country.countryCode;
                        let phone = value.slice(dialCode.length, value.length);
                        setFullPhone(value);
                        setDialCode(dialCode);
                        setPhoneNo(phone);
                      }}
                    />
                  </label>
                  <label className="edit-acc-label">
                    {t("AGE")}
                    <Form.Select
                      aria-label="Default select example"
                      {...register("age_group")} onChange={(e) => { updatAgeValue(e); }}
                    >
                      {userInfoList
                        ? userInfoList.age_data.map((item, index) => (
                          <option
                            key={index}
                            value={item && item.id ? item.id : index}

                            selected={value.age_group === item.id ? item.id : index == 0}
                            className="optionselect"
                            disabled={load ? true : false}
                          >

                            {item.ageName}
                          </option>
                        ))
                        : ""}
                    </Form.Select>
                  </label>
                  <label className="edit-acc-label" style={{maxWidth: "300px"}}>
                    {t("Recommendedspeed")}
                    <Form.Select
                      aria-label="Default select example"
                      {...register("speed")} 
                      value={selectedSpeed}
                      className="optionselect"
                      disabled={load ? true : false}
                      onChange={(e) => setSelectedSpeed(e.target.value)}
                    >
                      {recommendedOption && recommendedOption.length > 0 && recommendedOption.map((item, index) => (
                        <option
                          key={index}
                          value={item.value}
                        >
                          {item.value}
                        </option>
                      ))}
                    </Form.Select>
                    </label>
                      <div style={{width: "80px", display: "inline-block"}}>
                      <label className="edit-acc-label phonedirectory">
                      <div className="edit_acc_sacg_button">
                      <button
                        type="button"
                        onClick={() => setnewpopup(true)}
                        style={{width: "80px", marginLeft: "12px", minHeight: "42px"}}
                      >
                        {t("calibrate")}
                      </button>
                    </div>
                  </label>
                  </div>
                  <label className="edit-acc-label">
                    {t("GENDER")}
                    <Form.Select
                      aria-label="Default select example"
                      {...register("gender_id")}
                    >
                      {userInfoList
                        ? userInfoList.gender_data.map((item, index) => (
                          <option
                            key={index}
                            value={item && item.id ? item.id : index}
                            selected={value.gender_id == item.value ? item.id : index == 0}
                            className="optionselect"
                            disabled={load ? true : false}
                          >
                            {item.gender_name}
                          </option>
                        ))
                        : ""}
                    </Form.Select>
                  </label>
                  <label className="edit-acc-label">
                    {t("MEDITATIONEXPE")}
                    <Form.Select
                      aria-label="Default select example"
                      {...register("meditation")}
                    >
                      {userInfoList
                        ? userInfoList.experience_data.map((item, index) => (
                          <option
                            key={index}
                            value={item && item.id ? item.id : index}
                            className="optionselect"
                            selected={value.expID == item.id ? item.id : index == 0}
                            disabled={load ? true : false}
                          >
                            {item.expName}
                          </option>
                        ))
                        : ""}
                    </Form.Select>
                  </label>
                  <p className={`${load ? "loaderImg" : "topSpace"}`}>
                    {load ? <img src={loaders} alt="loader" /> : null}
                  </p>
                  < div className="edit_acc_button_body">
                    <div className="edit_acc_cancel_button">
                      <button
                        type="button"
                        onClick={() => {
                          history.push("/Setting/account-setting");
                        }}
                      >
                        {t("CANCEL")}
                      </button>
                    </div>
                    <div className="edit_acc_sacg_button">
                      <button type="submit">
                        {" "}
                        {t("SAVE")}
                      </button>
                    </div>
                  </div>
                </form>
              ) : (
                <Loader />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default EditAccountSetting;