import "./emailVerification.css";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useState } from "react";
import SublyApi from "../../../helpers/Api";
import backarrows from "../../../Assets/Images/backarrow.svg";
import { Toast } from "../../../commonFile";
import { useEffect } from "react";
import { logout } from "../../../actions/currentUser";
import { deleteToken } from "../../../actions/currentUser";
import { getUser } from "../../../actions/currentUser";
import { SmallLoader } from "../../../commonFile";
import { useTranslation } from "react-i18next";
import { getinternet } from "../../../commonfunction";
import { Getlanguage } from "../../../commonfunction";
import { deleteGender } from "../../../actions/getgender";
import { deleteLanguage } from "../../../actions/getlanguage";
import { deleteAge } from "../../../actions/getsurvey";
import { deleteExperience } from "../../../actions/getexperiense";
import { deleteDefaultList } from "../../../actions/defaultList";
import { deleteExcerciseList } from "../../../actions/getExcercise";
import { deleteNewDefaultList } from "../../../actions/defaultList";
import { onMessageListener } from "../../../firebase";
import { clearErr } from "../../../actions/errors";
import { deleteGoalTimeList } from "../../../actions/goalTimeList";
import { deleteDefault } from "../../../actions/homeSession";
import { deleteNarrationTheme } from "../../../actions/narrationTheme";
import { removeTempData } from "../../../actions/tempData";
import { deletTimeNarration } from "../../../actions/timeNarrationList";


function EmailVerifcation() {
  const [loading, setLoading] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState("");
  const [notification, setNotification] = useState({ title: "", body: "" })
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const token = useSelector((st) => st.token);
  const currentUser = useSelector((st) => st.currentUser);
  useEffect(() => {
    async function setlanguage() {
      const geti18value = await Getlanguage(parseInt(currentUser.default_language ? currentUser.default_language : 1));
      i18n.changeLanguage(geti18value);
    }
    setlanguage();
  }, []);
  function logouts() {
    dispatch(logout());
    dispatch(deleteToken());
    dispatch(deleteDefaultList());
    dispatch(clearErr());
    dispatch(deleteExcerciseList());
    dispatch(deleteExperience());
    dispatch(deleteGender());
    dispatch(deleteLanguage());
    dispatch(deleteAge());
    dispatch(deletTimeNarration());
    dispatch(removeTempData());
    dispatch(deleteNarrationTheme());
    dispatch(deleteDefault());
    dispatch(deleteGoalTimeList());
    dispatch(deleteNewDefaultList());
    dispatch(deleteDefaultList());
    history.push("/login");
  };

  async function signOut() {
    await SublyApi.logoutuser(token, currentUser.device_id, currentUser.device_timezone).then((responsejson) => {
      if (
        responsejson &&
        responsejson.data.error_type == "USER_NOT_FOUND" &&
        responsejson.data.status_code === 400
      ) {
        // Toast.fire({
        //   icon: "error",
        //   title: responsejson.data.message,
        // });
        logouts();
      }
      else {
        if (responsejson.data.status_code == 400) {
          Toast.fire({
            icon: "error",
            title: `${responsejson.data.message}`,
          })
          logouts();
        }
        else {
          logouts();
          Toast.fire({
            icon: "success",
            title: `${responsejson.message}`,
          });
        }
      }
    })
      .catch((error) => {
      });
  }

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  setValue("email", `${currentUser.email}`);

  onMessageListener()
    .then((payload) => {
      // setusernotification([]);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
    })
  useEffect(() => {
    async function getemailverfication() {
      const online = await getinternet();

      if (online === false) {
        Toast.fire({
          icon: "error",
          title: t("noiternet"),
        });

        if (!currentUser.id) {
          history.push("/login");
        }
      }
      else {
        await SublyApi.emailVerfication(token, currentUser.email, currentUser.device_id, currentUser.device_timezone)
          .then((responsejson) => {
            if (responsejson.data.error_type === "USER_NOT_FOUND") {
              // Toast.fire({
              //   icon: "error",
              //   title: responsejson.data.message,
              // });
              signOut();
            }
            if (responsejson.data == 400) {
              setLoading(false);

              Toast.fire({
                icon: "error",
                title: `${responsejson.data.message}`,
              })
            }
            else {
              setServerErrorMessage("");
              if (responsejson.data.user_detail.is_verified == 1) {
                Toast.fire({
                  icon: "success",
                  title: t("emailversucc"),
                })
              }
              dispatch(getUser({ is_verified: responsejson.data.user_detail.is_verified }))

              setTimeout(() => {
                if (responsejson.data.user_detail.is_verified == 1) {
                  if (responsejson.data.user_detail.is_subscribe == 1) {
                    if (responsejson.data.user_detail.is_survey_complete == 1) {
                      history.push("/new-tutorial")
                    }
                  }
                  else {
                    history.push("/Algorithm")
                  }
                }
                else {
                  history.push("/check")
                }

              }, 3000);
              setLoading(false);
            }
          })

          .catch((error) => { });
      }
    }
    getemailverfication();
  }, [notification.title == "Email verify"])




  // it's call one time call for verified email
  async function OnSubmit(formData) {
    const online = await getinternet();

    if (online === false) {
      Toast.fire({
        icon: "error",
        title: t("noiternet"),
      });
    }
    else {
      await SublyApi.emailVerfication(token, formData.email, currentUser.device_id, currentUser.device_timezone)
        .then((responsejson) => {
          if (responsejson.data.error_type === "USER_NOT_FOUND") {
            // Toast.fire({
            //   icon: "error",
            //   title: responsejson.data.message,
            // });
            signOut();
          }
          if (responsejson.data == 400) {
            setLoading(false);

            Toast.fire({
              icon: "error",
              title: `${responsejson.data.message}`,
            })
          }
          else {
            setServerErrorMessage("");
            if (responsejson.data.user_detail.is_verified == 0) {
              Toast.fire({
                icon: "warning",
                title: t("pleaseverifyid"),
              })
            }
            if (responsejson.data.user_detail.is_verified == 1) {
              Toast.fire({
                icon: "success",
                title: t("emailversucc"),
              })
            }
            dispatch(getUser({ is_verified: responsejson.data.user_detail.is_verified }))

            setTimeout(() => {
              if (responsejson.data.user_detail.is_verified == 1) {
                if (responsejson.data.user_detail.is_subscribe == 1) {
                  if (responsejson.data.user_detail.is_survey_complete == 1) {
                    history.push("/new-tutorial")
                  }
                }
                else {
                  history.push("/Algorithm")
                }
              }
              else {
                history.push("/check")
              }

            }, 3000);
            setLoading(false);
          }
        })

        .catch((error) => { });
    }
  }
  // it's call resend mailverification
  async function onSubmits(formData) {
    const online = await getinternet();

    if (online === false) {
      Toast.fire({
        icon: "error",
        title: t("noiternet"),
      });
    }
    else {
      await SublyApi.ResendMailVerfication(token, formData.email, currentUser.device_timezone)
        .then((responsejson) => {
          if (
            responsejson &&
            responsejson.data.error_type == "USER_NOT_FOUND" &&
            responsejson.data.status_code === 400
          ) {
            // Toast.fire({
            //     icon: "error",
            //     title: responsejson.data.message,
            // });
            signOut();
          }
          if (responsejson.status === 400) {
            setServerErrorMessage(responsejson.data.message);
          } else {
            setServerErrorMessage("");

            Toast.fire({
              icon: "success",
              title: t("veremasuccess"),
            });
            setTimeout(() => {
              if (responsejson.is_verified === 1) {
                history.push("/login")
              }
              else {
                history.push("/check")
              }
            }, 5000);
            setLoading(false);
          }
        })

        .catch((error) => { });
    }
  }



  return (
    <>
      <section className="emailVerficationbody">
        <div className="container">
          <div className="emailVerificationConatin">
            <div className="emailConatin">
              <div className="emailVerificationbody">
                <div className="emailverificationlogo">
                  <button onClick={() => history.push("/login")}>
                    <img src={backarrows} alt="BackIcon" />
                  </button>
                </div>
                <h2 className="title">{t("EMAILVER_TITLE")}</h2>
                <p className="firstp">
                  {t("EMAILVER_P")}
                </p>
                <form autoComplete="off" className="Form_Side" >
                  <div className="EmailVerifyField">
                    <input readOnly
                      {...register("email", {
                        required: t("emailreq"),
                        message: t("pentvalemail"),
                      })}
                    />
                    <p className="errorMessageColor">{serverErrorMessage}</p>
                    <div className="new_work_button">
                      <button type="button">
                        {" "}
                        <span
                          onClick={handleSubmit(onSubmits)}
                          className="log_out_color"
                        >
                          {t("RESEND")}
                        </span>
                      </button>
                      <button type="button">
                        <span
                          onClick={signOut}
                          className="log_out_color"
                        >
                          {t("LOGOUT")}
                        </span>
                      </button>
                    </div>
                  </div>
                  {loading ? <SmallLoader /> : null}
                  <button className="btn" type={`${loading ? "button" : "submit"}`} onClick={handleSubmit(OnSubmit)}
                  >
                    {t("VERIFIED")}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section >
    </>
  );
}

export default EmailVerifcation;

