import { NavLink, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import "./Question.css";
import { useDispatch, useSelector } from "react-redux";
import SignupHeader from "./SignupHeader";
import SublyApi from "../../../helpers/Api";
import { getAgeValue, deleteAge } from "../../../actions/getsurvey";
import { Loader } from "../../../commonFile";
import { deleteGender } from "../../../actions/getgender";
import { deleteExperience } from "../../../actions/getexperiense";
import { deleteLanguage } from "../../../actions/getlanguage";
import {  logout } from "../../../actions/currentUser";
import { deleteToken } from "../../../actions/currentUser";
import { deleteNewDefaultList } from "../../../actions/defaultList";
import { useTranslation } from "react-i18next";
import { Getlanguage } from "../../../commonfunction";
import { deleteDefaultList } from "../../../actions/defaultList";

const AgeQuestion = () => {
  const currentUser = useSelector((st) => st.currentUser);
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const signOut = () => {
    dispatch(deleteGender());
    dispatch(deleteLanguage());
    dispatch(deleteAge());
    dispatch(deleteExperience());
    dispatch(logout());
    dispatch(deleteToken());
    dispatch(deleteNewDefaultList());
    dispatch(deleteDefaultList());
    history.push("/login");
  };
  // it's work store a data in reducer.
  const dispatch = useDispatch();
  const token = useSelector((st) => st.token);
  // it's use for open popup
  const [breathtitles, setbreathtitles] = useState();
  // show list age
  const [ages, setages] = useState();
  const language = useSelector((st) => st.getlanguage);
  const changelanguage = Number(language.defaultData);

  useEffect(() => {
    async function setlanguage() {
      const geti18value = await Getlanguage(parseInt(currentUser.default_language ? currentUser.default_language : changelanguage));
      i18n.changeLanguage(geti18value);
    }
    setlanguage();
  }, []);


  //get survey data list show for option
  useEffect(() => {
    async function getsurvey() {
      const value = await SublyApi.getsurvey();
      setages(value ? value.data.age_list : "")
    }
    function testing() {
      if (age.defaultData || currentUser.userID) {
        setbreathtitles(false)
      } else {
        setbreathtitles(true)
      }
    }
    // (age.defaultData || currentUser.userID? setbreathtitles(false): "")
    getsurvey();
    testing();
  }, []);
  //save value age in redux
  async function getagevalu(e) {
    const getage = e.currentTarget.id;
    dispatch(getAgeValue(getage));
    history.push("/gender-question")
  }
  // get custom option select
  const age = useSelector((st) => st.getsurvey);
  // it's function solve issue go on next component if user is currentuser

  const myComponentStyle = {
    fontSize: "14px"
  }

  return (
    <>
      <section>
        <SignupHeader></SignupHeader>

        <div
          className="AgeQuestionbody"
          style={{ marginBottom: "20px", position: "sticky" }}
        >
          <div className={`${breathtitles == false ? "AgeQuestioninnerbody" : "forFixedBack"}`}>
            <div className="AgeQuestiontitlbody">
              <div className="titles">
                <p className="numbering">1/4</p>
                <h2>
                  {t("AGETITLE")}
                </h2>
              </div>
            </div>

            {ages ?
              <form className="Featurgoalsubtitlesage">

                <div style={{ paddingTop: "10px" }}>
                  {ages ? ages.map((item, index) => (
                    <label className="containerlevel" key={index}>
                      <input
                        defaultChecked={!(currentUser.userID) ?
                          !(age.defaultData) ? "" : age.defaultData == item.id
                          : (currentUser.age_group) ?
                            (currentUser.age_group == item.id)
                            : ""}
                        type="radio"
                        name="agegroup"
                        className="inputfieldfirsts"
                        id={item.id}
                        onClick={getagevalu}
                      ></input>
                      <span className="geekmarks">
                        <span className="Questioninputtext">
                          {item.ageName}
                        </span>
                      </span>
                    </label>
                  ))
                    : ""}
                </div>
                <div className="ageQuestionbutton" style={{ paddingTop: "60px" }}>
                  {/* <NavLink exact to="/gender-question">
                    <div className="ageQuestionsubmitbutton" >
                      <button > {t("NEXTBUTTON")} </button>
                    </div>
                  </NavLink> */}
                  <NavLink exact to={`${!currentUser.userID ? "/signup" : currentUser.userID && currentUser.is_tutorilal == 0 ? "/home" : "/new-tutorial"}`}>
                    <div className="ageQuestionskipbutton">
                      <button style={myComponentStyle}>{t("SKIPBUTTON")}</button>
                    </div>
                  </NavLink>
                </div>
              </form>
              : <Loader />}

          </div>
        </div>
      </section >


    </>
  );
};
export default AgeQuestion;
