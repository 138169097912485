export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_CURRENT = 'FETCH_CURRENT';
export const REMOVE_CURRENT = 'REMOVE_CURRENT';
export const SHOW_ERR = 'SHOW_ERR';
export const CLEAR_ERR = 'CLEAR_ERR';
export const FETCH_TOKEN = 'FETCH_TOKEN'
export const REMOVE_TOKEN = 'REMOVE_TOKEN';
export const FETCH_HOMESESSION = 'FETCH_HOMESESSION';
export const REMOVE_HOMESCREEN = 'REMOVE_HOMESCREEN';
export const FETCH_DEFAULT_LIST = 'FETCH_DEFAULT_LIST';
export const REMOVE_DEFAULT_LIST = 'REMOVE_DEFAULT_LIST';
export const GOAL_TIME_LIST = 'GOAL_TIME_LIST';
export const REMOVE_GOAL_TIME_LIST = 'REMOVE_GOAL_TIME_LIST';
export const TIME_NARRATION_LIST = 'TIME_NARRATION_LIST';
export const REMOVE_TIME_NARRATION_LIST = 'REMOVE_TIME_NARRATION_LIST';
export const NARRATION_THEME_LIST = 'NARRATION_THEME_LIST';
export const REMOVE_NARRATION_THEME_LIST = 'REMOVE_NARRATION_THEME_LIST';
export const FETCH_DEFAULT_DATA = 'FETCH_DEFAULT_DATA';
export const REMOVE_DEFAULT_DATA = 'REMOVE_DEFAULT_DATA';
export const EXCERCISE_LIST = 'EXCERCISE_LIST';
export const REMOVE_EXCERCISE_LIST = 'REMOVE_EXCERCISE_LIST';
export const FETCH_NEW_DEFAULT_LIST = 'FETCH_NEW_DEFAULT_LIST';
export const REMOVE_NEW_DEFAULT_LIST = 'REMOVE_NEW_DEFAULT_LIST';
export const AGE_LIST = 'AGE_LIST';
export const REMOVE_AGE_LIST = 'REMOVE_AGE_LIST';
export const GENDER_LIST = 'GENDER_LIST';
export const REMOVE_GENDER_LIST = 'REMOVE_GENDER_LIST';
export const LANGUAGE_LIST = 'LANGUAGE_LIST';
export const REMOVE_LANGUAGE_LIST = 'REMOVE_LANGUAGE_LIST';
export const EXPERIENCE_LIST = 'EXPERIENCE_LIST';
export const REMOVE_EXPERIENCE_LIST = 'REMOVE_EXPERIENCE_LIST';
export const STORE_TEMP_DATA = 'STORE_TEMP_DATA';
export const REMOVE_TEMP_DATA = 'REMOVE_TEMP_DATA';